import { Select, Option } from "@mui/joy";

export type DateFilterValue =
    | "last7"
    | "last14"
    | "last90"
    | "last365"
    | "thisMonth"
    | "lastMonth"
    | "thisYear"
    | "allTime";

const dateFilters: { label: string; value: DateFilterValue }[] = [
    { label: "Last 7 Days", value: "last7" },
    { label: "Last 14 Days", value: "last14" },
    { label: "Last 90 Days", value: "last90" },
    { label: "Last 365 Days", value: "last365" },
    { label: "This Month", value: "thisMonth" },
    { label: "Last Month", value: "lastMonth" },
    { label: "This Year", value: "thisYear" },
    { label: "All Time", value: "allTime" },
];

interface DateFilterProps {
    selectedFilter: DateFilterValue;
    onChange: (value: DateFilterValue) => void;
}

export default function DateFilter({ selectedFilter, onChange }: DateFilterProps) {
    return (
        <Select<DateFilterValue>
            size="sm"
            value={selectedFilter}
            onChange={(_, newValue) => newValue && onChange(newValue)}>
            {dateFilters.map(({ label, value }) => (
                <Option key={value} value={value}>
                    {label}
                </Option>
            ))}
        </Select>
    );
}