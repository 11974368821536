import React from 'react';
import Lottie from "lottie-react";
import emptyAnimation from "../empty_state.json";

import { Typography } from '@mui/joy';

function EmptyMessageComponent({ title, description }: any) {

    return (
        <div className='container text-center'>
            <Lottie
                animationData={emptyAnimation}
                style={{ height: 200 }} />
            <Typography
                level="title-lg"
                sx={{ marginBottom: 1 }}>
                {title}
            </Typography>
            <Typography
                level="body-sm">
                {description}
            </Typography>
        </div>
    );
}

export default EmptyMessageComponent;