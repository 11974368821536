import React, { useState } from 'react';
import { Button, Card, Chip, CircularProgress, Divider, Skeleton, Snackbar, Stack, Typography } from '@mui/joy';
import { Report, CopyAll } from '@mui/icons-material';

import '../../styles/predictor.scss';

const networking = require('../../Networking/Wingman/wingmanAPI');

function PredictRoadmapComponent({ app }: any) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [success, setSuccess] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [roadmap, setRoadmap] = useState<any[]>();

    function getData() {
        setLoading(true);

        networking.generateRoadmap(app.id).then((data: any) => {
            setLoading(false);
            createRoadmap(data.insights);
        }).catch((error: Error) => {
            setLoading(false);
            setError(error.message);
            setShowError(true);
        });
    }

    function createRoadmap(data: any) {
        const impactPriority: Record<string, number> = { High: 1, Medium: 2, Low: 3 };

        const sortedRoadmap = data.roadmap.sort((a: any, b: any) => {
            const impactA = impactPriority[a.impact as keyof typeof impactPriority] || 99;
            const impactB = impactPriority[b.impact as keyof typeof impactPriority] || 99;

            return impactA - impactB;
        });

        setRoadmap(sortedRoadmap);
    }

    var shareMessage = "Here is the roadmap for " + app.name + ":\n\n";

    roadmap?.forEach((item: any) => {
        let result = "## " + item.topic + " ## **" + item.impact + "** \n\n"
            + item.reasoning + "\n\n"
            + "**" + item.type + "**\n\n *** \n\n";
        shareMessage += result;
    });

    const shareToSlack = () => {
        networking.shareOnSlack(app.id, shareMessage).then((result: any) => {
            setShowSuccess(true);
            setSuccess('Shared to your slack channel.');
        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
        });
    }

    const shareToTeams = () => {
        networking.shareOnTeams(app.id, shareMessage).then((result: any) => {
            setShowSuccess(true);
            setSuccess('Shared to your teams channel.');
        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
        });
    }

    const copyToClipboard = () => {
        setShowSuccess(true);
        setSuccess('Copied to clipboard.');

        navigator.clipboard.writeText(shareMessage);
    }

    return (

        <div className=''>
            {/* Error handling */}
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            {showSuccess &&
                <Snackbar
                    variant="soft"
                    color="success"
                    open={showSuccess}
                    onClose={() => setShowSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowSuccess(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {success}
                </Snackbar>
            }
            <div className=''>
                {loading ?
                    <Typography
                        level='body-xs'>
                        <Skeleton>
                            The general sentiment across the reviews is predominantly negative, with users expressing acute frustration with various technical difficulties.
                            A major issue highlighted is the inability to log in, particularly after app updates, and persistent problems with the app's user interface, especially on iPads.
                            There are very few positive mentions, which indicate occasional satisfaction with app updates and simplicity.
                            What do you like to know?
                        </Skeleton>
                    </Typography>
                    :
                    <div className='container-fluid'>
                        <div className='row gx-1'>
                            <div className='col'>
                                <Button onClick={getData}
                                    loading={loading}>
                                    {loading ? <CircularProgress size="sm" /> : "Generate Roadmap"}
                                </Button>
                            </div>
                            {roadmap && app.slack_webhook_url &&
                                <div className='col-auto'>

                                    <Chip
                                        variant="outlined"
                                        color="neutral"
                                        size="lg"
                                        startDecorator={<CopyAll
                                            sx={{
                                                width: '14px'
                                            }} />}
                                        onClick={shareToSlack}>
                                        Share on Slack
                                    </Chip>

                                </div>
                            }
                            {roadmap && app.teams_webhook_url &&
                                <div className='col-auto'>
                                    <Chip
                                        variant="outlined"
                                        color="neutral"
                                        size="lg"
                                        startDecorator={<CopyAll
                                            sx={{
                                                width: '14px'
                                            }} />}
                                        onClick={shareToTeams}>
                                        Share on Teams
                                    </Chip>

                                </div>
                            }
                            <div className='col-auto'>
                                {
                                    roadmap &&
                                    <Chip
                                        variant="outlined"
                                        color="neutral"
                                        size="lg"
                                        startDecorator={<CopyAll
                                            sx={{
                                                width: '14px'
                                            }} />}
                                        onClick={copyToClipboard}>
                                        Copy
                                    </Chip>
                                }
                            </div>
                        </div>
                        <div className='mt-3'>
                            {roadmap && roadmap.map((item: any, index: number) => (
                                <Card key={index} sx={{ mb: 1 }}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography level="title-sm">{item.topic}</Typography>
                                        <Chip
                                            size="sm"
                                            color={
                                                item.impact === "High"
                                                    ? "danger"
                                                    : item.impact === "Medium"
                                                        ? "warning"
                                                        : "neutral"
                                            }
                                        >
                                            {item.impact}
                                        </Chip>
                                    </Stack>
                                    <Divider />
                                    <Typography
                                        level="body-sm">
                                        {item.reasoning}
                                    </Typography>
                                    <Chip
                                        size="sm"
                                    >
                                        {item.type}
                                    </Chip>
                                </Card>

                            ))}
                        </div>
                    </div>
                }
            </div>

        </div >
    )
}

export default PredictRoadmapComponent;
