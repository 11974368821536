import { faAppStoreIos, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Store, Message } from '@mui/icons-material';

export function calculateAverageRating(reviews: any[]) {
  if (reviews.length === 0) return 0; // Avoid division by zero

  const totalRating = reviews.reduce((sum, review) => sum + parseFloat(review.rating), 0);

  return (totalRating / reviews.length).toFixed(2); // Round to 2 decimal places
}

export function timeSince(date: string) {
  var seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }

  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }

  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }

  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }

  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }

  return Math.floor(seconds) + " seconds ago";
}

export function iconFor(store: string) {
  switch (store) {
    case "playstore":
      return <FontAwesomeIcon icon={faGooglePlay} />;
    case "googlestore":
      return <FontAwesomeIcon icon={faGooglePlay} />;
    case "applestore":
      return <FontAwesomeIcon icon={faAppStoreIos} />;
    case "profeed":
      return <Message />;
    default:
      return <Store />;
  };
}

export function canAddMoreApps(plan: string, apps: number) {
  if (plan === 'A') {
    return apps === 0;
  }

  if (plan === 'AA') {
    return apps < 3;
  }

  if (plan === 'AAA') {
    return apps < 10;
  }

  return true
}

export function base64For(file: any) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      resolve(reader.result);
    };

    reader.onerror = function (error) {
      reject(error);
    }

  });
}

export function getInitials(name: string): string {
  // Divide o nome em partes (usando espaços como delimitadores)
  const parts: string[] = name.trim().split(' ');

  // Se houver apenas um nome, retorna a inicial dele
  if (parts.length === 1) {
    return parts[0][0].toUpperCase();
  }

  // Pega a primeira e a última parte e retorna as iniciais em maiúsculas
  const firstInitial: string = parts[0][0].toUpperCase();
  const lastInitial: string = parts[parts.length - 1][0].toUpperCase();

  return firstInitial + lastInitial;
}

export function formatFriendlyDate(isoDate: string, locale: string): string {
  const date = new Date(isoDate);

  // Format the date to a more user-friendly format
  return date.toLocaleDateString(locale ?? "en-US", {
    year: "numeric", // e.g., "2025"
    month: "long",   // e.g., "September"
    day: "numeric"   // e.g., "22"
  });
}

export const planA = {
  name: 'Free',
  description: 'Ideal for small developers looking to dip their toes into app feedback analysis. \
                Offers basic tools to explore user sentiment and track essential keywords.',
  priceMonth: '0',
  priceYear: '0',
  code: 'A',
  features: [
    "1 App",
    "Basic sentiment analysis",
    "5 topics",
  ]
};

export const planAA = {
  name: 'Starter',
  description: 'Perfect for growing teams managing a few apps. \
                Includes sentiment trends and email reports to keep track of feedback easily.',
  priceMonth: '32',
  priceYear: '340',
  code: 'AA',
  features: [
    "Up to 3 Apps",
    "All Free feature",
    "10 topics",
    "Sentiment trend analysis",
    "E-mail reports"
  ]
};

export const planAAA = {
  name: 'Pro',
  description: 'Designed for businesses scaling up. Automatic topic creation, \
                and advanced tracking ensure maximum insights and customer engagement.',
  priceMonth: '89',
  priceYear: '960',
  code: 'AAA',
  features: [
    "Up to 10 Apps",
    "All Starter features",
    "Unlimited topics",
    "Wingman"
  ]
};

export const planU = {
  name: 'Enterprise',
  code: 'U',
  description: 'Tailored for enterprises with specific needs.\
                Includes access to appinion SDK for proactive feedback features.',
  features: [
    "Unlimited Apps",
    "All Pro features",
    "Proactive feedback"
  ]
};

export const parseKeyValue = (jsonString: string): { key: string; value: string } | null => {
  try {
    const parsedObject = JSON.parse(jsonString); // Parse the JSON string
    const [key, value] = Object.entries(parsedObject)[0] as [string, string]; // Extract key-value pair
    return { key, value };
  } catch (error) {
    console.error('Failed to parse JSON string:', error);
    return null; // Return null if parsing fails
  }
};

export const getLanguageName = (isoCode: string) => {
  return new Intl.DisplayNames([navigator.language], { type: "language" }).of(isoCode) || isoCode;
};