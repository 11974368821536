import { Add } from '@mui/icons-material';
import { Button } from '@mui/joy';
import React from 'react';

function InfoBanner({ title, description, action, subscription }: any) {

    const onActionTapped = () => {
        action()
    }

    return (
        <div className='bg-doriean'>
            <div className='container'>
                <div className='row p-0'>
                    <div className='col'>
                        <h1 className='bold iron'>{title}</h1>
                        <p className='semi-bold light-iron'>{description}</p>
                    </div>
                    {action && subscription &&
                        <div className='apps col-auto my-auto'>
                            <Button
                                onClick={onActionTapped}
                                endDecorator={<Add />}
                                color="primary"
                            >
                                Add App
                            </Button>
                        </div>
                    }
                </div>
            </div>
        </div >
    );
}

export default InfoBanner;