import React from 'react';

import '../../styles/star-rating.scss';
import { LinearProgress, Typography } from '@mui/joy';

function RatingDistributionComponent({ reviews, total }: any) {

    const distribution = reviews.map((list: any, index: number) =>
        <div className='row my-auto'>
            <div className="col-auto text-left">
                <span className="legend light-iron">&#9733; {index + 1}</span>
            </div>
            <div className='col-9 p-0 my-auto'>
                <LinearProgress determinate value={(list.length / total) * 100} />
            </div>
            <div className='col my-auto'>
                <Typography
                    color='neutral'
                    fontWeight={700}
                    sx={{
                        fontSize: 10
                    }}>
                    {list.length}
                </Typography>
            </div>
        </div>
    );

    return (
        <div>
            {distribution}
        </div>
    );
}

export default RatingDistributionComponent;