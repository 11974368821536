import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Add, Report } from '@mui/icons-material';
import { Alert, Box, Button, DialogTitle, Modal, ModalClose, ModalDialog, ModalOverflow, Skeleton, Snackbar, Typography } from '@mui/joy';

import PrivateNavigationHeader from '../PrivateNavigationHeader';
import AppCard from '../AppCard';
import ClientManagementComponent from '../Client Management/ClientManagementComponent';

import '../../styles/navigation.scss';

import revault_logo from '../../resources/footer_logo.png'
import EmptyMessageComponent from '../EmptyMessageComponent';
import AddNewAppComponent from './AddNewAppComponent';
import WhatsNewModal from '../Whats New/WhatsNewModal';

const networking = require('../../Networking/API');
const helper = require('../../Helpers/helpers');

function AppsListComponent() {
    const [parameters, setParameters] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [activeComponent, setActiveComponent] = useState(0);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [apps, setApps] = useState(null) as any;
    const [showAddNewApp, setShowAddNewApp] = useState(false);
    const [currentSubscription, setCurrentSubscription] = useState(JSON.parse(localStorage.getItem("savedState")!));

    const sessionId = parameters.get('session_id');
    const plan = parameters.get('plan');

    if (sessionId) {
        networking.customer(sessionId).then((data: any) => {
            networking.updateState(currentSubscription.id, sessionId, data.str_customer_id, data.str_subscription_id, plan).then((data: any) => {
                getNewData();
            }).catch((error: Error) => {
                setShowError(true);
                setError(error.message);
            });

        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
        });
    }

    const hasFetched = useRef(false);

    useEffect(() => {
        if (!hasFetched.current) {
            getApps();
            hasFetched.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getNewData = () => {
        setLoading(true);

        networking.authenticateSubscription(currentSubscription.id).then((sub: any) => {
            setLoading(false);

            setCurrentSubscription(sub);
            localStorage.setItem("savedState", JSON.stringify(sub));
            setShowSuccess(true);

            parameters.delete('session_id')
            setParameters(parameters)

        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    function getApps() {
        networking.appsFor(currentSubscription.id).then((data: any) => {
            setApps(data);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    const updateSubscription = (subscription: any) => {
        setCurrentSubscription(subscription);
    }

    const openList = (event: any) => {
        event.preventDefault()

        setActiveComponent(0);
    }

    const openAddNewApp = () => {
        setShowAddNewApp(true)
    }

    const updateScreen = () => {
        setShowAddNewApp(false);
        getApps();
    }

    if (apps) {
        var apps_list = apps.map((app: any, index: BigInteger) =>
            <div className='col-md-4'>
                <AppCard
                    app={app}
                    subscription={currentSubscription} />
            </div>
        );
    }

    return (
        <div>
            <WhatsNewModal />
            <PrivateNavigationHeader
                subscription={currentSubscription}
                onSubscriptionUpdate={updateSubscription} />

            {/* Add new App Modal */}
            <Modal
                open={showAddNewApp}
                onClose={() => setShowAddNewApp(false)}>
                <ModalOverflow>
                    <ModalDialog
                        color="neutral"
                        size="lg"
                        variant="soft"
                        maxWidth={'800px'}>
                        <ModalClose />
                        <DialogTitle>
                            Add new App
                        </DialogTitle>
                        <>
                            <AddNewAppComponent
                                subscription={currentSubscription}
                                onAppAdded={updateScreen} />
                        </>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
            {apps && helper.canAddMoreApps(currentSubscription!.plan, apps.length) &&
                <div className='mt-3 container'>
                    < Alert
                        size="lg"
                        variant="soft"
                        color="neutral"
                        endDecorator={
                            <Button
                                onClick={openAddNewApp}
                                endDecorator={<Add />}
                                color="primary"
                            >
                                Add App
                            </Button>
                        }>
                        <div>
                            <div>
                                <Typography level="title-md">
                                    Apps
                                </Typography>
                            </div>
                            <Typography level="body-sm">
                                Here are the apps you've connected to Appinion.
                                Manage your integrations and keep your insights flowing smoothly.
                            </Typography>
                        </div>
                    </Alert>
                </div>
            }
            {
                showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            {
                showSuccess &&
                <Snackbar
                    variant="soft"
                    color="success"
                    open={showSuccess}
                    onClose={() => setShowSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowSuccess(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    Profile updated! 🚀
                </Snackbar>
            }
            {
                loading ?
                    <div className='container'>
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                                gap: 2,
                                p: 2
                            }}
                        >
                            {Array.from({ length: 6 }).map((_, index) => (
                                <>
                                    <Skeleton
                                        variant="rectangular"
                                        height={140}
                                        sx={{
                                            borderRadius: 8
                                        }} />
                                </>

                            ))}
                        </Box>
                    </div>
                    :
                    <div className='container apps-list pb-5 pt-5'>
                        {activeComponent === 0 &&
                            <div>
                                {apps && apps.length === 0 &&
                                    <EmptyMessageComponent
                                        title={'You haven\'t linked any app yet.'}
                                        description={'Click on the Add new app button above to link your App and start \
                                    analysing your reviews.'} />
                                }
                                <div className='row gy-2'>
                                    {apps_list}
                                </div>
                            </div>
                        }
                        {activeComponent === 1 &&
                            <div>
                                <a href='/apps-List'
                                    onClick={openList}>
                                    <p className='semi-bold mb-5'>
                                        <span className='me-3'><FontAwesomeIcon icon={faChevronLeft} /></span>back to list</p></a>
                                <div className='row gy-4'>
                                    <ClientManagementComponent subscription={currentSubscription!} />
                                </div>
                            </div>
                        }
                    </div >
            }
            <footer className='p-3 text-center'>
                <a className='no-decoration' href='https://www.appinion.pt' target={'blank'}>
                    <p className='legend light-iron'>© 2025 Appinion All Rights Reserved.</p>
                    <img className='ms-3' height={16} src={revault_logo} alt="logo"></img>
                </a>
            </footer>
        </div >

    );
}

export default AppsListComponent;