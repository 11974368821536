import { LinearProgress, Stack, Tooltip } from '@mui/joy';
import React from 'react';

function IssueOverallSentimentBar({ reviews }: any) {
    const badReviews = reviews.filter((d: any) => d.sentiment === 'bad');

    const total = reviews.length;

    const badWidth = (badReviews.length / total) * 100;

    return (
        <Stack spacing={0}>
            <Tooltip
                title='Bad sentiment percentage'
                size='sm'
                placement='top'>
                <LinearProgress
                    determinate
                    value={badWidth || 0}
                    color='danger' />
            </Tooltip>
        </Stack>
    );
}

export default IssueOverallSentimentBar;