import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import EmptyMessageComponent from '../EmptyMessageComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const networking = require('../../Networking/API');

function ReportsComponent({ app, subscription, handler }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];

    if (!reviews) {
        getData();
    }

    function getData() {
        networking.reviewsFor(app.id).then((reviews: any) => {
            setReviews(reviews);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function downloadReport(month: any, year: any) {
        setLoading(true);

        networking.downloadReport(app, month, year).then((file: any) => {
            setLoading(false);

            const url = window.URL.createObjectURL(
                new Blob([file]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                "report_" + month + "-" + app.name + ".pdf",
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    const itemTapped = (index: any) => {
        handler(9);
    }

    let m: any[] = [];
    let allMonths = Array.from({ length: 12 }, (_, i) => {
        return new Date(0, i).toLocaleString('en-US', { month: 'long' });
    });

    let years: any[] = [];
    const revPerYear: any[] = [];
    let lastYear: number = 0;

    if (reviews) {
        years = [...new Set(reviews.map((r: any) => new Date(r.updated).getFullYear()))].sort();

        years.map((year: any) => {
            var count = reviews.filter((review: Review) => {
                const reviewYear = new Date(review.updated).getFullYear();
                return reviewYear === year;
            }).length;

            revPerYear.push(
                {
                    "year": year,
                    "count": count
                })
        });

        let monthIndex: number = new Date().getMonth();

        m = Array.from({ length: monthIndex + 1 }, (_, i) => {
            return new Date(0, i).toLocaleString('en-US', { month: 'long' });
        });

        if (subscription.plan === 'AAA') {
            m = m.slice(-6);
        }

        if (subscription.plan === 'AA') {
            m = m.slice(-3);
        }

        if (subscription.plan === 'A') {
            m = m.slice(-1);
        }

        lastYear = years.pop();
    }

    return (
        <div className=''>
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div>
                    {
                        subscription.plan === 'A' &&
                        <div className='card mb-4 bg-cloudie'>
                            <div className='row'>
                                <div className='col'>
                                    <p className='iron'>Your plan only allow you do download your last month report. Upgrade to have access to your full historic.</p>
                                </div>
                                <div className='col-auto'>
                                    <button onClick={itemTapped} className='btn btn-cta-primary bg-apporange white bold legend'>Manage subscription</button>
                                </div>
                            </div>

                        </div>
                    }
                    {
                        years && years.length == 0 &&
                        <div className='card mt-3'>
                            <EmptyMessageComponent
                                title={'Oh no! '}
                                description={'We were not able to find reports! Please try again later.'} />
                        </div>
                    }
                    {
                        years && years.length != 0 &&
                        <div className='accordion' id='reportAccordion'>
                            {subscription.plan !== 'A' && subscription.plan !== 'AA' && subscription.plan !== 'AAA' &&
                                years && years.map((year: any, index: any) => (
                                    <div>
                                        <div className='accordion-item mb-2'>
                                            <p className='iron bold accordion-header' id={'flush-heading ' + index} >
                                                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target={'#flush-collapse' + index}>
                                                    <p className='iron semi-bold m-0'>{year} | <span className='light-iron legend'>{revPerYear[index].count} reviews</span></p>
                                                </button>
                                            </p>
                                            <div className='accordion-collapse collapse ps-5 pt-4 pb-0 pe-5' id={'flush-collapse' + index}>
                                                {
                                                    allMonths && allMonths.map(month => (
                                                        <div>
                                                            <div className='row my-auto'>
                                                                <div className='col'>
                                                                    <p className='iron legend semi-bold p-0'>{month}</p>
                                                                </div>
                                                                <div className='col-auto'>
                                                                    <button onClick={() => downloadReport(month, year)} className='btn p-2 iron semi-bold legend p-0'><FontAwesomeIcon icon={faDownload} /></button>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='accordion-item mb-2'>
                                <p className='iron bold accordion-header' id={'flush-heading ' + lastYear} >
                                    <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target={'#flush-collapse' + lastYear}>
                                        <p className='iron semi-bold m-0'>{lastYear} | <span className='light-iron legend'>{revPerYear[revPerYear.length - 1].count} reviews</span></p>
                                    </button>
                                </p>
                                <div className='accordion-collapse collapse ps-5 pt-4 pb-0 pe-5' id={'flush-collapse' + lastYear}>
                                    {
                                        m && m.map(month => (
                                            <div>
                                                <div className='row my-auto'>
                                                    <div className='col'>
                                                        <p className='iron legend semi-bold p-0'>{month}</p>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <button onClick={() => downloadReport(month, lastYear)} className='btn p-2 iron semi-bold legend p-0'><FontAwesomeIcon icon={faDownload} /></button>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div >
    )

}

export default ReportsComponent;