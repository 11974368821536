import React from 'react';

import AppInfoOverviewComponent from './AppInfoOverviewComponent';
import NotificationAlertComponent from './NotificationAlertComponent';
import SentimentBreakdownComponent from './SentimentBreakdownComponent';
import SentimentGaugeComponent from './SentimentGaugeComponent';
import TopKeywordsComponent from './TopKeywordsComponent';
import AppRatingComponent from './AppRatingComponent';
import { Avatar, Card, Divider, Typography } from '@mui/joy';
import { Android, Apple } from '@mui/icons-material';

const helper = require('../../Helpers/helpers');

function DashboardComponent(app: any) {

    const stores = () => {
        if (app.app.androidpackageid && app.app.appleappid) {
            return <span className="mr-3">
                {helper.iconFor('applestore')}
                <span> + </span>
                {helper.iconFor('playstore')}
            </span>
        } else if (app.app.androidpackageId && !app.app.appleappid) {
            return <span className="mr-3">
                {helper.iconFor('playstore')}
            </span>
        } else if (!app.app.androidpackageid && app.app.appleappid) {
            return <span className="mr-3">
                {helper.iconFor('applestore')}
            </span>
        }
    }

    return (
        <div className=''>
            <div className='row mb-4'>
                <div
                    className='col-auto my-auto '>
                    <Avatar
                        alt={app.app.name + 'App icon'}
                        src={app.app.icon}
                        size='lg'>
                        {helper.getInitials(app.app.name)}
                    </Avatar>
                </div>
                <div
                    className='col-auto my-auto'>
                    <Typography
                        level='title-sm'>
                        {app.app.name}
                    </Typography>
                    <Typography
                        level='body-xs'>
                        {app.app.country} | {stores()}
                    </Typography>
                </div>
            </div>
            <div className='row gy-3 gx-3 mb-3'>
                <div className='col-md'>
                    {app.app.appleappid &&
                        <AppRatingComponent
                            app={app.app}
                            type={'apple'} />
                    }
                    {!app.app.appleappid &&
                        <Card
                            sx={{
                                textAlign: 'center',
                                backgroundColor: 'white',
                                height: 100 + '%'
                            }}>
                            <Apple className='mx-auto'></Apple>
                            <Typography
                                level='title-sm'>
                                No iOS App connected.
                            </Typography>
                            <Typography
                                sx={{
                                    paddingX: 8
                                }}
                                level='body-sm'>
                                Go to the App settings section and add an apple app id.
                                You can connect with appstore connect services to enable more metadata and
                                reply to reviews.
                            </Typography>
                        </Card>
                    }
                </div>
                <div className='col-md'>
                    {app.app.androidpackageid &&
                        <AppRatingComponent
                            app={app.app}
                            type={'google'} />
                    }
                    {!app.app.androidpackageid &&
                        <Card
                            sx={{
                                textAlign: 'center',
                                backgroundColor: 'white',
                                height: 100 + '%'
                            }}>
                            <Android className='mx-auto'></Android>
                            <Typography
                                level='title-sm'>
                                No Android App connected.
                            </Typography>
                            <Typography
                                sx={{
                                    paddingX: 8
                                }}
                                level='body-sm'>
                                Go to the App settings section and add a package.
                                You can connect with playstore console services to enable more metadata and
                                reply to reviews.
                            </Typography>
                        </Card>
                    }
                </div>
            </div>
            <AppInfoOverviewComponent
                app={app.app}
            />
            <div className='p-0 mt-3'>
                <div className='row gy-3 gx-3' >
                    <div className='col-lg-3'>
                        <NotificationAlertComponent
                            app={app.app} />
                    </div>
                    <div className='col-lg-4'>
                        <Card
                            sx={{
                                backgroundColor: 'white',
                                padding: 3
                            }}>
                            <SentimentGaugeComponent
                                app={app.app} />
                        </Card>
                    </div>
                    <div className='col-lg-5'>
                        <Card
                            sx={{
                                backgroundColor: 'white',
                                padding: 3,
                                height: 100 + '%'
                            }}>
                            <SentimentBreakdownComponent
                                app={app.app} />
                        </Card>
                    </div>
                </div>
            </div>
            <div className='p-0 mt-3'>
                <Card
                    sx={{
                        backgroundColor: 'white'
                    }}>
                    <Typography
                        color='neutral'
                        fontWeight={600}
                        level='body-xs'>
                        {"Top keywords".toUpperCase()}
                    </Typography>
                    <Divider />
                    <TopKeywordsComponent
                        app={app.app}
                        limit={5}
                        state={''} />
                </Card>
            </div>
        </div >
    )
}

export default DashboardComponent;