import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Sheet, Snackbar, Avatar, styled, Typography, Switch } from '@mui/joy';
import { DialogTitle, Modal, ModalClose, ModalDialog, ModalOverflow, DialogContent, Divider } from '@mui/joy';
import { Stack, FormControl, FormLabel, Input, FormHelperText } from '@mui/joy';
import { Report } from '@mui/icons-material';
import SvgIcon from '@mui/joy/SvgIcon';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import '../../styles/navigation.scss';
import '../../styles/stage.scss';
import { Link } from 'react-router-dom';

const networking = require('../../Networking/API');
const helper = require('../../Helpers/helpers');

function AppSettingsComponent(app: any, subscription: any) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [tempImage, setTempImage] = useState(null);
    const [keyFileName, setKeyFileName] = useState(null);
    const [key, setKey] = useState(null);
    const [json, setJson] = useState(null);
    const [iconURL, setIconURL] = useState(app.app.icon);
    const [showConnectAppstore, setShowConnectAppstore] = useState(false);
    const [showConnectPlaystore, setShowConnectPlaystore] = useState(false);
    const [packageId, setPackageId] = useState(null);
    const [appleappId, setAppleappId] = useState(null);

    const currentApp = JSON.parse(localStorage.getItem("currentApp")!);
    var notification = currentApp.notify_email;
    var appstore_connected = currentApp.appstore_connect;
    var playstore_connected = currentApp.playstore_connect;

    const VisuallyHiddenInput = styled('input')`
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        white-space: nowrap;
        width: 1px;
        `;

    const updateTempImage = (event: any) => {
        event.preventDefault();

        setIconURL('');
        setError('');
        setShowError(false);

        const file = event.target.files[0];

        if ((file.size / 1024 / 1024).toFixed(4) > "1") {
            setError('File size is too large');
            setShowError(true);
        } else {
            setTempImage(file);
            setIconURL(URL.createObjectURL(file));
        }
    }

    const updateFileName = (event: any) => {
        event.preventDefault();

        const file = event.target.files[0];

        helper.base64For(file).then((result: any) => {
            setKey(result);
            setJson(result);
            setKeyFileName(file.name);
        }).catch((error: any) => {
            setError(error.message);
            setShowError(true);
        });
    }

    const updateNotification = (event: any) => {
        event.preventDefault();

        var status = !currentApp.notify_email;

        setLoading(true);
        networking.updateNotificationStatus(currentApp.id, status).then((app: any) => {
            setLoading(false);
            setShowSuccess(true);

            localStorage.setItem("currentApp", JSON.stringify(app.app));
            notification = app.notify_email;

        }).catch((error: Error) => {
            window.scrollTo(0, 0)
            setError(error.message);
            setShowError(true);
            setLoading(false);
        });
    }

    const handlePackageChange = (event: any) => {
        setError('');
        setPackageId(event.target.value);
    }

    const handleAppleIdChange = (event: any) => {
        setError('');
        setAppleappId(event.target.value);
    }

    const updateApp = (event: any) => {
        setLoading(true);

        if (tempImage) {
            currentApp.icon = tempImage;
        }

        if (packageId) {
            currentApp.androidpackageid = packageId
        }

        if (appleappId) {
            currentApp.appleappid = appleappId
        }

        networking.updateApp(currentApp.id, currentApp, subscription).then((app: any) => {
            setLoading(false);
            setShowSuccess(true);

            localStorage.setItem("currentApp", JSON.stringify(app.app));

        }).catch((error: Error) => {
            window.scrollTo(0, 0);
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    const removeApp = (event: any) => {
        event.preventDefault();

        setLoading(true);
        networking.deleteApp(currentApp.id).then((app: any) => {
            setLoading(false);
            navigate("/apps-list");
        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    const manageAppstoreConnection = (event: any) => {
        if (currentApp.appstore_connect === true) {
            disconnectFromAppstoreConnect();
        } else {
            setKeyFileName(null);
            setShowConnectAppstore(true);
        }
    }

    const managePlaystoreConnection = (event: any) => {
        if (currentApp.playstore_connect === true) {
            disconnectFromPlaystore();
        } else {
            setKeyFileName(null);
            setShowConnectPlaystore(true);
        }
    }

    const connectToAppstoreConnect = (event: any) => {
        setLoading(true);
        setShowConnectAppstore(false);

        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        formJson.key = key!
        formJson.connect = currentApp.appstore_connect === true ? 'false' : 'true'

        networking.connectAppstoreConnect(currentApp.id, formJson).then((app: any) => {
            setLoading(false);
            setShowSuccess(true);

            localStorage.setItem("currentApp", JSON.stringify(app.app));
            setShowConnectAppstore(false);

        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    function disconnectFromAppstoreConnect() {
        let data = {
            "key": null,
            "keyid": null,
            "issuerid": null,
            "connect": false
        }

        setLoading(true);
        networking.connectAppstoreConnect(currentApp.id, data).then((app: any) => {
            setLoading(false);
            setShowSuccess(true);

            localStorage.setItem("currentApp", JSON.stringify(app.app));
            setShowConnectAppstore(false);
        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    const connectToPlaystore = (event: any) => {
        setLoading(true);
        setShowConnectPlaystore(false);

        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        formJson.androidjsonfile = json!
        formJson.connect = currentApp.playstore_connect === true ? 'false' : 'true'

        networking.connectPlaystore(currentApp.id, formJson).then((app: any) => {
            setLoading(false);
            setShowSuccess(true);

            localStorage.setItem("currentApp", JSON.stringify(app.app));
            setShowConnectPlaystore(false);

        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    function disconnectFromPlaystore() {
        let data = {
            "androidJsonFile": null,
            "androidPackageId": null,
            "androidBucketId": null,
            "connect": false
        }

        setLoading(true);
        networking.connectPlaystore(currentApp.id, data).then((app: any) => {
            setLoading(false);
            setShowSuccess(true);

            localStorage.setItem("currentApp", JSON.stringify(app.app));
            setShowConnectAppstore(false);
        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    const connectToSlack = (event: any) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());

        networking.connectSlack(currentApp.id, formJson).then((app: any) => {
            setLoading(false);
            setShowSuccess(true);

            localStorage.setItem("currentApp", JSON.stringify(app.app));
            setShowConnectAppstore(false);

        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    const connectToTeams = (event: any) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());

        networking.connectTeams(currentApp.id, formJson).then((app: any) => {
            setLoading(false);
            setShowSuccess(true);

            localStorage.setItem("currentApp", JSON.stringify(app.app));
            setShowConnectAppstore(false);

        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    return (
        <>
            {/* Connect Playstore connect modal */}
            <Modal
                open={showConnectPlaystore}
                onClose={() => setShowConnectPlaystore(false)}>
                <ModalOverflow>
                    <ModalDialog
                        color="neutral"
                        layout="center"
                        size="md"
                        variant="plain">
                        <ModalClose />
                        <DialogTitle>
                            Connect with Google Playstore
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <form
                                onSubmit={(event) => {
                                    connectToPlaystore(event)
                                }}
                            >
                                <Stack
                                    spacing={2}>
                                    <div className='row my-auto'>
                                        <FormLabel sx={{ mb: 1 }}>
                                            Google json file
                                        </FormLabel>
                                        {keyFileName &&
                                            <div className='col-auto'>
                                                <Typography
                                                    level="body-sm">
                                                    {keyFileName}
                                                </Typography>
                                            </div>
                                        }
                                        <div className='col-auto'>
                                            <Button
                                                component="label"
                                                role={undefined}
                                                tabIndex={-1}
                                                variant="outlined"
                                                color="neutral"
                                                startDecorator={
                                                    <SvgIcon>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={1.5}
                                                            stroke="currentColor"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                                            />
                                                        </svg>
                                                    </SvgIcon>
                                                }
                                            >
                                                Upload json
                                                <VisuallyHiddenInput
                                                    onChange={updateFileName}
                                                    accept='.json'
                                                    type="file"
                                                    name="key"
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                    <FormControl
                                        sx={{
                                            marginTop: 2
                                        }}>
                                        <FormLabel>Bucket Id</FormLabel>
                                        <Input
                                            // html input attribute
                                            name="androidbucketid"
                                            type="text"
                                            placeholder="Bucket Id"
                                            size='md'
                                            required
                                        />
                                        <FormHelperText>
                                            The Bucket ID, in the context of Google Cloud Storage,
                                            uniquely identifies a storage container where files and
                                            data are stored.
                                            Refer to the <Link to='/docs'>documentation</Link> to find this information.
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                                <Button
                                    type='submit'
                                    sx={{ mt: 6 /* margin top */ }}>
                                    Connect
                                </Button>
                            </form>
                        </DialogContent>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
            {/* Connect Appstore connect modal */}
            <Modal
                open={showConnectAppstore}
                onClose={() => setShowConnectAppstore(false)}>
                <ModalOverflow>
                    <ModalDialog
                        color="neutral"
                        layout="center"
                        size="md"
                        variant="plain">
                        <ModalClose />
                        <DialogTitle>
                            Connect with Appstore connect
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <form
                                onSubmit={(event) => {
                                    connectToAppstoreConnect(event)
                                }}
                            >
                                <Stack
                                    spacing={2}>
                                    <div className='row my-auto'>
                                        <FormLabel sx={{ mb: 1 }}>
                                            Key file (.p8)
                                        </FormLabel>
                                        {keyFileName &&
                                            <div className='col-auto'>
                                                <Typography
                                                    level="body-sm">
                                                    {keyFileName}
                                                </Typography>
                                            </div>
                                        }
                                        <div className='col-auto'>
                                            <Button
                                                component="label"
                                                role={undefined}
                                                tabIndex={-1}
                                                variant="outlined"
                                                color="neutral"
                                                startDecorator={
                                                    <SvgIcon>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={1.5}
                                                            stroke="currentColor"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                                            />
                                                        </svg>
                                                    </SvgIcon>
                                                }
                                            >
                                                Upload key
                                                <VisuallyHiddenInput
                                                    onChange={updateFileName}
                                                    accept='.p8'
                                                    type="file"
                                                    name="key"
                                                />
                                            </Button>
                                        </div>
                                    </div>

                                    <FormControl
                                        sx={{
                                            marginTop: 2
                                        }}>
                                        <FormLabel>Issuer Id</FormLabel>
                                        <Input
                                            // html input attribute
                                            name="issuerid"
                                            type="text"
                                            placeholder="Issuer Id"
                                            size='md'
                                            required
                                        />
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            marginTop: 2
                                        }}>
                                        <FormLabel>Key Id</FormLabel>
                                        <Input
                                            // html input attribute
                                            name="keyid"
                                            type="text"
                                            placeholder="Key Id"
                                            size='md'
                                            required
                                        />
                                    </FormControl>
                                </Stack>
                                <Button
                                    type='submit'
                                    sx={{ mt: 6 /* margin top */ }}>
                                    Connect
                                </Button>
                            </form>
                        </DialogContent>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            {showSuccess &&
                <Snackbar
                    variant="soft"
                    color="success"
                    open={showSuccess}
                    onClose={() => setShowSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowSuccess(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    Changes saved.
                </Snackbar>
            }
            {loading ?
                <LoadingSpinnerComponent />
                :
                <>
                    <Typography
                        sx={{
                            marginTop: 2,
                            marginBottom: 2
                        }}
                        level="title-sm">
                        General information
                    </Typography>
                    <Typography
                        level="body-sm">
                    </Typography>
                    <Sheet
                        variant="outlined"
                        sx={{
                            p: 3,
                            borderRadius: 8,
                            backgroundColor: 'white'
                        }}>
                        <div className='row my-auto'>
                            <div className='col-auto'>
                                <Avatar
                                    alt='The App Icon'
                                    src={iconURL}
                                    sx={{ borderRadius: 12 }}>
                                    {helper.getInitials(app.app.name)}
                                </Avatar>
                            </div>
                            <div className='col-auto'>
                                <Button
                                    component="label"
                                    role={undefined}
                                    tabIndex={-1}
                                    variant="outlined"
                                    color="neutral"
                                    startDecorator={
                                        <SvgIcon>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                                />
                                            </svg>
                                        </SvgIcon>
                                    }
                                >
                                    Upload a file
                                    <VisuallyHiddenInput
                                        onChange={updateTempImage}
                                        accept='image/png, image/jpeg'
                                        type="file"
                                        name="avatar"
                                    />
                                </Button>
                            </div>
                            <Typography
                                level="title-sm"
                                sx={{ marginTop: 4 }}>
                                {app.app.name}
                            </Typography>
                            <Typography
                                level="body-xs">
                                {app.app.country}
                            </Typography>
                            {app.app.appleappid &&
                                <>
                                    <Typography
                                        level="title-sm"
                                        sx={{ marginTop: 4 }}>
                                        Apple app Id
                                    </Typography>
                                    <Typography
                                        level="body-xs">
                                        {app.app.appleappid}
                                    </Typography>
                                </>
                            }
                            {
                                !app.app.appleappid &&
                                <FormControl
                                    sx={{
                                    }}>
                                    <FormLabel>App Id</FormLabel>
                                    <Input
                                        // html input attribute
                                        name="appleAppId"
                                        type="text"
                                        placeholder="##########"
                                        size='md'
                                        onChange={handleAppleIdChange}
                                    />
                                    <FormHelperText>
                                        The Apple Id is an automatically generated ID assigned to your app. You can find your app
                                        Apple Id on the App information section on your Appstore connect console.
                                    </FormHelperText>
                                </FormControl>
                            }
                            {app.app.androidpackageid &&
                                <>
                                    <Typography
                                        level="title-sm"
                                        sx={{ marginTop: 4 }}>
                                        Google package Id
                                    </Typography>
                                    <Typography
                                        level="body-xs">
                                        {app.app.androidpackageid}
                                    </Typography>
                                </>
                            }
                            {
                                !app.app.androidpackageid &&
                                <FormControl
                                    sx={{
                                        marginTop: 2
                                    }}>
                                    <FormLabel>Package Id</FormLabel>
                                    <Input
                                        // html input attribute
                                        name="androidPackageId"
                                        type="text"
                                        placeholder="com.example.myapp"
                                        size='md'
                                        onChange={handlePackageChange}
                                    />
                                    <FormHelperText>
                                        The Package ID, uniquely identifies an Android app within the Google Play Store ecosystem.
                                        It typically follows a reverse domain name format (e.g., com.example.myapp).
                                    </FormHelperText>
                                </FormControl>
                            }
                            <div className='row mt-4'>
                                <div className='col-auto'>
                                    <Button
                                        type='submit'
                                        onClick={(e) => updateApp(e)}>
                                        Save changes
                                    </Button>
                                </div>
                                <div className='col-auto'>
                                    <Button
                                        color='danger'
                                        variant='outlined'
                                        type='submit'
                                        onClick={(e) => removeApp(e)}>
                                        Delete app
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </Sheet>
                    {(app.subscription.plan !== 'A' && app.subscription.plan !== 'AA') &&
                        <>
                            <Typography
                                sx={{
                                    marginTop: 4
                                }}
                                level="title-sm">
                                Connections
                            </Typography>
                            <Typography
                                level="body-sm">
                                Manage your source connections.
                            </Typography>
                            <Sheet
                                variant="outlined"
                                sx={{
                                    marginTop: 2,
                                    p: 3,
                                    borderRadius: 8,
                                    backgroundColor: 'white'
                                }}>
                                {currentApp.appleappid &&
                                    <div className='row'>
                                        <div className='col'>
                                            <Typography
                                                level="title-sm">
                                                Appstore connect
                                            </Typography>
                                            <Typography
                                                level="body-sm">
                                                Connect to Appstore connect API to enable replies directly from appinion.
                                            </Typography>
                                        </div>
                                        <div className='col-auto my-auto'>
                                            <Switch
                                                checked={appstore_connected}
                                                onChange={() => manageAppstoreConnection(true)}
                                            />
                                        </div>
                                    </div>
                                }
                                {currentApp.androidpackageid &&
                                    <div className='row mt-4'>
                                        <div className='col'>
                                            <Typography
                                                level="title-sm">
                                                Google Playstore
                                            </Typography>
                                            <Typography
                                                level="body-sm">
                                                Connect to Google Playstore to enable replies directly from appinion.
                                            </Typography>
                                        </div>
                                        <div className='col-auto my-auto'>
                                            <Switch
                                                checked={playstore_connected}
                                                onChange={() => managePlaystoreConnection(true)}
                                            />
                                        </div>
                                    </div>
                                }
                            </Sheet>
                            <Typography
                                sx={{
                                    marginTop: 4
                                }}
                                level="title-sm">
                                Integrations
                            </Typography>
                            <Typography
                                level="body-sm">
                                Manage your sharing integrations.
                            </Typography>
                            <Sheet
                                variant="outlined"
                                sx={{
                                    marginTop: 2,
                                    p: 3,
                                    borderRadius: 8,
                                    backgroundColor: 'white'
                                }}>
                                <div className='row'>
                                    <div className='col-auto my-auto'>
                                        <Typography
                                            level="title-sm">
                                            Slack
                                        </Typography>
                                        <Typography
                                            level="body-sm">
                                            Connect a webhook to share insights to your slack channel.
                                        </Typography>
                                    </div>
                                    <div className='col my-auto'>
                                        <form
                                            className='row'
                                            onSubmit={(event) => {
                                                connectToSlack(event)
                                            }}
                                        >
                                            <div className='col my-auto'>
                                                <FormControl>
                                                    <Input
                                                        // html input attribute
                                                        name="slackwebhook"
                                                        placeholder='Webhook url'
                                                        type="text"
                                                        size='md'
                                                        defaultValue={app.app.slack_webhook_url}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className='col-auto my-auto'>
                                                <Button
                                                    type='submit'>
                                                    Save
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-auto my-auto'>
                                        <Typography
                                            level="title-sm">
                                            Microsoft Teams
                                        </Typography>
                                        <Typography
                                            level="body-sm">
                                            Connect a webhook to share insights to your Microsoft Teams channel.
                                        </Typography>
                                    </div>
                                    <div className='col my-auto'>
                                        <form
                                            className='row'
                                            onSubmit={(event) => {
                                                connectToTeams(event)
                                            }}
                                        >
                                            <div className='col my-auto'>
                                                <FormControl>
                                                    <Input
                                                        // html input attribute
                                                        name="teamswebhook"
                                                        placeholder='Webhook url'
                                                        type="text"
                                                        size='md'
                                                        defaultValue={app.app.teams_webhook_url}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className='col-auto my-auto'>
                                                <Button
                                                    type='submit'>
                                                    Save
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Sheet>
                        </>
                    }
                    {app.subscription.plan !== 'A' &&
                        <>
                            <Typography
                                sx={{
                                    marginTop: 4,
                                }}
                                level="title-sm">
                                Notifications
                            </Typography>
                            <Typography
                                level="body-sm">
                                Manage your e-mail notifications.
                            </Typography>
                            <Sheet
                                variant="outlined"
                                sx={{
                                    marginTop: 2,
                                    p: 3,
                                    borderRadius: 8,
                                    backgroundColor: 'white'
                                }}>
                                <div className='row'>
                                    <div className='col'>
                                        <Typography
                                            level="title-sm">
                                            Weekly summary
                                        </Typography>
                                    </div>
                                    <div className='col-auto my-auto'>
                                        <Switch
                                            checked={notification}
                                            onChange={(event) => updateNotification(event)}
                                        />
                                    </div>
                                </div>
                            </Sheet>
                        </>
                    }
                </>
            }
        </>
    );
}

export default AppSettingsComponent;