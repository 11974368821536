import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import '../../styles/graphics.scss';

type DataPoint = {
    x: string; // Version
    y: number[]; // Sentiment counts [positive, negative, neutral]
};

type SentimentEvolutionProps = {
    data: DataPoint[];
    annotations?: XAxisAnnotations[];
};

function SentimentEvolutionOverTime({ data, annotations }: SentimentEvolutionProps) {
    const options: ApexOptions = {
        chart: {
            type: "area",
            zoom: { enabled: false },
            toolbar: { show: false },
            animations: { enabled: false }
        },
        annotations: { xaxis: annotations ?? [] },
        xaxis: {
            categories: data.map(({ x }) => x),
            labels: { format: "yyyy-MMM-dd" },
            axisBorder: { show: false }, // Remove axis border
            axisTicks: { show: false }
        },
        yaxis: {
            labels: {
                show: false
            },
            axisBorder: { show: false }, // Remove axis border
            axisTicks: { show: false },
        },
        stroke: {
            curve: "straight",
            width: 1
        },
        fill: {
            type: "solid",
            opacity: 1
        },
        colors: ["#B6DAFF", "#FECDD6", "#FFE6A7"], // Positive, Negative, Neutral
        dataLabels: {
            enabled: false
        },
        tooltip: {
            y: { formatter: (value) => value.toString() },
        },
        grid: {
            show: false
        },
        legend: { position: "top" }
    };

    // Define the series for each sentiment type
    const series = [
        {
            name: "Positive",
            data: data.map(({ y }) => y[0]), // Positive sentiment data
        },
        {
            name: "Negative",
            data: data.map(({ y }) => y[1]), // Negative sentiment data
        },
        {
            name: "Neutral",
            data: data.map(({ y }) => y[2]), // Neutral sentiment data
        },
    ];

    return (
        <div className='rating-evolution'>
            <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={350} />
        </div>
    );

}

export default SentimentEvolutionOverTime;