import React from 'react';

import { Alert, Avatar, Button, Chip, Stack, Typography } from '@mui/joy';

import PillsList from '../PillsListComponent';
import Rating from "@mui/material/Rating"
import { Star, Reply } from '@mui/icons-material';

import '../../styles/reviews.scss';

const helper = require('../../Helpers/helpers');

interface ReviewHighlighterProps {
    review: string;
    keywords: string[];
    tokens: string[];
}

const ReviewHighlighter: React.FC<ReviewHighlighterProps> = ({ review, keywords, tokens }) => {
    // Regular expression to match sentences
    const sentences = review.match(/[^.!?]+[.!?]*\s*/g) || [];

    // Function to check if a sentence contains any keyword
    const containsKeyword = (sentence: string) =>
        keywords.some(keyword => sentence.toLowerCase().includes(keyword.toLowerCase()));

    const containsToken = (sentence: string) =>
        tokens.some(tk => sentence.toLowerCase().includes(tk.toLowerCase()))

    // Process sentences and highlight the ones containing keywords
    const highlightedText = sentences.map((sentence, index) => {
        const shouldHighlight = containsKeyword(sentence);
        const shouldHighlightToken = containsToken(sentence);

        return shouldHighlight ? (
            <span
                key={index}
                style={{
                    backgroundColor: 'rgb(253, 240, 225)',
                    color: 'rgb(73, 43, 8)',
                    borderRadius: 4,
                    padding: 2 + 'px'
                }}>
                {sentence}
            </span>
        ) : (
            shouldHighlightToken ? (
                <span
                    key={index}
                    style={{
                        backgroundColor: 'rgb(199, 223, 247)',
                        borderRadius: 4,
                        color: 'rgb(18, 70, 123)',
                        padding: 2 + 'px'
                    }}>
                    {sentence}
                </span>
            ) :
                sentence
        );
    });

    return <p className='content'>
        {highlightedText}
    </p>;
};

function ReviewComponent({ subscription, app, review, tokens, canReply, handler }: any) {

    const replyTapped = (review: Review) => {
        handler(review);
    };

    return (
        <div className='mb-1 pb-2'>
            <div className="row mt-2 review-item border-bottom">
                <div className="col-md-auto border-end">
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{
                            justifyContent: "left",
                            alignItems: "left",
                        }}
                    >
                        <div className='row'>
                            <div className='col-auto'>
                                <Typography
                                    color='neutral'
                                    level='body-xs'>
                                    Sentiment
                                </Typography>
                            </div>
                            <Chip
                                size='sm'
                                color={review.sentiment === 'good' ? 'success' : review.sentiment === 'bad' ? 'danger' : 'warning'}>
                                {review.sentiment}
                            </Chip>
                        </div>
                        <div className='row'>
                            <div className='col-auto'>
                                <Typography
                                    color='neutral'
                                    level='body-xs'>
                                    Updated
                                </Typography>
                            </div>
                            <div className='col-auto my-auto'>
                                <Typography
                                    color='neutral'
                                    level='body-xs'
                                    fontWeight={700}>
                                    {review.updated &&
                                        <>
                                            {helper.formatFriendlyDate(review.updated, navigator.language)}
                                        </>
                                    }
                                </Typography>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-auto'>
                                <Typography
                                    color='neutral'
                                    level='body-xs'>
                                    Author
                                </Typography>
                            </div>
                            <div className='col-auto my-auto'>
                                <Typography
                                    color='neutral'
                                    level='body-xs'
                                    fontWeight={700}>
                                    {review.author}
                                </Typography>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-auto'>
                                <Typography
                                    color='neutral'
                                    level='body-xs'>
                                    Version
                                </Typography>
                            </div>
                            <div className='col-auto my-auto'>
                                <Typography
                                    color='neutral'
                                    fontWeight={700}
                                    level='body-xs'>
                                    {review.version}
                                </Typography>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-auto'>
                                <Typography
                                    color='neutral'
                                    level='body-xs'>
                                    Source
                                </Typography>
                            </div>
                            <div className='col-auto my-auto'>
                                <Typography
                                    color='neutral'
                                    level='body-xs'
                                    fontWeight={700}>
                                    {helper.iconFor(review.store)}
                                </Typography>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-auto'>
                                <Typography
                                    color='neutral'
                                    level='body-xs'>
                                    Language
                                </Typography>
                            </div>
                            <div className='col-auto my-auto'>
                                <Typography
                                    color='neutral'
                                    level='body-xs'
                                    fontWeight={700}>
                                    {review.language &&
                                        helper.getLanguageName(review.language)
                                    }
                                </Typography>
                            </div>
                        </div>
                    </Stack>
                </div>
                <div className='col'>
                    <div className='row'>
                        <div className="ml-0">
                            <div className='row mb-3'>
                                <div className='col-auto'>
                                    <Typography
                                        level='body-xs'
                                        sx={{
                                            color: '#a1a1a1'
                                        }}>
                                        {review.title &&
                                            <span>
                                                <Typography
                                                    level='body-sm'
                                                    fontWeight={700}>
                                                    <ReviewHighlighter
                                                        review={review.title}
                                                        keywords={tokens ?? []}
                                                        tokens={review.tokens ?? []}
                                                    />
                                                </Typography>
                                            </span>
                                        }
                                    </Typography>
                                </div>
                                <div className='col-auto'>
                                    <Rating
                                        value={Number(review.rating)}
                                        readOnly size="small"
                                        emptyIcon={<Star
                                            style={{ opacity: 0.55 }}
                                            fontSize="inherit" />} />
                                </div>
                            </div>
                            <ReviewHighlighter
                                review={review.content}
                                keywords={tokens ?? []}
                                tokens={review.tokens ?? []}
                            />
                            <div>
                                <PillsList
                                    keywords={review.tokens.concat(tokens) ?? []} />
                            </div>
                            {(review.reply && review.reply !== "") &&
                                <div className='row mt-4'>
                                    <div className='col'>
                                        <Alert>
                                            {review.reply}
                                        </Alert>
                                    </div>
                                    <div className='col-auto'>
                                        <Avatar
                                            src={app.icon}>
                                            {helper.getInitials(app.name)}
                                        </Avatar>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {(canReply === true && (review.reply === null || review.reply === '')) &&
                    <div className='col-auto'>
                        <Button
                            size='sm'
                            color='primary'
                            variant='solid'
                            startDecorator={<Reply />}
                            onClick={(e) => replyTapped(review)}>
                            Reply
                        </Button>
                    </div>
                }
            </div>
        </div >
    )
}

export default ReviewComponent;