import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import '../../styles/graphics.scss';

type DataPoint = {
    x: string; // Version
    y: number[]; // Sentiment counts [positive, negative, neutral]
};

type ReviewVolumeProps = {
    data: DataPoint[];
    annotations?: XAxisAnnotations[];
};


function ReviewsByVersion({ data, annotations }: ReviewVolumeProps) {
    const options: ApexOptions = {
        chart: {
            type: "area",
            zoom: { enabled: false },
            toolbar: { show: false },
            animations: { enabled: false }
        },
        annotations: { xaxis: annotations ?? [] },
        xaxis: {
            categories: data.map(({ x }) => x),
            axisBorder: { show: false }, // Remove axis border
            axisTicks: { show: false }
        },
        yaxis: {
            min: 0,
            axisBorder: { show: false }, // Remove axis border
            axisTicks: { show: false }
        },
        stroke: { curve: 'straight', width: 1 },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            y: { formatter: (value) => `${value} reviews` },
        },
        grid: {
            show: false
        }
    };

    const series = [
        {
            name: "Reviews",
            data: data.map(({ y }) => y),
        },
    ];

    return <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={200} />;

}

export default ReviewsByVersion;