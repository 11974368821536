import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Dropdown, IconButton, Menu, MenuButton, MenuItem } from '@mui/joy';
import { DialogContent, DialogTitle, Modal, ModalClose, ModalDialog, ModalOverflow, Avatar, ListDivider } from '@mui/joy';
import { MoreVert } from '@mui/icons-material';

import Cookies from 'universal-cookie';

import logo from '../resources/logo.svg'

import '../styles/navigation.scss';
import '../styles/typography.scss';
import '../styles/colors.scss';

import ClientManagementComponent from './Client Management/ClientManagementComponent';
import ClientPlanManagementComponent from './Client Management/ClientPlanManagementComponent';

const helper = require('../Helpers/helpers');

function PrivateNavigationHeader({ subscription, onSubscriptionUpdate }: any) {
    const [showSettings, setShowSettings] = useState(false);
    const [showPlanSettings, setShowPlanSettings] = useState(false);

    const cookies = new Cookies();

    function clearCookies() {
        cookies.remove("subs_token");
    }

    const openCustomerSettings = (event: any) => {
        setShowSettings(true)
    }

    const openCustomerPlanSettings = (event: any) => {
        setShowPlanSettings(true)
    }

    const updateSubscription = (subscription: any) => {
        onSubscriptionUpdate(subscription)
    }

    return (
        <div>
            {/* Plan Modal */}
            <Modal
                open={showPlanSettings}
                onClose={() => setShowPlanSettings(false)}>
                <ModalOverflow>
                    <ModalDialog
                        color="neutral"
                        size="sm"
                        variant="plain">
                        <ModalClose />
                        <DialogTitle>
                            Plans & Billing
                        </DialogTitle>
                        <DialogContent>
                            Manage your billing and plan settings.
                        </DialogContent>
                        <div className='pt-4'>
                            <ClientPlanManagementComponent
                                subscription={subscription}
                                onSubscriptionUpdate={updateSubscription}
                            />
                        </div>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
            {/* Settings Modal */}
            <Modal
                open={showSettings}
                onClose={() => setShowSettings(false)}>
                <ModalOverflow>
                    <ModalDialog
                        color="neutral"
                        size="sm"
                        variant="plain">
                        <ModalClose />
                        <DialogTitle>
                            Profile Settings
                        </DialogTitle>
                        <DialogContent>
                            Here you can update your profile settings.
                        </DialogContent>
                        <div className='pt-4'>
                            <ClientManagementComponent
                                subscription={subscription}
                                onSubscriptionUpdate={updateSubscription}
                            />
                        </div>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
            <nav className='navbar bg-white navbar-expand-sm'>
                <a href='/' className='navbar-brand'>
                    <img src={logo}
                        height={40 + 'px'}
                        alt='logo'></img>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <ul className='navbar-nav ms-auto'>
                        <li className='nav-item'>
                            <div className='row user-container'>
                                <div className='col text-end'>
                                    <p className='semi-bold iron mb-0'>Hello, {subscription.name}</p>
                                    <p className='legend light-iron mb-0'>{subscription.businessname}</p>
                                </div>
                                <div className='col-auto my-auto'>
                                    {subscription.profileimage &&
                                        <Avatar
                                            alt={subscription.name}
                                            src={subscription.profileimage}>
                                            {helper.getInitials(subscription.name)}
                                        </Avatar>
                                    }
                                </div>
                                <div className='col-auto my-auto'>
                                    <Dropdown>
                                        <MenuButton
                                            slots={{ root: IconButton }}
                                            slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
                                        >
                                            <MoreVert />
                                        </MenuButton>
                                        <Menu>
                                            <MenuItem
                                                onClick={(e: any) => openCustomerSettings(e)}>
                                                Profile settings
                                            </MenuItem>
                                            <MenuItem
                                                onClick={(e: any) => openCustomerPlanSettings(e)}>
                                                Upgrade plan⚡️
                                            </MenuItem>
                                            <ListDivider />
                                            <Link to='/authenticate'>
                                                <MenuItem onClick={clearCookies}>Sign out</MenuItem>
                                            </Link>
                                        </Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default PrivateNavigationHeader;