import React, { useEffect, useState } from 'react';

import { Alert, Button, Snackbar, Typography, Accordion, AccordionGroup, AccordionDetails, Skeleton, Chip, Modal, ModalOverflow, ModalDialog, ModalClose, DialogTitle, DialogContent } from '@mui/joy';
import AccordionSummary, {
    accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';
import { Report, AutoAwesome } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import '../../styles/typography.scss'
import ShareMenu from './ShareMenu';
import PredictorPersonaGenerator from '../Predictor AI/PredictorPersonaGenerator';

const networking = require('../../Networking/Wingman/wingmanAPI');

function WingmanSentiment({ app, data }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showPersonaGenerator, setShowPersonaGenerator] = useState(false);
    const [summary, setSummary] = useState('');
    const [displayedText, setDisplayedText] = useState("");
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (summary.length !== 0) {
            let i = 0;
            const interval = setInterval(() => {
                setDisplayedText((prev) => summary.slice(0, i + 1)); // ✅ Fix: Use `slice()`
                i++;
                if (i >= summary.length) clearInterval(interval); // ✅ Fix: Stop correctly
            }, 5);

            return () => clearInterval(interval);
        }
    }, [summary]);

    const handleChange = (_: React.SyntheticEvent, expanded: boolean) => {
        setExpanded(expanded);
        if (expanded) {
            getData();
        }
    }

    function getData() {
        setLoading(true);

        networking.getSentimentInsights(app.id, data).then((summ: any) => {
            setLoading(false);
            setSummary(summ.insights.summary);
        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    const openPersonaGenerator = () => {
        setShowPersonaGenerator(true);
    }

    return (
        <div className='keywords'>
            {/* Error handling */}
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            <>
                {/* Persona Generator Modal */}
                <Modal
                    open={showPersonaGenerator}
                    onClose={() => setShowPersonaGenerator(false)}>
                    <ModalOverflow>
                        <ModalDialog
                            maxWidth="800px"
                            color="neutral"
                            layout="center"
                            size="md"
                            variant="plain">
                            <ModalClose />
                            <DialogTitle >
                                <div className='col-auto'>
                                    <AutoAwesome
                                        sx={{
                                            width: '16px'
                                        }} />
                                </div>
                                <div className='col my-auto'>
                                    <Typography
                                        level="title-sm">
                                        Hey! Let's create your typical user persona.
                                    </Typography>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <Typography
                                    level="body-sm">
                                    Understand your audience with AI-powered personas built from the latest 50
                                    reviews. Uncover key needs, pain points, and expectations.
                                </Typography>
                            </DialogContent>
                            <>
                                <PredictorPersonaGenerator
                                    app={app}
                                    type={'persona'} />
                            </>
                        </ModalDialog>
                    </ModalOverflow>
                </Modal>
                <Alert
                    className="mb-3"
                    size="lg"
                    variant="soft"
                    color="primary"
                >
                    <>
                        <AccordionGroup
                            transition="0.2s">
                            <Accordion
                                color='primary'
                                expanded={expanded}
                                onChange={handleChange}
                                sx={{
                                    [`& .${accordionSummaryClasses.indicator}`]: {
                                        transition: 'none',
                                    },
                                    [`& [aria-expanded="true"] .${accordionSummaryClasses.indicator}`]: {
                                        transform: 'none',
                                    },
                                }}>
                                <AccordionSummary
                                    indicator={
                                        <Button
                                            variant='soft'>
                                            {expanded ? "Hide" : "Show"}
                                        </Button>}
                                    color='primary'>
                                    <div className='row mb-2'>
                                        <div className='col-auto'>
                                            <AutoAwesome
                                                sx={{
                                                    width: '16px'
                                                }} />
                                        </div>
                                        <div className='col my-auto'>
                                            <Typography
                                                level="title-sm">
                                                {'Hi again! 👋 . Want to know more about the sentiment of your app ?'}
                                            </Typography>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {loading ?
                                        <Typography
                                            level='body-xs'>
                                            <Skeleton>
                                                The general sentiment across the reviews is predominantly negative, with users expressing acute frustration with various technical difficulties.
                                                A major issue highlighted is the inability to log in, particularly after app updates, and persistent problems with the app's user interface, especially on iPads.
                                                There are very few positive mentions, which indicate occasional satisfaction with app updates and simplicity.
                                                What do you like to know?
                                            </Skeleton>
                                        </Typography>
                                        :
                                        <div>
                                            <Typography
                                                level="body-sm"
                                                className='ms-4 markdown-content'>
                                                {summary &&
                                                    <ReactMarkdown>{displayedText}</ReactMarkdown>
                                                }
                                            </Typography>
                                            {displayedText === summary &&
                                                <div className='ms-4 mt-2'>
                                                    <Typography
                                                        level="title-sm">
                                                        What do you like to do next?
                                                    </Typography>
                                                    <div className='row mt-3 gx-2'>
                                                        <div className='col-auto my-auto'>
                                                            <Chip
                                                                variant="outlined"
                                                                color="neutral"
                                                                size="lg"
                                                                startDecorator={<AutoAwesome
                                                                    sx={{
                                                                        width: '14px'
                                                                    }} />}
                                                                onClick={() => (openPersonaGenerator())}>
                                                                Generate user persona
                                                            </Chip>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <ShareMenu
                                                                app={app}
                                                                content={'Hi 👋, Look what I\'ve found using appinion\'s wingman. Can you take a look?\n\n' + summary}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </AccordionGroup>
                    </>
                </Alert>
            </>
        </div >
    );
}

export default WingmanSentiment;