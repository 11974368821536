import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import '../../styles/graphics.scss';

type DataPoint = {
    x: string; // Version
    y: number[]; // Sentiment counts [positive, negative, neutral]
};

type SentimentEvolutionProps = {
    data: DataPoint[];
};

function SentimentEvolution({ data }: SentimentEvolutionProps) {
    const options: ApexOptions = {
        chart: { type: "bar", stacked: true },
        xaxis: { categories: data.map(({ x }) => x), title: { text: "Version" } },
        yaxis: {
            title: { text: "Number of Reviews" },
            min: 0,
            max: Math.max(...data.map(({ y }) => y.reduce((acc, val) => acc + val, 0)))
        },
        fill: { opacity: 1 },
        colors: ["#B6DAFF", "#FECDD6", "#FFE6A7"], // Green for positive, red for negative, yellow for neutral
        tooltip: { y: { formatter: (value) => value.toString() } },
        legend: { position: "top" }
    };

    const series = [
        {
            name: "Positive",
            data: data.map(({ y }) => y[0]), // Extracting positive sentiment count
        },
        {
            name: "Negative",
            data: data.map(({ y }) => y[1]), // Extracting negative sentiment count
        },
        {
            name: "Neutral",
            data: data.map(({ y }) => y[2]), // Extracting neutral sentiment count
        },
    ];

    return (
        <div className='rating-evolution'>
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height={350} />
        </div>
    );

}

export default SentimentEvolution;