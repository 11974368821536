import React, { useState } from 'react';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import StarRatingComponent from '../StarRatingComponent';

import { Avatar, Button, Card, Chip, Divider, Snackbar, Typography } from '@mui/joy';
import { Report, Apple, Android } from '@mui/icons-material';

import '../../styles/appinfooverview.scss';

const networking = require('../../Networking/API');
const helper = require('../../Helpers/helpers');

function AppRatingComponent({ app, type }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [rating, setRating] = useState(null) as any;

    if (!rating) {
        getData();
    }

    function getData() {
        networking.ratingsFor(app.id, type).then((rating: any) => {
            setRating(rating);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    return (
        <>
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            <Card
                variant='outlined'
                sx={{
                    backgroundColor: 'white'
                }}>
                {loading ?
                    <LoadingSpinnerComponent />
                    :
                    <div>
                        <div className='row'>
                            <div className='col-md-auto my-auto iron legend bold'>
                                <Avatar>
                                    {helper.iconFor(type + 'store')}
                                </Avatar>
                            </div>
                            <div className='col-md-auto my-auto'>
                                {rating &&
                                    <>
                                        <Typography
                                            level='title-md'>
                                            {rating.userRatingCount}
                                            <span>
                                                <Typography
                                                    level='body-xs'>
                                                    {' ratings'}
                                                </Typography>
                                            </span>
                                        </Typography>
                                        <Typography
                                            level='body-sm'>
                                            {type === 'apple' ? app.apple_version : app.google_version}
                                        </Typography>
                                    </>
                                }
                            </div>
                            <div className='col-md-auto my-auto'>
                                {rating &&
                                    <div>
                                        <StarRatingComponent
                                            rating={rating.averageUserRating}
                                            size={'small'} />
                                    </div>
                                }
                            </div>
                            <div className='col-md my-auto'>
                                {rating &&
                                    <div className='bold iron hint'>{Math.round(rating.averageUserRating * 100) / 100}</div>
                                }
                            </div>
                        </div>
                        <Divider
                            sx={{
                                marginTop: 2,
                                marginBottom: 2
                            }}>

                            <Chip variant="soft" color="neutral" size="sm">
                                More
                            </Chip>
                        </Divider>
                        <div className=''>
                            <Typography
                                level='body-sm'>
                                {type === 'apple' ?
                                    app.apple_genres && app.apple_genres.map((genre: string) =>
                                        <>{genre + ','}</>
                                    ) :
                                    app.google_genres && app.google_genres.map((genre: string) =>
                                        <>{genre + ','}</>
                                    )
                                }
                            </Typography>
                            <Typography
                                level='body-sm'>
                                {type === 'apple' ?
                                    helper.formatFriendlyDate(app.apple_released, navigator.language) :
                                    app.google_released
                                }
                            </Typography>
                        </div>
                    </div>
                }
            </Card>
        </>
    )
}

export default AppRatingComponent;