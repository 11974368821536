import React, { useEffect, useState } from 'react';

import { Alert, Button, Snackbar, Typography, Accordion, AccordionGroup, AccordionDetails, Skeleton } from '@mui/joy';
import AccordionSummary, {
    accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';
import { Report, AutoAwesome } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import '../../styles/typography.scss'
import ShareMenu from './ShareMenu';

const networking = require('../../Networking/Wingman/wingmanAPI');

function WingmanRating({ app, data }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [summary, setSummary] = useState('');
    const [displayedText, setDisplayedText] = useState("");
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (summary.length !== 0) {
            let i = 0;
            const interval = setInterval(() => {
                setDisplayedText((prev) => summary.slice(0, i + 1)); // ✅ Fix: Use `slice()`
                i++;
                if (i >= summary.length) clearInterval(interval); // ✅ Fix: Stop correctly
            }, 5);

            return () => clearInterval(interval);
        }
    }, [summary]);

    const handleChange = (_: React.SyntheticEvent, expanded: boolean) => {
        setExpanded(expanded);
        if (expanded) {
            getData();
        }
    }

    function getData() {
        setLoading(true);

        networking.getRatingInsights(app.id, data).then((summ: any) => {
            setLoading(false);
            setSummary(summ.insights.summary);
        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    return (
        <div className='keywords'>
            {/* Error handling */}
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            <>
                <Alert
                    className="mb-3"
                    size="lg"
                    variant="soft"
                    color="primary"
                >
                    <>
                        <AccordionGroup
                            transition="0.2s">
                            <Accordion
                                color='primary'
                                expanded={expanded}
                                onChange={handleChange}
                                sx={{
                                    [`& .${accordionSummaryClasses.indicator}`]: {
                                        transition: 'none',
                                    },
                                    [`& [aria-expanded="true"] .${accordionSummaryClasses.indicator}`]: {
                                        transform: 'none',
                                    },
                                }}>
                                <AccordionSummary
                                    indicator={
                                        <Button
                                            variant='soft'>
                                            {expanded ? "Hide" : "Show"}
                                        </Button>}
                                    color='primary'>
                                    <div className='row mb-2'>
                                        <div className='col-auto'>
                                            <AutoAwesome
                                                sx={{
                                                    width: '16px'
                                                }} />
                                        </div>
                                        <div className='col my-auto'>
                                            <Typography
                                                level="title-sm">
                                                {'Hi 👋, I\'m your wingman. Click here to know more about your rating performance.'}
                                            </Typography>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {loading ?
                                        <Typography
                                            level='body-xs'>
                                            <Skeleton>
                                                The general sentiment across the reviews is predominantly negative, with users expressing acute frustration with various technical difficulties.
                                                A major issue highlighted is the inability to log in, particularly after app updates, and persistent problems with the app's user interface, especially on iPads.
                                                There are very few positive mentions, which indicate occasional satisfaction with app updates and simplicity.
                                                What do you like to know?
                                            </Skeleton>
                                        </Typography>
                                        :
                                        <div>
                                            <Typography
                                                level="body-sm"
                                                className='ms-4 markdown-content'>
                                                {summary &&
                                                    <ReactMarkdown>{displayedText}</ReactMarkdown>
                                                }
                                            </Typography>
                                            {displayedText === summary &&
                                                <div className='ms-4 mt-2'>
                                                    <Typography
                                                        level="title-sm">
                                                        What do you like to do next?
                                                    </Typography>
                                                    <div className='row mt-3 gx-2'>
                                                        <div className='col-auto'>
                                                            <ShareMenu
                                                                app={app}
                                                                content={'Hi 👋, Look what I\'ve found using appinion\'s wingman. Can you take a look?\n\n' + summary}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </AccordionGroup>
                    </>
                </Alert>
            </>
        </div >
    );
}

export default WingmanRating;