import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../resources/logo.svg'
import add_app from '../../resources/add_app.png'
import app_id from '../../resources/app_id.png'
import footer_appinion from '../../resources/footer_logo.png'

import step1 from '../../resources/step1.png'
import step2 from '../../resources/step2.png'
import step3 from '../../resources/step3.png'
import step4 from '../../resources/step4.png'
import step4_1 from '../../resources/step4_1.png'
import step5 from '../../resources/step5.png'
import step6 from '../../resources/step6.png'
import step7 from '../../resources/step7.png'
import step8 from '../../resources/step8.png'
import step9 from '../../resources/step9.png'
import step10 from '../../resources/step10.png'
import step11 from '../../resources/step11.png'
import step11_1 from '../../resources/step11_1.png'
import step11_2 from '../../resources/step11_2.png'
import step12 from '../../resources/step12.png'
import step13 from '../../resources/step13.png'
import step14 from '../../resources/step14.png'
import step15 from '../../resources/step15.png'
import step16 from '../../resources/step16.png'
import service_account from '../../resources/servive_account_copy.png'

function DocumentationMainComponent() {
    return (
        <div className=''>
            <nav className='navbar bg-purple '>
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src={logo} height={40 + 'px'} alt='logo'></img>
                    </a>
                    <Link to='/' style={{ textDecoration: 'none' }}>
                        <a className='iron bold nav-link'>Close</a>
                    </Link>
                </div>
            </nav>
            <div className='row bg-cloudie'>
                <div className='col-md-auto'>
                    <div id='list' className='p-5 list-group list-group-flush sticky-top'>
                        <a className='list-group-item iron ' href="#welcome">Welcome</a>
                        <p className='iron semi-bold  ms-2 mt-3'>Start using</p>
                        <a className='list-group-item iron ' href="#add">Adding a new App</a>
                        <p className='iron semi-bold  ms-2 mt-3'>Appinion SDK</p>
                        <a className='list-group-item iron ' href="#sdk_ios">iOS</a>
                        <a className='list-group-item iron ' href="#sdk_android">Android</a>
                    </div>
                </div>
                <div className='col-md'>
                    <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className='p-5 scrollspy-example' tabIndex={0}>
                        <div id='welcome' className='mb-5'>
                            <h4 className='iron bold'>Welcome</h4>
                            <p className='iron'>Appinion is the platform that will help you better understand what your users feel about your apps.
                                It will also help you improve your ratings on App stores using a proactive feedback strategy.</p>

                            <div className='ps-4'>
                                <h5 className='light-iron semi-bold mt-5'>Features</h5>
                                <h6 className='iron semi-bold mt-4'>Link your Appstore and Playstore review data easily</h6>
                                <p className='iron'>Appinion reduces the effort of collecting review information from your Appstore and Playstore reviews data.</p>

                                <h6 className='iron semi-bold mt-4'>Analyse key metrics of your reviews data</h6>
                                <p className='iron'>The Appinion platform has a convenient dashboard with key metrics of your reviews data.
                                    The heartbeat section allows you to follow the evolution of your users satisfaction.
                                    Organize your reviews by Keyword, enabling the track of particular funcionalities or issues.</p>

                                <h6 className='iron semi-bold mt-4'>Proactive feedback</h6>
                                <p className='iron'>The Appinion platform gives you a way of collecting users feedback directly on your apps.</p>

                                <h6 className='iron semi-bold mt-4'>Export data</h6>
                                <p className='iron'>Export data to CSV to use it on your other favorite tools.</p>

                            </div>
                        </div>
                        <div id='add' className=''>
                            <h4 className='iron bold'>Adding a new App</h4>

                            <p className='mt-3'>To add a new app, click on the "Add new app" button at the top and fill the
                                information about your app.</p>
                            {/* <div className='p-3 mb-5'><img style={ {width: 100 + '%'} } alt={ add_app } src={ add_app }></img></div> */}

                            <h5 className='light-iron semi-bold mt-2'>App icon</h5>
                            <p>Upload an icon for your App.</p>
                            <h5 className='light-iron semi-bold mt-2'>App name</h5>
                            <p>That's the App's name. Choose something that helps you identify the App.</p>
                            <h5 className='light-iron semi-bold mt-2'>App country</h5>
                            <p>Select the country store you are tracking. Currently each App is linked to a country.
                                Multi country tracking support will be available soon.</p>
                            <h4 className='light-iron semi-bold mt-2 ms-3 mt-5'>App Store</h4>
                            <h5 className='light-iron semi-bold mt-2 ms-3 mb-4'>App Id</h5>
                            <div className='p-3 mb-5'><img style={{ width: 50 + '%' }} alt={add_app} src={app_id}></img></div>
                            <p className='iron ms-3'>This is the App's Id on the Apple Appstore connect portal.</p>
                            <h4 className='light-iron semi-bold mt-2 ms-3 mt-5'>Play Store</h4>
                            <p className='iron ms-3'>To complete the folowing steps and be able to link your Google Play Store to Appinion
                                you need have access to the Google Play Developer Console as an account owner.</p>

                            <p className='iron ms-3'>Please, follow this steps:</p>
                            <p className='iron bold ms-3'>Step 1. Login to the Google Play Console</p>
                            <p className='iron  ms-3'>Open the <a href='https://play.google.com/console/u/0/signup'>Google Play Console</a> and log in as the account owner.
                                On a diferent tab open your <a href='https://console.cloud.google.com'>Google Cloud Console</a> page.</p>
                            <p className='iron  bold ms-3'>Make sure that you are with the correct profile on each console.</p>

                            <p className='iron bold ms-3'>Step 2. Create a Google Cloud Project</p>
                            <p className='iron  ms-3'>Select <b>Menu - IAM & Admin - Create a Project</b> and enter the Project Name.
                                Then hit the <b>Create</b> button.</p>

                            <p className='iron  ms-3'>Open the menu again and go to <b>APIs and Services - Enabled APIs and services</b></p>
                            <div className='p-3 mb-5'><img style={{ width: 100 + '%' }} alt={add_app} src={step4_1}></img></div>

                            <p className='iron bold ms-3'>Step 3. Search for APIs</p>
                            <p className='iron  ms-3'>Search for the following API’s and make sure they are all ENABLED:”</p>
                            <ul>
                                <li className='iron '><b>Google Cloud APIs </b><span className='hint'>(lets us authenticate you)</span></li>
                                <li className='iron '><b>Google Cloud Storage </b><span className='hint'>(allows us to access historical review data)</span></li>
                                <li className='iron '><b>Google Cloud Storage JSON API </b><span className='hint'>(also allows us to access historical review data)</span></li>
                                <li className='iron '><b>Google Play Android Developer </b><span className='hint'>(allows us to access current review data)</span></li>
                            </ul>
                            <div className='p-3 mb-5'><img style={{ width: 100 + '%' }} alt={add_app} src={step5}></img></div>

                            <p className='iron bold ms-3'>Step 6. Create Service Account</p>
                            <p className='iron  ms-3'>Select <b>Menu - IAM & Admin - Service accounts</b> and hit <b>Create Service Account</b>.</p>
                            <div className='p-3 mb-5'><img style={{ width: 100 + '%' }} alt={add_app} src={step8}></img></div>

                            <p className='iron bold ms-3'>Step 7. Account Name</p>
                            <p className='iron  ms-3'>Give the service account a name (this can be anything you want, but it’s a good idea to name it something to do with Appinion so you know what it is in future), and then click Create.</p>
                            <div className='p-3 mb-5'><img style={{ width: 100 + '%' }} alt={add_app} src={step9}></img></div>

                            <p className='iron bold ms-3'>Step 8. Roles</p>
                            <p className='iron  ms-3'>Under the Select a role dropdown, choose Basic and select Browser, then click Done.</p>
                            <div className='p-3 mb-5'><img style={{ width: 100 + '%' }} alt={add_app} src={step10}></img></div>

                            <p className='iron bold ms-3'>Step 9. Create Key</p>
                            <p className='iron  ms-3'>Click the three dots and then Manage keys.</p>
                            <div className='p-3 mb-5'><img style={{ width: 100 + '%' }} alt={add_app} src={step11}></img></div>

                            <p className='iron  ms-3'>From Add Key select Create new key.</p>
                            <div className='p-3 mb-5'><img style={{ width: 100 + '%' }} alt={add_app} src={step11_1}></img></div>

                            <p className='iron  ms-3'>Select JSON and then Create.</p>
                            <div className='p-3 mb-5'><img style={{ width: 100 + '%' }} alt={add_app} src={step11_2}></img></div>

                            <p className='iron bold ms-3'>Step 10. Save Key</p>
                            <p className='iron  ms-3'>You should then get a message telling you Private key saved to your computer and the
                                private key should begin downloading. Click Close on the dialog.</p>
                            <div className='p-3'><img style={{ width: 100 + '%' }} alt={add_app} src={step12}></img></div>
                            <p className='iron  ms-3'>Return to the Service Account main page and copy the user
                                email address for the service account.</p>
                            <div className='p-3'><img style={{ width: 100 + '%' }} alt={add_app} src={service_account}></img></div>

                            <p className='iron bold ms-3 mt-3'>Step 11. Granting access</p>
                            <p className='iron  ms-3'>Back in your <a href='https://play.google.com/console/u/0/signup'>Google Play Console</a>,
                                select <b>Menu - Users and Permissions</b></p>
                            <p className='iron  ms-3'>Hit the <b>Invite new users</b> button. Paste the email you copied from the
                                Service Account page then scroll to <b>Account Permissions</b>.</p>

                            <p className='iron bold ms-3'>Step 12. Account Permissions</p>
                            <p className='iron  ms-3'>Uncheck all except:</p>
                            <ol>
                                <li className='iron bold '>
                                    <p className='iron  ms-3'>View app information and download bulk reports (read-only) – so you’re only giving the Visibility permission to Appinion. This allows us to access the data in the Reports section of your Console account, which is where we obtain the historical review data.</p>
                                </li>
                                <li className='iron bold '>
                                    <p className='iron  ms-3'>Reply to reviews – so you can reply from within Appinion.</p>
                                </li>
                            </ol>

                            <p className='iron  ms-3'>Then press Invite User.</p>

                            <div className='p-3 mb-5'><img style={{ width: 100 + '%' }} alt={add_app} src={step13}></img></div>
                            <div className='p-3 mb-5'><img style={{ width: 100 + '%' }} alt={add_app} src={step14}></img></div>

                            <p className='iron bold ms-3'>Step 13. Complete conection</p>

                            <h5 className='light-iron semi-bold mt-4 ms-3 mb-4'>Package Id</h5>
                            <p>The Package Id is available on your Play Developer Console and should look like this <b>com.xxx.yourappname</b></p>
                            <h5 className='light-iron semi-bold mt-4 ms-3 mb-4'>Bucket Id</h5>
                            <p>Head back to your Play Developer Console and navigate to <b>Download reports</b> from the left menu.
                                Click into <b>Reviews</b> and select your app from the right.</p>

                            <p>Click on Copy Cloud Storage URI.</p>
                            <div className='p-3 mb-5'><img style={{ width: 100 + '%' }} alt={add_app} src={step15}></img></div>

                            <p>Paste it on the dedicated space.</p>
                            <div className='p-3 mb-5'><img style={{ width: 100 + '%' }} alt={add_app} src={step16}></img></div>

                            <h5 className='light-iron semi-bold mt-4 ms-3 mb-4'>Android json file</h5>
                            <p>Upload the json file stored at step 10.</p>

                            <p className='iron semi-bold mt-4 ms-3 mb-4'>Tap Save app button</p>

                            <div className='card bg-iron p-4'>
                                <p className='mb-0 white semi-bold ms-3'>NOTE: Google requires 24 hours for the permissions to take effect.
                                    The Android reviews will only be available after at least 24 hours. After this period if no Android reviews are available
                                    please contact us at main@appinion.pt</p>
                            </div>

                        </div>
                        <div className='mt-5'>
                            <h4 className='iron bold'>SDK integration overview</h4>
                            <h6 className='mt-3 mb-3 light-iron light'>About the SDK</h6>
                            <p>The SDK gives your App the ability to integrate with Appinions proactive feedback engine. You can ask for feedback at any point of your app, record your users opinion and redirect positive feedback directly to the appstores.</p>
                            <h6 className='mt-5 mb-3 light-iron light'>Compatibility</h6>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>iOS</th>
                                        <th>Android</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className=''>iOS 14.0</td>
                                        <td className=''><span className='hint'>minSDK</span> API 24 | <span className='hint'>targetAPI</span> API31</td>
                                    </tr>
                                    <tr>
                                        <td className=''></td>
                                        <td className=''><span className='hint'>targetAPI</span> API31</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h6 className='mt-5 mb-3 light-iron light'>Basic SDK integration</h6>
                            <h6 id='sdk_ios' className='mt-3 mb-3 light-iron'>iOS</h6>
                            <p>Initialize the SDK as soon as possible on your App life cycle.</p>
                            <hr></hr>
                            <div className='containter code-snippet'>
                                <p className='mb-3'>let config = Configuration(clientID: "###CLIENT_ID###", clientSecret: "###CLIENT_SECRET###", appID: "###APP_ID###", scope: "###SCOPE###", debug: true/false)</p>

                                <p className='mb-0'>let _ = AppinionSDK(with:config) &#123; error in</p>
                                <p className='ms-3 mb-0'>if let error &#123;</p>
                                <p className='ms-5 mb-0'>//TODO: Handle error</p>
                                <p className='ms-3 mb-0'>&#125;</p>
                                <p className='mb-0'>&#125;</p>
                            </div >
                            <hr></hr>
                            <p>Call a feedback journey</p>
                            <hr></hr>
                            <div className='containter code-snippet'>
                                <p className='mb-0'>var config = StoreFeedbackConfiguration(stage: ###STAGE_NAME###)</p>
                                <p className='mb-0'>config.userData = ExtraInfo(user_id: ##OTHER INFO##)</p>
                            </div >
                            <hr></hr>
                            <hr></hr>
                            <div className='code-snippet'>
                                <p className='mb-0'>self.rsm.start(on: ###LAUNCHING_VIEWCONTROLLER###, configuration: config) &#123; ready in</p>
                                <p className='ms-2 mb-0'>if(ready) &#123;</p>
                                <p className='ms-4 mb-0'>DispatchQueue.main.async &#123;</p>
                                <p className='ms-5 mb-0'>self.rsm.askFeedback()</p>
                                <p className='ms-4 mb-0'>&#125;</p>
                                <p className='ms-2 mb-0'>&#125;</p>
                                <p className='mb-0'>&#125;</p>
                            </div >
                            <hr></hr>
                            <h6 id='sdk_android' className='mt-3 mb-3 light-iron'>Android</h6>
                            <p>Integration</p>
                            <hr></hr>
                            <div className='containter code-snippet'>
                                <p className='mb-0'>Build.gradle (:app)</p>
                                <p className='mb-0'>implementation project(':androidsdk')</p>
                            </div >
                            <hr></hr>
                            <p>Implements SDKManagerInterface</p>
                            <hr></hr>
                            <div className='containter code-snippet'>
                                <p className='mb-3'>class YourClass : Fragment(), SDKManagerInterface</p>
                                <p className='mb-0'>override fun authenticate(resp: Boolean) &#123; </p>
                                <p className='ms-5 mb-0'>// This method returns true if sdk is authenticate</p>
                                <p className='mb-0'>&#125;</p>
                            </div >
                            <hr></hr>
                            <p>Declare the SdkManager as soon as possible</p>
                            <hr></hr>
                            <div className='containter code-snippet'>
                                <p className='mb-3'>public lateinit var sdkManager: SDKManager</p>
                            </div >
                            <hr></hr>
                            <p>Initialize the SDK as soon as possible on your App life cycle</p>
                            <hr></hr>
                            <div className='containter code-snippet'>
                                <p className='mb-0'>activity?.let &#123;</p>
                                <p className='ms-5 mb-0'>this.sdkManager = SDKManager(this, it)</p>
                                <p className='ms-5 mb-0'>this.sdkManager.authenticate(</p>
                                <p className='ms-5 mb-0'>appID = "###APP_ID###",</p>
                                <p className='ms-5 mb-0'>clientID = "###CLIENT_ID###",</p>
                                <p className='ms-5 mb-0'>clientSecret = "###CLIENT_SECRET###"</p>
                                <p className='mb-0'>&#125;</p>
                            </div >
                            <hr></hr>
                            <p>Call a feedback journey</p>
                            <hr></hr>
                            <div className='containter code-snippet'>
                                <p className='mb-0'>activity?.let &#123;</p>
                                <p className='ms-5 mb-0'>this.sdkManager.storeFeedbackConfiguration(###STAGE_NAME###)</p>
                                <p className='mb-0'>&#125;</p>
                            </div >
                            <hr></hr>
                        </div >
                    </div >
                </div >
                <div className='col-2'>

                </div>
            </div >
            <footer className='p-3 text-center'>
                <p className=' light-iron'>© Appinion 2025. All rights reserved</p>
                <img className='ms-3' src={footer_appinion} alt="logo" style={{ height: 16 + 'px' }}></img>
                <ul className='navbar-nav ms-auto'>
                    <li className='nav-item'>
                    </li>
                </ul>
            </footer>
        </div >
    );
}

export default DocumentationMainComponent;