import React, { useState } from 'react';
import { Report, CopyAll } from '@mui/icons-material';
import { Button, Card, Chip, CircularProgress, Divider, Skeleton, Snackbar, Typography } from '@mui/joy';

import '../../styles/predictor.scss';

const networking = require('../../Networking/Wingman/wingmanAPI');

function PredictorPersonaGenerator({ app, type }: any) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [success, setSuccess] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [data, setData] = useState<any>();

    function getData() {
        setLoading(true);

        networking.generatePersona(app.id).then((data: any) => {
            createPersona(data.insights.content);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setShowError(true);
            setLoading(false);
        });
    }

    function createPersona(data: string) {
        let object = JSON.parse(data);

        setData(object);
    }

    const shareToSlack = () => {
        networking.shareOnSlack(app.id, shareMessage).then((result: any) => {
            setShowSuccess(true);
            setSuccess('Shared to your slack channel.');
        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
        });
    }

    const shareToTeams = () => {
        networking.shareOnTeams(app.id, shareMessage).then((result: any) => {
            setShowSuccess(true);
            setSuccess('Shared to your teams channel.');
        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
        });
    }

    var shareMessage =
        `## ${data?.name} ## \n\n\n**Description**\n\n${data?.description} \n\n**Problem**\n\n${data?.problem}\n\n**Pain points**\n\n${data?.pain_points}\n\n**Goal**\n\n${data?.goal}\n\n**Benefits**\n\n${data?.benefits}\n\n**Trigger**\n\n${data?.triggers}\n\n**Doubts**\n\n${data?.doubts}\n\n`;

    const copyToClipboard = () => {
        setShowSuccess(true);
        setSuccess('Copied to clipboard.');

        navigator.clipboard.writeText(shareMessage);
    }

    return (
        <div className=''>
            {/* Error handling */}
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            {showSuccess &&
                <Snackbar
                    variant="soft"
                    color="success"
                    open={showSuccess}
                    onClose={() => setShowSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowSuccess(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {success}
                </Snackbar>
            }
            <div className=''>
                {loading ?
                    <Typography
                        level='body-xs'>
                        <Skeleton>
                            The general sentiment across the reviews is predominantly negative, with users expressing acute frustration with various technical difficulties.
                            A major issue highlighted is the inability to log in, particularly after app updates, and persistent problems with the app's user interface, especially on iPads.
                            There are very few positive mentions, which indicate occasional satisfaction with app updates and simplicity.
                            What do you like to know?
                        </Skeleton>
                    </Typography>
                    :
                    <div className='container-fluid'>
                        <div className='row gx-1'>
                            <div className='col'>
                                <Button onClick={getData}
                                    loading={loading}>
                                    {loading ? <CircularProgress size="sm" /> : "Generate Persona"}
                                </Button>
                            </div>
                            {data && app.slack_webhook_url &&
                                <div className='col-auto'>

                                    <Chip
                                        variant="outlined"
                                        color="neutral"
                                        size="lg"
                                        startDecorator={<CopyAll
                                            sx={{
                                                width: '14px'
                                            }} />}
                                        onClick={shareToSlack}>
                                        Share on Slack
                                    </Chip>

                                </div>
                            }
                            {data && app.teams_webhook_url &&
                                <div className='col-auto'>
                                    <Chip
                                        variant="outlined"
                                        color="neutral"
                                        size="lg"
                                        startDecorator={<CopyAll
                                            sx={{
                                                width: '14px'
                                            }} />}
                                        onClick={shareToTeams}>
                                        Share on Teams
                                    </Chip>

                                </div>
                            }
                            <div className='col-auto'>
                                {
                                    data &&
                                    <Chip
                                        variant="outlined"
                                        color="neutral"
                                        size="lg"
                                        startDecorator={<CopyAll
                                            sx={{
                                                width: '14px'
                                            }} />}
                                        onClick={copyToClipboard}>
                                        Copy
                                    </Chip>
                                }
                            </div>
                        </div>
                        {data &&
                            <div className='row mt-3 gx-3'>
                                <Card
                                    color='primary'>
                                    <Typography
                                        level='title-sm'>
                                        {data.name}
                                    </Typography>
                                    <Typography
                                        level='body-sm'>
                                        {data.description}
                                    </Typography>
                                    <Divider sx={{ margin: 1 }} />
                                    <div className='row g-2'>
                                        <div className='col'>
                                            <Typography
                                                level='title-sm'
                                                fontWeight={700}>
                                                Problem
                                            </Typography>
                                            <Typography
                                                level='body-sm'
                                                fontWeight={600}
                                                sx={{ marginBottom: 1 }}>
                                                Your persona struggles from this issue.
                                            </Typography>
                                            <Typography
                                                level='body-sm'>
                                                {data.problem}
                                            </Typography>
                                        </div>
                                        <div className='col'>
                                            <Typography
                                                level='title-sm'
                                                fontWeight={700}>
                                                Pain points
                                            </Typography>
                                            <Typography
                                                level='body-sm'
                                                fontWeight={600}
                                                sx={{ marginBottom: 1 }}>
                                                Especially from these negative experiences.
                                            </Typography>
                                            <Typography
                                                level='body-sm'>
                                                {data.pain_points && data.pain_points.map((i: any, index: any) => (
                                                    <li key={index}>
                                                        {i}
                                                    </li>
                                                ))
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider sx={{ margin: 1 }} />
                                    <div className='row g-2'>
                                        <div className='col'>
                                            <Typography
                                                level='title-sm'
                                                fontWeight={700}>
                                                Goal
                                            </Typography>
                                            <Typography
                                                level='body-sm'
                                                fontWeight={600}
                                                sx={{ marginBottom: 1 }}>
                                                Your Persona wants to achieve this transformation.
                                            </Typography>
                                            <Typography
                                                level='body-sm'>
                                                {data.goal}
                                            </Typography>
                                        </div>
                                        <div className='col'>
                                            <Typography
                                                level='title-sm'
                                                fontWeight={700}>
                                                Benefits
                                            </Typography>
                                            <Typography
                                                level='body-sm'
                                                fontWeight={600}
                                                sx={{ marginBottom: 1 }}>
                                                So she/he can experience these positive experiences.
                                            </Typography>
                                            <Typography
                                                level='body-sm'>
                                                {data.benefits && data.benefits.map((i: any, index: any) => (
                                                    <li key={index}>
                                                        {i}
                                                    </li>
                                                ))
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider sx={{ margin: 1 }} />
                                    <div className='row g-2'>
                                        <div className='col'>
                                            <Typography
                                                level='title-sm'
                                                fontWeight={700}>
                                                Trigger
                                            </Typography>
                                            <Typography
                                                level='body-sm'
                                                fontWeight={600}
                                                sx={{ marginBottom: 1 }}>
                                                This pushed your persona to take action.
                                            </Typography>
                                            <Typography
                                                level='body-sm'>
                                                {data.triggers}
                                            </Typography>
                                        </div>
                                        <div className='col'>
                                            <Typography
                                                level='title-sm'
                                                fontWeight={700}>
                                                Doubts
                                            </Typography>
                                            <Typography
                                                level='body-sm'
                                                fontWeight={600}
                                                sx={{ marginBottom: 1 }}>
                                                But these doubts slow her/him down.
                                            </Typography>
                                            <Typography
                                                level='body-sm'>
                                                {data.doubts && data.doubts.map((i: any, index: any) => (
                                                    <li key={index}>
                                                        {i}
                                                    </li>
                                                ))
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        }
                    </div>
                }
            </div>

        </div>
    )
}

export default PredictorPersonaGenerator;