import React, { useState } from 'react';

import '../../styles/appinfooverview.scss';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import RatingDistributionComponent from '../Dashboard/RatingDistributionComponent';
import StarRatingComponent from '../StarRatingComponent';

import { Card, Stack, Tooltip, Typography } from '@mui/joy';

const networking = require('../../Networking/API');

function AppInfoOverviewComponent(app: any) {
    var average = 0;
    var reviewsByRating = [];

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];

    if (!reviews) {
        getData();
    }

    function getData() {
        networking.reviewsFor(app.app.id).then((reviews: any) => {
            setReviews(reviews.filter((d: any) => d.store_id !== ''));
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    if (reviews) {
        const ratingSet = [1, 2, 3, 4, 5] as number[];
        var ratings = [] as number[]

        reviews.filter((d: any) => parseInt(d.rating) !== 0).forEach((review: any) => {
            ratings.push(parseInt(review.rating));
        });

        const total = ratings.reduce((a, v) => a + v, 0);
        average = (total / ratings.length);

        reviewsByRating = ratingSet.map((r: number) =>
            reviews.filter((d: any) => parseInt(d.rating) === r)
        );
    }

    return (
        <Card
            variant='outlined'
            sx={{
                backgroundColor: 'white'
            }}>
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div>
                    <div className='row'>
                        <div className='col-md my-auto'>
                            <div className='text-center'>
                                <Typography
                                    color='neutral'
                                    fontWeight={600}
                                    level='body-xs'>
                                    {"Total Reviews".toUpperCase()}
                                </Typography>
                                <Typography
                                    color='primary'
                                    fontWeight={300}
                                    sx={{
                                        fontSize: 52
                                    }}>
                                    {reviews.length}
                                </Typography>
                            </div>
                        </div>
                        <div className='col-md my-auto'>
                            <Tooltip
                                title="Average rating from written reviews."
                                variant="solid">
                                <Stack
                                    spacing={1}
                                    sx={{
                                        textAlign: 'center'
                                    }}>
                                    <Typography
                                        color='neutral'
                                        fontWeight={600}
                                        level='body-xs'>
                                        {"Average rating".toUpperCase()}
                                    </Typography>
                                    {average && <StarRatingComponent rating={average} />}
                                    <Typography
                                        color='neutral'
                                        fontWeight={600}
                                        level='body-xs'>
                                        {average.toFixed(2)}
                                        <span>
                                            <Typography
                                                color='neutral'
                                                level='body-xs'>
                                                {" Stars"}
                                            </Typography>
                                        </span>
                                    </Typography>
                                </Stack>
                            </Tooltip>
                        </div>
                        <div className='col-md mx-auto p-3'>
                            <RatingDistributionComponent
                                reviews={reviewsByRating}
                                total={reviews.length} />
                        </div>
                    </div>
                </div >
            }
        </Card >
    )
}

export default AppInfoOverviewComponent;