import { DateFilterValue } from "../components/Filters/DateFilter";

export const getDateRange = (filter: DateFilterValue): { start?: Date; end?: Date } => {
    const now = new Date();
    const start = new Date(now);
    start.setHours(0, 0, 0, 0);

    switch (filter) {
        case "last7":
            start.setDate(now.getDate() - 7);
            break;
        case "last14":
            start.setDate(now.getDate() - 14);
            break;
        case "last90":
            start.setDate(now.getDate() - 90);
            break;
        case "last365":
            start.setDate(now.getDate() - 365);
            break;
        case "thisMonth":
            start.setDate(1);
            break;
        case "lastMonth":
            start.setMonth(now.getMonth() - 1, 1);
            break;
        case "thisYear":
            start.setMonth(0, 1);
            break;
        case "allTime":
            return { start: undefined, end: undefined };
    }

    return { start, end: now };
}