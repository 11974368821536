import React, { useState, useEffect } from 'react';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import '../../styles/reviews.scss';
import { Alert, Avatar, Button, Chip, FormControl, FormLabel, Skeleton, Snackbar, Stack, Textarea, Typography } from '@mui/joy';
import { AutoAwesome, Report, Star } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import Rating from "@mui/material/Rating"

const networking = require('../../Networking/API');
const wingmanAPI = require('../../Networking/Wingman/wingmanAPI');
const helper = require('../../Helpers/helpers');

function ReplyPopUpComponent({ subscription, app, review, handler }: any) {
    const [loading, setLoading] = useState(false);
    const [wmloading, setwmLoading] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [displayedText, setDisplayedText] = useState("");
    const [wingmanSuggestions, setWingmanSuggestions] = useState("");
    const [wingmanTone, setWingmanTone] = useState("");
    const [wingmanClassification, setWingmanClassification] = useState<number>(1);
    const [defaultResponse, setDefaultResponse] = useState("");

    useEffect(() => {
        if (wingmanSuggestions.length !== 0) {
            let i = 0;
            const interval = setInterval(() => {
                setDisplayedText((prev) => wingmanSuggestions.slice(0, i + 1)); // ✅ Fix: Use `slice()`
                i++;
                if (i >= wingmanSuggestions.length) clearInterval(interval); // ✅ Fix: Stop correctly
            }, 5);

            return () => clearInterval(interval);
        }
    }, [wingmanSuggestions]);

    const sendReply = (event: any) => {
        setLoading(true);

        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());

        if (review.store === 'playstore') {
            networking.sendPlaystoreReply(app.id, review.store_id, formJson.reply).then((success: boolean) => {
                setLoading(false);

                handler(true);
            }).catch((error: Error) => {
                setError(error.message);
                setShowError(true);
                setLoading(false);
            });
        } else {
            networking.sendAppstoreReply(app.id, review.store_id, formJson.reply).then((success: boolean) => {
                setLoading(false);
                handler(true);
            }).catch((error: Error) => {
                setError(error.message);
                setShowError(true);
                setLoading(false);
            });
        }
    };

    const getWingmanSuggestions = () => {
        setwmLoading(true);
        wingmanAPI.getResponseForReview(app, review.content).then((r: any) => {
            setwmLoading(false);
            setWingmanSuggestions(r.insights.response);
            setWingmanClassification(r.insights.responsability);
            setWingmanTone(r.insights.tone);
        }).catch((error: Error) => {
            setError(error.message);
            setShowError(true);
            setwmLoading(false);
        });
    };

    const getNewResponse = () => {
        getWingmanSuggestions()
    }

    const applyResponse = () => {
        setDefaultResponse(wingmanSuggestions);
    }

    return (
        <div>
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            {loading ?
                <LoadingSpinnerComponent />
                :
                <>
                    <div className='row mt-2 mb-4'>
                        <div className='col-auto'>
                            <Avatar>
                                {helper.getInitials(review.author)}
                            </Avatar>
                        </div>
                        <div className='col'>
                            <Alert>
                                <Stack
                                    direction='column'
                                    spacing={1}>
                                    <div>
                                        <Rating
                                            value={Number(review.rating)}
                                            readOnly size="small"
                                            emptyIcon={<Star
                                                style={{ opacity: 0.55 }}
                                                fontSize="inherit" />} />
                                    </div>
                                    <div>
                                        {review.content}
                                    </div>
                                </Stack>
                            </Alert>
                        </div>
                    </div>

                    {(subscription.plan === 'AAA' || subscription.plan === 'U') &&
                        < Alert
                            className="mt-2 mb-2"
                            size="lg"
                            variant="soft"
                            color="primary"
                        >
                            <>
                                <div>
                                    <div className='row gx-2 mb-2'>
                                        <div className='col-auto'>
                                            <AutoAwesome
                                                sx={{
                                                    width: '16px'
                                                }} />
                                        </div>
                                        <div className='col-auto my-auto'>
                                            <Typography
                                                level="title-sm">
                                                {'Hi 👋, I’m your Wingman. \
                                                Need help with a reply?'}
                                            </Typography>
                                        </div>
                                        <div className='col-auto'>
                                            {wingmanSuggestions.length !== 0 &&
                                                <Chip
                                                    variant="solid"
                                                    color={wingmanClassification > 4 ? "danger" : wingmanClassification > 3 ? "warning" : "success"}
                                                    size="sm"
                                                >
                                                    {wingmanClassification > 4 ? "Must answer" : wingmanClassification > 3 ? "Should answer" : "Not urgent"}
                                                </Chip>
                                            }
                                        </div>
                                        <div className='col-auto'>
                                            {wingmanSuggestions.length !== 0 &&
                                                <Chip
                                                    variant="solid"
                                                    color="neutral"
                                                    size="sm"
                                                >
                                                    {wingmanTone}
                                                </Chip>
                                            }
                                        </div>
                                    </div>
                                    {wmloading ?
                                        <Typography
                                            level='body-xs'>
                                            <Skeleton>
                                                The general sentiment across the reviews is predominantly negative, with users expressing acute frustration with various technical difficulties.
                                                A major issue highlighted is the inability to log in, particularly after app updates, and persistent problems with the app's user interface, especially on iPads.
                                                There are very few positive mentions, which indicate occasional satisfaction with app updates and simplicity.
                                                What do you like to know?
                                            </Skeleton>
                                        </Typography>
                                        :
                                        <>
                                            <Typography
                                                level="body-sm"
                                                className='ms-4 markdown-content'>
                                                {wingmanSuggestions &&
                                                    <ReactMarkdown>
                                                        {displayedText}
                                                    </ReactMarkdown>
                                                }
                                            </Typography>

                                            {displayedText === wingmanSuggestions &&
                                                <>
                                                    <div className='row mt-3 gx-2'>
                                                        <div className='col-auto my-auto'>
                                                            <Chip
                                                                variant="outlined"
                                                                color="neutral"
                                                                size="lg"
                                                                startDecorator={<AutoAwesome
                                                                    sx={{
                                                                        width: '14px'
                                                                    }} />}
                                                                sx={{
                                                                    marginLeft: 2
                                                                }}
                                                                onClick={() => (getNewResponse())}>
                                                                Generate new reply
                                                            </Chip>
                                                        </div>
                                                    </div>
                                                    {wingmanSuggestions.length !== 0 &&
                                                        <div className=''>
                                                            <Chip
                                                                variant="solid"
                                                                color="primary"
                                                                size="lg"
                                                                sx={{
                                                                    marginLeft: 2,
                                                                    marginTop: 2
                                                                }}
                                                                onClick={() => (applyResponse())}>
                                                                Use this reply.
                                                            </Chip>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </>
                        </Alert>
                    }
                    <div className='form-group'>
                        <form
                            onSubmit={(event) => {
                                sendReply(event)
                            }}>
                            <FormControl
                                sx={{
                                    marginTop: 2
                                }}>
                                <FormLabel>Reply</FormLabel>
                                <Textarea
                                    name="reply"
                                    defaultValue={defaultResponse}
                                    required />
                            </FormControl>
                            <Button
                                type='submit'
                                sx={{ mt: 6 /* margin top */ }}>
                                Send reply
                            </Button>
                        </form>
                    </div>
                </>
            }
        </div >
    )
}

export default ReplyPopUpComponent;