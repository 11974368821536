import React, { useState, useEffect, useRef } from 'react';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import SentimentEvolution from './SentimentEvolution';
import EmptyMessageComponent from '../EmptyMessageComponent';

import '../../styles/dropdowns.scss';

import { Select, Option, Stack, Typography, Card, Alert, FormLabel, Box, Avatar, Divider, Table } from '@mui/joy';
import { Star } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStore } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons'
import RatingDistributionGraph from './RatingDistributionComponent';
import RatingEvolutionGraph from './RatingEvolutionComponent';
import WingmanRating from '../Wingman/WingmanRating';
import SentimentEvolutionOverTime from './SentimentEvolutionOverTime';
import WingmanSentiment from '../Wingman/WingmanSentiment';
import ReviewsByVersion from './ReviewsByVersion';
import Rating from "@mui/material/Rating";

import DateFilter, { DateFilterValue } from '../Filters/DateFilter';
import { getDateRange } from '../../Helpers/DateHelpers';

const networking = require('../../Networking/API');
const hbHelper = require('../../Helpers/heartbeatDataHandler');
const helper = require('../../Helpers/helpers');

function HeartBeatComponent({ subscription, app }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];

    //Filter states
    const [currentStore, setCurrentStore] = useState('');
    const [currentSentiment, setCurrentSentiment] = useState('All');
    const [currentRating, setCurrentRating] = useState<number>(5);
    const [selectedFilter, setSelectedFilter] = useState<DateFilterValue>("last7");

    const hasFetched = useRef(false);
    const { start, end } = getDateRange(selectedFilter);

    useEffect(() => {
        if (!hasFetched.current) {
            getData();
            hasFetched.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getData() {
        networking.reviewsFor(app.id).then((reviews: any) => {
            setLoading(false);

            setReviews(reviews);

        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function filterByStore(e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        setCurrentStore(value)
    }

    function filterBySentiment(e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        setCurrentSentiment(value)
    }

    var currentItems = reviews && reviews.filter((r: any) => r.store !== 'profeed');

    if (currentItems) {
        if (currentStore === 'Appstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'applestore');

        } else if (currentStore === 'Playstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'playstore');

        }

        currentItems = currentItems.filter((r: Review) => Number(r.rating) <= currentRating);

        if (currentSentiment !== 'All') {
            currentItems = currentItems.filter((r: Review) => r.sentiment.toLowerCase() === currentSentiment.toLowerCase());
        }

        //Apply Date filters
        currentItems = currentItems.filter((review: Review) => {
            const reviewDate = new Date(review.updated);
            return (!start || reviewDate >= start) && (!end || reviewDate <= end);
        });
    }

    return (
        <>
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div className='col'>
                    {/* Filters and Actions */}
                    <Box
                        component='section'
                        color='primary'
                        sx={{
                            marginBottom: 2,
                            backgroundColor: '#f4f4f4',
                            borderRadius: '0.5rem',
                            padding: '1rem',
                        }}>
                        <div className='row'>
                            <div className='col-auto mt-auto'>
                                <div className='row'>
                                    <div
                                        className='col-auto my-auto '>
                                        <Avatar
                                            alt={app.name + 'App icon'}
                                            src={app.icon}
                                            size='lg'>
                                            {helper.getInitials(app.name)}
                                        </Avatar>
                                    </div>
                                </div>
                            </div>
                            <div className='col-auto my-auto'>
                                <FormLabel
                                    sx={{
                                        fontSize: '0.75rem',
                                        marginBottom: 0.25
                                    }}>
                                    Source
                                </FormLabel>
                                <Select
                                    color="neutral"
                                    defaultValue={'all'}
                                    size="sm"
                                    variant="outlined"
                                    onChange={filterByStore}>
                                    <Option value="all">
                                        <span className="me-2"><FontAwesomeIcon icon={faStore} /></span>
                                        All
                                    </Option>
                                    <Option value="apple">
                                        <span className="me-2"><FontAwesomeIcon icon={faAppStore} />
                                        </span> Appstore
                                    </Option>
                                    <Option value="google"><span className="me-2">
                                        <FontAwesomeIcon icon={faGooglePlay} /></span> Playstore
                                    </Option>
                                </Select>
                            </div>
                            <div className='col-auto my-auto'>
                                <FormLabel
                                    sx={{
                                        fontSize: '0.75rem',
                                        marginBottom: 0.25
                                    }}>
                                    Sentiment
                                </FormLabel>
                                <Select
                                    color="neutral"
                                    defaultValue={'all'}
                                    size="sm"
                                    variant="outlined"
                                    onChange={filterBySentiment}>
                                    <Option value="all">
                                        All
                                    </Option>
                                    <Option value="good">
                                        Good
                                    </Option>
                                    <Option value="neutral">
                                        Neutral
                                    </Option>
                                    <Option value="bad">
                                        Bad
                                    </Option>
                                </Select>
                            </div>
                            <div className='col-auto my-auto'>
                                <FormLabel
                                    sx={{
                                        fontSize: '0.75rem',
                                        marginBottom: 0.25
                                    }}>
                                    Rating
                                </FormLabel>
                                <Select
                                    value={currentRating || 5}
                                    color="neutral"
                                    size="sm"
                                    variant="outlined"
                                    placeholder="Filter by rating"
                                    onChange={(_, newValue) => setCurrentRating(newValue ?? 5)}
                                    renderValue={(selected) =>
                                        selected ? (
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Rating
                                                    value={Number(selected.value)}
                                                    readOnly size="small"
                                                    emptyIcon={<Star
                                                        style={{ opacity: 0.55 }}
                                                        fontSize="inherit" />} />
                                                <Typography level='body-xs'>
                                                    {selected.value} Stars
                                                </Typography>
                                            </Stack>
                                        ) : (
                                            "Filter by rating"
                                        )
                                    }
                                >
                                    {[5, 4, 3, 2, 1].map((rating) => (
                                        <Option
                                            key={rating}
                                            value={rating}>
                                            <Stack direction="row"
                                                spacing={1}
                                                alignItems="center">
                                                <Rating
                                                    value={rating}
                                                    readOnly size="small"
                                                    emptyIcon={<Star
                                                        style={{ opacity: 0.55 }}
                                                        fontSize="inherit" />} />
                                                <Typography level='body-xs'>
                                                    {rating} Stars
                                                </Typography>
                                            </Stack>
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='col-auto my-auto'>
                                <FormLabel
                                    sx={{
                                        fontSize: '0.75rem',
                                        marginBottom: 0.25
                                    }}>
                                    Date range
                                </FormLabel>
                                <DateFilter
                                    selectedFilter={selectedFilter}
                                    onChange={setSelectedFilter} />
                            </div>
                        </div>
                    </Box>
                    {
                        currentItems && currentItems.length === 0 &&
                        <Card
                            sx={{
                                backgroundColor: 'white'
                            }}>
                            <EmptyMessageComponent
                                title={'We were not able to generate your heartbeat.'}
                                description={'We\'ve not found any reviews. Please try another search criteria.'} />
                        </Card>
                    }
                    {currentItems && currentItems.length !== 0 &&
                        <>
                            {app && subscription && (subscription.plan === 'AAA' || subscription.plan === 'U') &&
                                <WingmanRating
                                    app={app}
                                    data={
                                        [hbHelper.getRatingOverTimeWingman(currentItems),
                                        hbHelper.getRatingOverVersionWingman(currentItems),
                                        hbHelper.getRatingDistributionWingman(currentItems)]} />
                            }
                            {app && subscription && (subscription.plan === 'A' || subscription.plan === 'AA') &&
                                <Alert
                                    className="mb-3"
                                    size="lg"
                                    variant="soft"
                                    color="primary">
                                    <div>
                                        <div>The wingman</div>
                                        <Typography
                                            level="body-sm"
                                        >
                                            Your plan does not include the wingman feature. To enable it, please upgrade your plan.
                                        </Typography>
                                    </div>
                                </Alert>
                            }

                            <div className='mb-3'>
                                <Card
                                    variant="outlined"
                                    color='neutral'
                                    sx={{
                                        backgroundColor: 'white'
                                    }}>
                                    <Typography
                                        color='neutral'
                                        fontWeight={600}
                                        level='body-xs'>
                                        {"Reviews timeline".toUpperCase()}
                                    </Typography>
                                    <Typography
                                        color='primary'
                                        fontWeight={300}
                                        level='h2'>
                                        {currentItems.length}
                                        <span>
                                            <Typography
                                                color='neutral'
                                                level='body-xs'>
                                                {" Reviews"}
                                            </Typography>
                                        </span>
                                        <span>
                                            <Typography
                                                color='neutral'
                                                fontWeight={400}
                                                level='body-xs'>
                                                {" (avg " + (currentItems.length / hbHelper.aggregateReviewsByDate(currentItems).seriesData.length).toFixed(0) + " per day)"}
                                            </Typography>
                                        </span>
                                    </Typography>
                                    <Divider />
                                    {currentItems &&
                                        <ReviewsByVersion
                                            data={hbHelper.aggregateReviewsByDate(currentItems).seriesData}
                                            annotations={hbHelper.getVersionAnnotations(currentItems)} />}
                                </Card>
                            </div>
                            <div className='row gx-2 mt-3'>
                                <div className='col'>
                                    <Card
                                        variant="outlined"
                                        color='neutral'
                                        sx={{
                                            backgroundColor: 'white'
                                        }}>
                                        <Typography
                                            color='neutral'
                                            fontWeight={600}
                                            level='body-xs'>
                                            {"Average rating".toUpperCase()}
                                        </Typography>
                                        <Typography
                                            color='primary'
                                            fontWeight={300}
                                            level='h2'>
                                            {helper.calculateAverageRating(currentItems)}
                                            <span>
                                                <Typography
                                                    color='neutral'
                                                    level='body-xs'>
                                                    {" Average Rating"}
                                                </Typography>
                                            </span>
                                        </Typography>
                                        <Divider />
                                        {reviews &&
                                            <RatingEvolutionGraph
                                                type="bar"
                                                data={hbHelper.getRatingOverVersion(currentItems)} />}
                                    </Card>
                                </div>
                                <div className='col'>
                                    <Card
                                        variant="outlined"
                                        color='neutral'
                                        sx={{
                                            backgroundColor: 'white'
                                        }}>
                                        <Typography
                                            color='neutral'
                                            fontWeight={600}
                                            level='body-xs'>
                                            {"Rating breakdown".toUpperCase()}
                                        </Typography>
                                        <Typography
                                            color='primary'
                                            fontWeight={300}
                                            level='h2'>
                                            {helper.calculateAverageRating(currentItems)}
                                            <span>
                                                <Typography
                                                    color='neutral'
                                                    level='body-xs'>
                                                    {" Average Rating"}
                                                </Typography>
                                            </span>
                                        </Typography>
                                        <Divider />
                                        {currentItems &&
                                            <RatingDistributionGraph
                                                data={hbHelper.getRatingDistribution(currentItems)} />}
                                    </Card>
                                </div>
                            </div>
                            <div className='mt-2'>
                                <Card
                                    variant="outlined"
                                    color='neutral'
                                    sx={{
                                        backgroundColor: 'white'
                                    }}>
                                    <Typography
                                        color='neutral'
                                        fontWeight={600}
                                        level='body-xs'>
                                        {"Average rating timeline".toUpperCase()}
                                    </Typography>
                                    <Typography
                                        color='primary'
                                        fontWeight={300}
                                        level='h2'>
                                        {helper.calculateAverageRating(currentItems)}
                                        <span>
                                            <Typography
                                                color='neutral'
                                                level='body-xs'>
                                                {" Average Rating"}
                                            </Typography>
                                        </span>
                                    </Typography>
                                    <Divider />
                                    {currentItems &&
                                        <RatingEvolutionGraph
                                            type="area"
                                            data={hbHelper.getRatingOverTime(currentItems)}
                                            annotations={hbHelper.getVersionAnnotations(currentItems)} />}
                                </Card>
                            </div>
                            {app && subscription && (subscription.plan === 'AAA' || subscription.plan === 'U') &&
                                <div className='mt-3'>
                                    <WingmanSentiment
                                        app={app}
                                        data={
                                            [hbHelper.getSentimentEvolutionOverVersionWingman(currentItems),
                                            hbHelper.getSentimentEvolutionOverTimeWingman(currentItems)]} />
                                </div>
                            }
                            {app && subscription && (subscription.plan === 'A' || subscription.plan === 'AA') &&
                                <Alert
                                    className="mb-3"
                                    size="lg"
                                    variant="soft"
                                    color="primary">
                                    <div>
                                        <div>The wingman</div>
                                        <Typography
                                            level="body-sm"
                                        >
                                            Your plan does not include the wingman feature. To enable it, please upgrade your plan.
                                        </Typography>
                                    </div>
                                </Alert>
                            }
                            <div>
                                <Card
                                    variant="outlined"
                                    color='neutral'
                                    sx={{
                                        backgroundColor: 'white'
                                    }}>
                                    <Typography
                                        color='neutral'
                                        fontWeight={600}
                                        level='body-xs'>
                                        {"Sentiment breakdown".toUpperCase()}
                                    </Typography>
                                    <Divider />
                                    {reviews &&
                                        <SentimentEvolution
                                            data={hbHelper.getSentimentEvolutionOverVersion(currentItems).seriesData} />}
                                </Card>
                                <div className='mt-2'>
                                    <Card
                                        variant="outlined"
                                        color='neutral'
                                        sx={{
                                            backgroundColor: 'white'
                                        }}>
                                        <Typography
                                            color='neutral'
                                            fontWeight={600}
                                            level='body-xs'>
                                            {"Sentiment timeline".toUpperCase()}
                                        </Typography>
                                        <Divider />
                                        {currentItems &&
                                            <SentimentEvolutionOverTime
                                                data={hbHelper.getSentimentEvolutionOverTime(currentItems).seriesData}
                                                annotations={hbHelper.getVersionAnnotations(currentItems)} />}
                                    </Card>
                                </div>
                            </div>

                        </>
                    }
                </div >
            }
        </>
    );

}

export default HeartBeatComponent;