import React, { useState } from 'react';

import AlertComponent from '../AlertComponent';
import IssueComponent from '../IssueComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import EmptyMessageComponent from '../EmptyMessageComponent';

const networking = require('../../Networking/API');

function TopKeywordsComponent({ app, limit, showDetails, reviews }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    var [issues, setIssues] = useState(null) as any[];
    var [topReviews, setTopReviews] = useState(null) as any[];

    var currentReviews = reviews

    if (!topReviews && currentReviews === undefined) {
        getData();
    }

    if (!issues) {
        getMainIssues();
    }

    function updatePage() {
        getMainIssues()
    }

    function getData() {
        networking.reviewsFor(app.id).then((reviews: any) => {
            setTopReviews(reviews);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function getMainIssues() {
        networking.issuesFor(app.id).then((issues: any) => {
            setIssues(issues);
            setLoading(false);
        }).catch((error: Error) => {
            console.log(error.stack);
            setError(error.message);
            setLoading(false);
        });
    }

    return (
        <div>
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div>
                    {(currentReviews || topReviews) &&
                        < IssueComponent
                            app={app}
                            reviews={currentReviews ?? topReviews}
                            issues={issues}
                            limit={limit}
                            showHeader={showDetails}
                            handler={updatePage} />
                    }
                </div>
            }
        </div>
    )

}

export default TopKeywordsComponent;