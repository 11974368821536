import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

function KeywordsHitEvolution({ reviews, preview }: any) {

    const uniqueVersions = [...new Set(reviews.map((review: Review) => review.version))];
    var graphData: any[] = []

    uniqueVersions.map((version: any) =>
        graphData.push(reviews.filter((r: any) => r.version === version).length)
    )

    const options: ApexOptions = {
        tooltip: {
            enabled: false
        },
        dataLabels: {
            enabled: false // Hides the data labels
        },
        chart: {
            type: "area",
            zoom: { enabled: false },
            toolbar: { show: false },
            animations: { enabled: false }
        },
        grid: {
            show: false
        },
        stroke: { curve: 'straight', width: 1 },
        xaxis: {
            categories: uniqueVersions,
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            labels: {
                show: false
            }
        }
    }

    var data = {
        series: [{
            name: "Hits",
            data: graphData
        }]
    };


    return (
        <div className='p-0'>
            <ReactApexChart
                options={options}
                series={data.series}
                type="area"
                height={preview === true ? 70 : 100} />
        </div>
    );
}

export default KeywordsHitEvolution;