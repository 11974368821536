import React, { useState, useEffect, useRef } from 'react';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import TopKeywordsComponent from '../Dashboard/TopKeywordsComponent';
import ReviewsDistributionByIssue from './ReviewsDistributionByIssue';
import GroupPopUpComponent from './GroupPopUpComponent';
import EmptyMessageComponent from '../EmptyMessageComponent';
import WordCloud from "react-d3-cloud";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStore } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons'

import Alert from '@mui/joy/Alert';
import Typography from '@mui/joy/Typography';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import ModalClose from '@mui/joy/ModalClose';
import Card from '@mui/joy/Card';
import { Avatar, Box, Divider, FormLabel, ModalOverflow, Stack, Table } from '@mui/joy';
import { Add, Star } from '@mui/icons-material';

import WingmanKeywords from '../Wingman/WingmanKeywords';
import Rating from "@mui/material/Rating"
import DateFilter, { DateFilterValue } from '../Filters/DateFilter';
import { getDateRange } from '../../Helpers/DateHelpers';
import ReviewComponent from '../Reviews/ReviewComponent';

const networking = require('../../Networking/API');
const helper = require('../../Helpers/helpers');
const hbHelper = require('../../Helpers/heartbeatDataHandler');

function KeywordsComponent({ subscription, app }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];
    const [issues, setIssues] = useState(null) as any[];
    const [groupToAdd, setGroupToAdd] = useState(false);
    const [viewMode, setViewMode] = useState('global');
    const [expanded, setExpanded] = useState(false);
    const [showReviews, setShowReviews] = useState(false);
    const [currentReviews, setCurrentReviews] = useState(null) as any[];
    const [currentWord, setCurrentWord] = useState('');

    //Filter states
    const [currentLimit, setCurrentLimit] = useState(5);
    const [currentStore, setCurrentStore] = useState('');
    const [currentSentiment, setCurrentSentiment] = useState('All');
    const [currentRating, setCurrentRating] = useState<number>(5);
    const [currentState, setCurrentState] = useState('');
    const [currentVersion, setCurrentVersion] = useState('All');
    const [selectedFilter, setSelectedFilter] = useState<DateFilterValue>("last7");

    const hasFetched = useRef(false);
    const { start, end } = getDateRange(selectedFilter);

    useEffect(() => {
        if (!hasFetched.current) {
            getAllData();
            hasFetched.current = true;
        }

        setExpanded(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStore, currentSentiment, currentRating, currentVersion, selectedFilter]);

    const getAllData = () => {
        getData();
        getMainIssues();
    }

    function updatePage() {
        setGroupToAdd(false);
        setLoading(true);
        getAllData()
    }

    function getData() {
        networking.reviewsFor(app.id).then((reviews: any) => {
            setReviews(reviews);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function getMainIssues() {
        networking.issuesFor(app.id).then((issues: any) => {
            setIssues(issues);
            setLoading(false);
        }).catch((error: Error) => {
            console.log(error.stack);
            setError(error.message);
            setLoading(false);
        });
    }

    function filterByVersion(e: any) {
        e.preventDefault();

        const value = e.target.innerText;

        setCurrentVersion(value)
        setCurrentLimit(5);
    }

    function filterByStore(e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        setCurrentStore(value)
        setCurrentLimit(5);
    }

    function filterByState(e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        setCurrentState(value)
        setCurrentLimit(5);
    }

    function filterBySentiment(e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        setCurrentSentiment(value)
        setCurrentLimit(5);
    }

    function toggleView() {
        setViewMode(viewMode === 'global' ? 'detail' : 'global')
    }

    const replySent = (event: any) => {
        updatePage();
    };

    function showReviewsFor(word: string) {
        setCurrentWord(word);

        const r = currentItems.filter((review: Review) =>
            review.content.toLocaleLowerCase().includes(word.toLocaleLowerCase())
        )

        setCurrentReviews(r)
        setShowReviews(true)
    }

    var currentItems = reviews;
    var versions: any[] = [];

    if (issues && reviews) {
        reviews.sort(function (a: any, b: any) {
            return new Date(b.updated).getTime() - new Date(a.updated).getTime();
        });

        versions = [...new Set(reviews.map((r: any) => r.version))];
        versions.sort(function (a: any, b: any) {
            return a !== null && a.localeCompare(b, undefined, { numeric: true });
        });
        versions.unshift("All");

        if (currentStore === 'Appstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'applestore');

        } else if (currentStore === 'Playstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'playstore');

        }

        if (currentVersion !== 'All') {
            currentItems = currentItems.filter((r: any) => r.version === currentVersion);
        }

        if (currentSentiment !== 'All') {
            currentItems = currentItems.filter((r: Review) => r.sentiment.toLowerCase() === currentSentiment.toLowerCase());
        }

        //Apply Date filters
        currentItems = currentItems.filter((review: Review) => {
            const reviewDate = new Date(review.updated);
            return (!start || reviewDate >= start) && (!end || reviewDate <= end);
        });
    }

    return (
        <div className='keywords'>
            {/* New Topic modal */}
            <Modal
                open={groupToAdd}
                onClose={() => setGroupToAdd(false)}>
                <ModalDialog
                    color="neutral"
                    layout="center"
                    size="lg"
                    variant="plain">
                    <ModalClose />
                    <DialogTitle>
                        Create new topic
                    </DialogTitle>
                    <DialogContent>
                        Define keywords to spotlight key insights from reviews.
                    </DialogContent>
                    <>
                        <GroupPopUpComponent
                            app={app}
                            title=''
                            message=''
                            type='group'
                            handler={replySent}
                        />
                    </>
                </ModalDialog>
            </Modal>
            {/* Review list Modal */}
            <Modal
                open={showReviews}
                onClose={() => setShowReviews(false)}>
                <ModalDialog
                    color="neutral"
                    layout="center"
                    size="lg"
                    variant="plain">
                    <ModalClose />
                    <Typography
                        component="h2"
                        id="close-modal-title"
                        level="h4"
                        textColor="inherit"
                        sx={{ fontWeight: 'lg' }}
                    >
                        Reviews
                    </Typography>
                    <div>
                        {currentReviews && currentReviews.length === 0 ?
                            <EmptyMessageComponent
                                title="No reviews found"
                                description="No reviews found for this issue."
                            /> :
                            <div className='review-list'>
                                {
                                    currentReviews && currentReviews.map((rev: any) =>
                                        <ReviewComponent
                                            app={app}
                                            review={rev}
                                            tokens={[currentWord]} />
                                    )
                                }
                            </div>
                        }
                    </div>
                </ModalDialog>
            </Modal>
            {/* Page Banner */}
            <Alert
                className="mb-3"
                size="lg"
                variant="soft"
                color="neutral"
                endDecorator={
                    <Button
                        color={viewMode === 'global' ? 'neutral' : 'primary'}
                        size='sm'
                        variant={viewMode === 'global' ? 'outlined' : 'solid'}
                        onClick={toggleView}>
                        {viewMode === 'global' ? 'Manage' : 'Done'}
                    </Button>
                }>
                <div>
                    <div>
                        <Typography
                            level="title-md">
                            Topics & keywords
                        </Typography>
                    </div>
                    <Typography
                        level="body-sm">
                        Here you can group reviews into topics by matching keywords,
                        giving you a clear view of what your users care about most.
                    </Typography>
                </div>
            </Alert>
            {/* Filters and Actions */}
            {viewMode === 'global' &&
                <Box
                    component='section'
                    color='primary'
                    sx={{
                        marginBottom: 2,
                        backgroundColor: '#f4f4f4',
                        borderRadius: '0.5rem',
                        padding: '1rem',
                    }}>
                    <div className='row'>
                        <div className='col-auto mt-auto'>
                            <div className='row'>
                                <div
                                    className='col-auto my-auto '>
                                    <Avatar
                                        alt={app.name + 'App icon'}
                                        src={app.icon}
                                        size='lg'>
                                        {helper.getInitials(app.name)}
                                    </Avatar>
                                </div>
                            </div>
                        </div>
                        <div className='col-auto my-auto'>
                            <FormLabel
                                sx={{
                                    fontSize: '0.75rem',
                                    marginBottom: 0.25
                                }}>
                                Source
                            </FormLabel>
                            <Select
                                color="neutral"
                                defaultValue={'all'}
                                size="sm"
                                variant="outlined"
                                onChange={filterByStore}>
                                <Option value="all">
                                    <span className="me-2"><FontAwesomeIcon icon={faStore} /></span>
                                    All
                                </Option>
                                <Option value="apple">
                                    <span className="me-2"><FontAwesomeIcon icon={faAppStore} />
                                    </span> Appstore
                                </Option>
                                <Option value="google"><span className="me-2">
                                    <FontAwesomeIcon icon={faGooglePlay} /></span> Playstore
                                </Option>
                            </Select>
                        </div>
                        <div className='col-auto my-auto'>
                            <FormLabel
                                sx={{
                                    fontSize: '0.75rem',
                                    marginBottom: 0.25
                                }}>
                                Sentiment
                            </FormLabel>
                            <Select
                                color="neutral"
                                defaultValue={'all'}
                                size="sm"
                                variant="outlined"
                                onChange={filterBySentiment}>
                                <Option value="all">
                                    All
                                </Option>
                                <Option value="good">
                                    Good
                                </Option>
                                <Option value="neutral">
                                    Neutral
                                </Option>
                                <Option value="bad">
                                    Bad
                                </Option>
                            </Select>
                        </div>
                        <div className='col-auto my-auto'>
                            <FormLabel
                                sx={{
                                    fontSize: '0.75rem',
                                    marginBottom: 0.25
                                }}>
                                Status
                            </FormLabel>
                            <Select
                                color="neutral"
                                defaultValue={'all'}
                                size="sm"
                                variant="outlined"
                                onChange={filterByState}>
                                <Option value="all">All</Option>
                                <Option value="new">New</Option>
                                <Option value="follow">Follow</Option>
                                <Option value="assigned">Assigned</Option>
                                <Option value="closed">Closed</Option>
                            </Select>
                        </div>
                        <div className='col-auto my-auto'>
                            <FormLabel
                                sx={{
                                    fontSize: '0.75rem',
                                    marginBottom: 0.25
                                }}>
                                Version
                            </FormLabel>
                            <Select
                                color="neutral"
                                placeholder="All versions"
                                size="sm"
                                variant="outlined"
                                onChange={filterByVersion}>
                                {
                                    versions.reverse().map((version: string) => {
                                        return (
                                            <Option value={version}>
                                                {version}
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                        </div>
                        <div className='col-auto my-auto'>
                            <FormLabel
                                sx={{
                                    fontSize: '0.75rem',
                                    marginBottom: 0.25
                                }}>
                                Rating
                            </FormLabel>
                            <Select
                                value={currentRating || 5}
                                color="neutral"
                                size="sm"
                                variant="outlined"
                                placeholder="Filter by rating"
                                onChange={(_, newValue) => setCurrentRating(newValue ?? 5)}
                                renderValue={(selected) =>
                                    selected ? (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Rating
                                                value={Number(selected.value)}
                                                readOnly size="small"
                                                emptyIcon={<Star
                                                    style={{ opacity: 0.55 }}
                                                    fontSize="inherit" />} />
                                            <Typography level='body-xs'>
                                                {selected.value} Stars
                                            </Typography>
                                        </Stack>
                                    ) : (
                                        "Filter by rating"
                                    )
                                }
                            >
                                {[5, 4, 3, 2, 1].map((rating) => (
                                    <Option
                                        key={rating}
                                        value={rating}>
                                        <Stack direction="row"
                                            spacing={1}
                                            alignItems="center">
                                            <Rating
                                                value={rating}
                                                readOnly size="small"
                                                emptyIcon={<Star
                                                    style={{ opacity: 0.55 }}
                                                    fontSize="inherit" />} />
                                            <Typography level='body-xs'>
                                                {rating} Stars
                                            </Typography>
                                        </Stack>
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className='col-auto my-auto'>
                            <FormLabel
                                sx={{
                                    fontSize: '0.75rem',
                                    marginBottom: 0.25
                                }}>
                                Date range
                            </FormLabel>
                            <DateFilter
                                selectedFilter={selectedFilter}
                                onChange={setSelectedFilter} />
                        </div>
                    </div>
                </Box>
            }
            {viewMode === 'global' && app && subscription && (subscription.plan === 'AAA' || subscription.plan === 'U') &&
                <WingmanKeywords
                    count={currentItems && currentItems.length}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    app={app} />
            }
            {app && subscription && (subscription.plan === 'A' || subscription.plan === 'AA') &&
                <Alert
                    className="mb-3"
                    size="lg"
                    variant="soft"
                    color="primary">
                    <div>
                        <div>The wingman</div>
                        <Typography
                            level="body-sm"
                        >
                            Your plan does not include the wingman feature. To enable it, please upgrade your plan.
                        </Typography>
                    </div>
                </Alert>
            }
            {/* Error handling */}
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div className='col'>
                    <div className='mt-4'>
                        {viewMode === 'global' &&
                            <Stack
                                spacing={2}>
                                <Card
                                    variant="outlined"
                                    color='neutral'
                                    sx={{
                                        backgroundColor: 'white'
                                    }}>
                                    <Typography
                                        color='neutral'
                                        fontWeight={600}
                                        level='body-xs'>
                                        {"Words".toUpperCase()}
                                    </Typography>
                                    <Divider />
                                    {currentItems && hbHelper.countKeywords(currentItems).length === 0 ?
                                        <EmptyMessageComponent
                                            title={'No relevant words found.'}
                                            description={'Relevante words must be mentioned at least 2 times to be featured here.'} />
                                        :
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th style={{ backgroundColor: '#ffffff' }}>Word</th>
                                                    <th style={{ backgroundColor: '#ffffff' }} className='text-center'>Hits</th>
                                                    <th style={{ backgroundColor: '#ffffff' }} className='text-center'>Overall</th>
                                                </tr>
                                            </thead>
                                            {currentItems && hbHelper.countKeywords(currentItems).slice(0, currentLimit).map((word: any, index: number) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Typography
                                                            level="body-sm"
                                                            fontWeight={700}>
                                                            <Button
                                                                variant='plain'
                                                                color='neutral'
                                                                style={{
                                                                    textDecoration: 'underline',
                                                                }}
                                                                onClick={() => showReviewsFor(word.text)}>
                                                                {word.text}
                                                            </Button>
                                                        </Typography>
                                                    </td>
                                                    <td className='text-center'>
                                                        <Typography
                                                            level="body-sm">
                                                            {word.value}
                                                        </Typography>
                                                    </td>
                                                    <td className='text-center'>
                                                        <Typography
                                                            level="body-sm">
                                                            {((word.value / currentItems.length) * 100).toFixed(0)} %
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                        </Table>
                                    }

                                    {currentItems && hbHelper.countKeywords(currentItems).length > currentLimit &&
                                        <>
                                            <Button
                                                variant='soft'
                                                color='neutral'
                                                onClick={() => setCurrentLimit(currentLimit + 5)}>
                                                Show more
                                            </Button>
                                        </>
                                    }
                                </Card>
                                <Card
                                    variant="outlined"
                                    color='neutral'
                                    sx={{
                                        backgroundColor: 'white'
                                    }}>
                                    <Typography
                                        color='neutral'
                                        fontWeight={600}
                                        level='body-xs'>
                                        {"Word cloud".toUpperCase()}
                                    </Typography>
                                    <Divider />
                                    {currentItems && hbHelper.countKeywords(currentItems).length > 0 ?
                                        <WordCloud
                                            data={hbHelper.countKeywords(currentItems)}
                                            height={200}
                                            fontSize={(word) => Math.log2(word.value) * 5}
                                            rotate={(word) => word.value % 360}
                                            padding={5}
                                            fill="#007bff" // Appinion blue
                                            spiral="rectangular"
                                            onWordClick={(event, d) => {
                                                showReviewsFor(d.text);
                                            }}
                                        />
                                        :
                                        <EmptyMessageComponent
                                            title={'No relevant words found.'}
                                            description={'Relevante words must be mentioned at least 2 times to be featured here.'} />
                                    }
                                </Card>
                                <Card
                                    color="neutral"
                                    orientation="vertical"
                                    size="lg"
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: 'white'
                                    }}>
                                    <div className='row'>
                                        <div className='col my-auto'>
                                            <Typography
                                                color='neutral'
                                                fontWeight={600}
                                                level='body-xs'>
                                                {"Topics".toUpperCase()}
                                            </Typography>
                                        </div>
                                        <div className='col-auto text-end'>
                                            <Button
                                                size='sm'
                                                startDecorator={<Add />}
                                                onClick={() => setGroupToAdd(true)}>
                                                Add new topic
                                            </Button>
                                        </div>
                                    </div>

                                    <Divider />
                                    {issues && issues.length === 0 &&
                                        <EmptyMessageComponent
                                            title={'Oh no!! You don\'t have group created yet!.'}
                                            description={'You can create groups and associate keywords to \
                                                        see your reviews separated by topic.'} />
                                    }

                                    {issues && issues.length !== 0 &&
                                        <>
                                            <TopKeywordsComponent
                                                reviews={currentItems}
                                                app={app}
                                                store={currentStore}
                                                version={currentVersion}
                                                state={currentState}
                                                showDetails={true} />
                                        </>
                                    }

                                </Card>
                            </Stack>
                        }

                        {viewMode === 'detail' &&
                            <div className='mt-3'>
                                <ReviewsDistributionByIssue
                                    reviews={currentItems}
                                    issues={issues}
                                    handler={updatePage} />
                            </div>
                        }
                    </div>
                </div>
            }
        </div >
    );
}

export default KeywordsComponent;