import csvDownload from 'json-to-csv-export'

import React, { useState } from 'react';
import { Modal, ModalDialog, ModalClose, DialogTitle, DialogContent, ModalOverflow, Stepper, StepIndicator, StepButton, Step, Button, Option, Divider, Card, Select, Switch, Input } from '@mui/joy';
import { Typography } from '@mui/joy';
import { Check, Search } from '@mui/icons-material';

import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import FeedbackResultComponent from './FeedbackResultComponent';
import mockup_1 from '../../resources/rvt-il-1.png'
import mockup_3 from '../../resources/rvt-il-3.png'

import '../../styles/typography.scss';
import '../../styles/pills.scss';

const networking = require('../../Networking/API');
const helper = require('../../Helpers/helpers');

function StageComponent({ app, stage, onRemoveStage }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [results, setResults] = useState(null) as any[];
    const [showPreview, setShowPreview] = useState(false);
    const [showReviews, setShowReviews] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [currentVersion, setCurrentVersion] = useState('All');
    const [showTextOnly, setShowTextOnly] = useState(true);
    const [textFilter, setTextFilter] = useState('');

    var steps = ['', '', ''];
    var yes: any[] = [];
    var no: any[] = [];
    var cleanResults: any[] = [];
    var completed: any[] = [];
    var notCompleted: any[] = [];
    var total = 0;
    let list = null;
    var barSourceData: any[] = [];
    var barSpecialSourceData: any[] = [];
    var versions: any[] = [];
    var currentItems: any[] = [];

    if (!results) {
        getData();
    }

    function getData() {
        networking.getQueriesForStage(stage.id).then((results: any) => {
            setResults(results);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function removeStage(id: string) {
        onRemoveStage(id)
    }

    function filterByVersion(e: any) {
        e.preventDefault();

        const value = e.target.innerText;

        setCurrentVersion(value)
    }

    function filterByText(e: any) {
        e.preventDefault();
        setShowTextOnly(!showTextOnly)
    }

    function exportToCSV() {
        const name = "feedback_" + new Date().toLocaleDateString();

        const data = {
            data: currentItems,
            filename: name + ".csv"
        }

        csvDownload(data)
    }

    if (results) {
        yes = results.filter((r: any) => r.vote === true)
        no = results.filter((r: any) => r.vote === false)
        completed = results.filter((r: any) => r.completed === true)
        notCompleted = results.filter((r: any) => r.completed === false)
        total = completed.length + notCompleted.length

        let answerResults = no.filter((r: any) => r.feedback !== '').filter((r: any) => r.feedback !== null).filter((r: any) => r.answers.length === 0);
        let questionsResults = no.filter((r: any) => r.answers.length > 0);

        cleanResults = answerResults.concat(questionsResults).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        currentItems = cleanResults;

        barSourceData = groupQueriesByDate(results);
        barSpecialSourceData = groupQueriesByDayAndClassification(results);

        versions = [...new Set(cleanResults.map((r: any) => r.version))];
        versions.sort(function (a: any, b: any) {
            return a.localeCompare(b, undefined, { numeric: true });
        });
        versions.unshift("All");

        if (currentVersion !== 'All') {
            currentItems = currentItems.filter((r: any) => r.version === currentVersion);
        }

        if (showTextOnly) {
            currentItems = currentItems.filter((r: any) => r.feedback !== '')
        }

        if (textFilter.length !== 0) {
            currentItems = currentItems.filter((r: any) => r.feedback.toLowerCase().includes(textFilter.toLocaleLowerCase()))
        }

        list = currentItems.map((feedback: any, index: number) =>
            <div className=''>
                <FeedbackResultComponent feedback={feedback} />
            </div>
        )
    }

    const categories = Object.keys(barSourceData).sort();
    const sourceData = categories.map((date: any) => barSourceData[date]);
    const goodData = categories.map((date: any) => barSpecialSourceData[date].good);
    const badData = categories.map((date: any) => barSpecialSourceData[date].bad);

    const answerCounts = cleanResults
        .flatMap((review: any) => review.answers)
        .reduce<Record<string, number>>((acc: any, answer: any) => {
            acc[answer] = (acc[answer] || 0) + 1;
            return acc;
        }, {});

    function groupQueriesByDate(queries: any[]): any {
        return queries.reduce((acc, query) => {
            const day = query.date.split('T')[0];
            acc[day] = (acc[day] || 0) + 1;
            return acc;
        }, {} as Record<string, number>);
    }

    function groupQueriesByDayAndClassification(queries: any[]) {
        return queries.reduce((acc, query) => {
            const day = query.date.split('T')[0]; // Extract the date (YYYY-MM-DD)
            if (!acc[day]) {
                acc[day] = { good: 0, bad: 0 }; // Initialize counts
            }
            if (query.vote) {
                acc[day].good += 1; // Increment good count
            } else {
                acc[day].bad += 1; // Increment bad count
            }
            return acc;
        }, {} as Record<string, { good: number; bad: number }>);
    }

    const completionOptions: ApexOptions = {
        legend: {
            show: true,
        },
        colors: ["#B6DAFF", "#FECDD6"],
        labels: ["Completed", "Not completed"],
        dataLabels: {
            enabled: true,
            dropShadow: {
                enabled: false
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '0%'
                },
                expandOnClick: false
            }
        }
    }

    const responseOptions: ApexOptions = {
        legend: {
            show: true,
        },
        fill: { opacity: 1 },
        colors: ["#B6DAFF", "#FECDD6"],
        labels: ["Positive", "Negative"],
        dataLabels: {
            enabled: true,
            dropShadow: {
                enabled: false
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '0%'
                },
                expandOnClick: false
            }
        }
    }

    const lineOptions: ApexOptions = {
        legend: {
            show: false,
        },
        chart: {
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        stroke: {
            width: 2,
            curve: "straight"
        },
        xaxis: {
            categories: categories,
            labels: {
                show: true, // Hides the Y-axis labels
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false, // Hides the Y-axis ticks
            },
        },
        yaxis: {
            axisBorder: {
                show: false // Hides the left axis line
            },
            axisTicks: {
                show: false // Hides the ticks on the axis
            }
        },
        grid: {
            show: false // Hides all grid lines
        },
    }

    const lineData = {
        series: [{
            name: "Interactions",
            data: sourceData
        }]
    };

    const barOptions: ApexOptions = {
        legend: {
            show: false,
        },
        chart: {
            stacked: true,
            stackType: '100%',
            toolbar: {
                show: false, // This hides the toolbar entirely
            },
        },
        colors: ["#B6DAFF", "#FECDD6"],
        xaxis: {
            categories: categories,
            labels: {
                show: true, // Hides the Y-axis labels
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false, // Hides the Y-axis ticks
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '100%'
            }
        },
        grid: {
            show: false // Hides all grid lines
        },
        yaxis: {
            axisBorder: {
                show: false // Hides the left axis line
            },
            axisTicks: {
                show: false // Hides the ticks on the axis
            }
        }
    }

    const barData = {
        series: [
            {
                name: 'Good Queries',
                data: goodData,
            },
            {
                name: 'Bad Queries',
                data: badData,
            },
        ],
    };

    const chartOptions: ApexOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false, // This hides the toolbar entirely
            },
        },
        dataLabels: {
            enabled: true,
            offsetX: -6,
        },
        xaxis: {
            categories: Object.keys(answerCounts),
            labels: {
                show: true, // Hides the Y-axis labels
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false, // Hides the Y-axis ticks
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '100%',
                dataLabels: {
                    position: 'top',
                },
            },
        },
        grid: {
            show: false // Hides all grid lines
        },
        yaxis: {
            axisBorder: {
                show: false // Hides the left axis line
            },
            axisTicks: {
                show: false // Hides the ticks on the axis
            }
        }
    };

    const answerData = {
        series: [
            {
                name: 'Count',
                data: Object.values(answerCounts), // Corresponding counts
            }
        ],
    };

    return (
        <>
            {/* Stage preview Modal */}
            <Modal
                open={showPreview}
                onClose={() => setShowPreview(false)}>
                <ModalOverflow>
                    <ModalDialog
                        color="neutral"
                        layout="center"
                        size="md"
                        variant="plain">
                        <ModalClose />
                        <DialogTitle>
                            Stage preview
                        </DialogTitle>
                        <DialogContent>
                            Preview how your stage will look like.
                        </DialogContent>
                        <>
                            <div className='mx-auto'>
                                <Stepper sx={{ width: '90%' }}>
                                    {steps.map((step, index) => (
                                        <Step
                                            key={step}
                                            indicator={
                                                <StepIndicator
                                                    variant={activeStep <= index ? 'soft' : 'solid'}
                                                    color={activeStep < index ? 'neutral' : 'primary'}
                                                >
                                                    {activeStep <= index ? index + 1 : <Check />}
                                                </StepIndicator>
                                            }
                                            sx={[
                                                activeStep > index &&
                                                index !== 2 && { '&::after': { bgcolor: 'primary.solidBg' } },
                                            ]}
                                        >
                                            <StepButton onClick={() => setActiveStep(index + 1)}>{step}</StepButton>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                            <div className="iphone-frame">
                                <div className="iphone-notch"></div>
                                <div className="screen">
                                    <div className='preview bg-cloudie mx-auto'>
                                        {activeStep === 1 &&
                                            <img
                                                alt='Preview for stage step 1'
                                                className='mt-4'
                                                src={mockup_1} />
                                        }
                                        {activeStep === 3 &&
                                            <img
                                                alt='Preview for stage step 1'
                                                className='mt-4'
                                                src={mockup_3} />
                                        }

                                        <div className='ps-5 pe-5 mt-5'>
                                            {activeStep === 1 &&
                                                <h6 className='iron regular'>{stage.first_message}</h6>
                                            }
                                            {activeStep === 2 &&
                                                <h6 className='iron regular'>{stage.second_message}</h6>
                                            }
                                            {activeStep === 3 &&
                                                <h6 className='iron regular'>{stage.final_message}</h6>
                                            }
                                        </div>
                                        <div>
                                            {activeStep === 1 &&
                                                <p className='iron bold mt-5'>Are you enjoying?</p>
                                            }
                                        </div>
                                        {activeStep === 2 &&
                                            <div>
                                                {
                                                    stage.questions.map((question: string, index: number) =>
                                                        <div>
                                                            <div className='row p-1'>
                                                                <div className='ps-5 col'>
                                                                    <p className='iron legend m-1'>{question}</p>
                                                                </div>
                                                                <div className='col-auto pe-5'>
                                                                    <div className='check'></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                <p className='iron bold hint'>Provide more information:</p>
                                                <div className='ps-5 pe-5 mb-5'>
                                                    <textarea className="form-control" id="recipient-name"></textarea>
                                                </div>
                                            </div>
                                        }
                                        {activeStep === 1 &&
                                            <div className=''>
                                                <div className='mx-auto mb-3 p-1 preview-btn bg-greenie'>
                                                    <p className='bold white m-0'>Yes</p>
                                                </div>
                                                <div className='mx-auto p-1 preview-btn'>
                                                    <p className='bold iron m-0'>No</p>
                                                </div>
                                            </div>
                                        }
                                        {activeStep === 2 &&
                                            <div className=''>
                                                <div className='mx-auto mb-3 p-1 preview-btn bg-greenie'>
                                                    <p className='bold white m-0'>Send</p>
                                                </div>
                                                <div className='mx-auto p-1 preview-btn'>
                                                    <p className='bold iron m-0'>Not now</p>
                                                </div>
                                            </div>
                                        }
                                        {activeStep === 3 &&
                                            <div className='mx-auto mb-3 p-1 preview-btn bg-greenie mt-5'>
                                                <p className='bold white m-0'>Close</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
            {/* Review list Modal */}
            <Modal
                open={showReviews}
                onClose={() => setShowReviews(false)}>
                <ModalOverflow>
                    <ModalDialog
                        color="neutral"
                        layout="center"
                        size="md"
                        variant="plain"
                        maxWidth={'800px'}>
                        <ModalClose />
                        <DialogTitle>
                            Stage feedback
                        </DialogTitle>
                        <DialogContent>
                            Feedback list
                        </DialogContent>
                        <>
                            <div className='row mt-2 mb-2'>
                                <div className='col'>
                                    <Input
                                        type="text"
                                        size='sm'
                                        placeholder="Type in here…"
                                        startDecorator={<Search />}
                                        onChange={(event) => setTextFilter(event.target.value)}
                                    />
                                </div>
                                {cleanResults && cleanResults.length !== 0 &&
                                    <>
                                        <div className='col-auto my-auto'>
                                            <Select
                                                color="neutral"
                                                placeholder="Version"
                                                size="sm"
                                                variant="outlined"
                                                onChange={filterByVersion}>
                                                {
                                                    versions.reverse().map((version: string) => {
                                                        return (
                                                            <Option value={version}>{version}</Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </>
                                }
                                <div className='col-auto my-auto'>
                                    <Typography
                                        component="label"
                                        level='body-xs'
                                        startDecorator={
                                            <Switch
                                                checked={showTextOnly}
                                                onChange={(e) => filterByText(e)}
                                                sx={{ mr: 1 }} />
                                        }>
                                        Show with text only
                                    </Typography>
                                </div>
                                <div className='col-auto my-auto'>
                                    <Button
                                        onClick={() => exportToCSV()}>
                                        Export to csv
                                    </Button>
                                </div>
                            </div>
                            {list}
                        </>
                    </ModalDialog>
                </ModalOverflow>
            </Modal >
            {error && <div> <AlertComponent alert={error} /> </div>
            }
            {
                loading ?
                    <LoadingSpinnerComponent />
                    :
                    <div className='p-3'>
                        <Typography
                            level="title-sm">
                            Created at
                            <span>
                                <Typography
                                    className='col'
                                    level="body-sm"
                                    sx={{
                                        marginBottom: 2
                                    }}>
                                    {" " + helper.formatFriendlyDate(stage.creation_date, navigator.language)}
                                </Typography>
                            </span>
                        </Typography>
                        <Typography
                            level="title-sm">
                            Stage available every
                            <span>
                                <Typography
                                    className='col'
                                    level="body-sm"
                                    sx={{
                                        marginBottom: 2
                                    }}>
                                    {" " + stage.ask_every} days.
                                </Typography>
                            </span>
                        </Typography>
                        {stage.version_toggle === true &&
                            <Typography
                                level="title-sm">
                                Showing once / version
                            </Typography>
                        }
                        <Button
                            sx={{
                                marginTop: 2,
                                marginRight: 2
                            }}
                            size='sm'
                            variant='outlined'
                            color='neutral'
                            onClick={() => setShowPreview(true)}>
                            Show preview
                        </Button>
                        {results && results?.length > 0 &&
                            <Button
                                size='sm'
                                color='primary'
                                variant='soft'
                                sx={{
                                    marginRight: 2
                                }}
                                onClick={() => setShowReviews(true)}>
                                See reviews
                            </Button>
                        }
                        <Button
                            sx={{
                                marginTop: 2
                            }}
                            size='sm'
                            variant='soft'
                            color='danger'
                            onClick={() => removeStage(stage)}>
                            Delete stage
                        </Button>
                        <Divider
                            sx={{
                                marginTop: 2,
                                marginBottom: 2
                            }}
                            orientation="horizontal" />

                        <Typography
                            level="title-sm">
                            Stage prompted
                            <span>
                                <Typography
                                    className='col'
                                    level="body-sm"
                                    sx={{
                                        marginBottom: 2
                                    }}>
                                    {" " + total} times.
                                </Typography>
                            </span>
                        </Typography>
                        {(cleanResults && cleanResults.length > 0) &&
                            <>
                                <Card
                                    sx={{
                                        marginTop: 2
                                    }}>
                                    <Typography
                                        color='neutral'
                                        fontWeight={600}
                                        level='body-xs'>
                                        {"Results distribution".toUpperCase()}
                                    </Typography>
                                    <Divider />
                                    <div className='row mt-4'>
                                        <div className='col'>
                                            <ReactApexChart
                                                options={completionOptions}
                                                series={[completed.length, notCompleted.length]}
                                                type="donut"
                                                height={200} />
                                        </div>
                                        <div className='col'>
                                            <ReactApexChart
                                                options={responseOptions}
                                                series={[yes.length, no.length]}
                                                type="donut"
                                                height={200} />
                                        </div>
                                    </div>
                                </Card>
                                <Card
                                    sx={{
                                        marginTop: 2
                                    }}>
                                    <Typography
                                        color='neutral'
                                        fontWeight={600}
                                        level='body-xs'>
                                        {"Interactions overtime".toUpperCase()}
                                    </Typography>
                                    <Divider />
                                    <ReactApexChart
                                        options={lineOptions}
                                        series={lineData.series}
                                        type="area"
                                        height={200} />
                                </Card>
                                <Card
                                    sx={{
                                        marginTop: 2
                                    }}>
                                    <Typography
                                        color='neutral'
                                        fontWeight={600}
                                        level='body-xs'>
                                        {"Sentiment overtime".toUpperCase()}
                                    </Typography>
                                    <Divider />
                                    <ReactApexChart
                                        options={barOptions}
                                        series={barData.series}
                                        type="bar"
                                        height={200} />
                                </Card>
                                <Card
                                    sx={{
                                        marginTop: 2
                                    }}>
                                    <Typography
                                        color='neutral'
                                        fontWeight={600}
                                        level='body-xs'>
                                        {"Options distribution".toUpperCase()}
                                    </Typography>
                                    <Divider />
                                    <ReactApexChart
                                        options={chartOptions}
                                        series={answerData.series}
                                        type="bar"
                                        height={200} />
                                </Card>
                            </>
                        }
                    </div>
            }
        </>
    );
}

export default StageComponent;