import React, { useState } from 'react';

import { Button, Chip, Dropdown, IconButton, Menu, MenuButton, MenuItem, Snackbar } from '@mui/joy';
import { Report, Share, CopyAll } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlack } from '@fortawesome/free-brands-svg-icons'
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons'

const networking = require('../../Networking/Wingman/wingmanAPI');

function ShareMenu({ app, content }: any) {
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [success, setSuccess] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);

    const shareToSlack = () => {
        networking.shareOnSlack(app.id, content).then((result: any) => {
            setShowSuccess(true);
            setSuccess('Shared to your slack channel.');
        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
        });
    }

    const shareToTeams = () => {
        networking.shareOnTeams(app.id, content).then((result: any) => {
            setShowSuccess(true);
            setSuccess('Shared to your teams channel.');
        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
        });
    }

    const copyToClipboard = () => {
        setShowSuccess(true);
        setSuccess('Copied to clipboard.');

        navigator.clipboard.writeText(content);
    }

    return (
        <>
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            {showSuccess &&
                <Snackbar
                    variant="soft"
                    color="success"
                    open={showSuccess}
                    onClose={() => setShowSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowSuccess(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {success}
                </Snackbar>
            }
            <Dropdown
                slotProps={{ listbox: { sx: { zIndex: 1 } } }}>
                <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{ root: { variant: 'plain', color: 'neutral' } }}
                    sx={{ borderRadius: 40 }}
                >
                    <Chip
                        variant="outlined"
                        color="neutral"
                        size="lg"
                        startDecorator={<Share
                            sx={{
                                width: '14px'
                            }} />}>
                        Share
                    </Chip>
                </MenuButton>
                <Menu>
                    {app.slack_webhook_url &&
                        <MenuItem
                            onClick={() => (shareToSlack())}>
                            <FontAwesomeIcon icon={faSlack} /> <span>Share on Slack</span>
                        </MenuItem>
                    }
                    {app.teams_webhook_url &&
                        <MenuItem
                            onClick={() => (shareToTeams())}>
                            <FontAwesomeIcon icon={faMicrosoft} /> <span>Share on Teams</span>
                        </MenuItem>
                    }
                    <MenuItem
                        onClick={() => (copyToClipboard())}>
                        <CopyAll sx={{ width: '14px' }}></CopyAll> <span>Copy to clipboard</span>
                    </MenuItem>
                </Menu>
            </Dropdown >
        </>
    )
}

export default ShareMenu;