import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import '../../styles/graphics.scss';

function RatingEvolutionGraph({ type, data, annotations }: any) {

    const options: ApexOptions = {
        chart: {
            type: type,
            zoom: { enabled: false },
            toolbar: { show: false },
            animations: { enabled: false }
        },
        annotations: { xaxis: annotations ?? [] },
        xaxis: {
            type: type === 'area' ? "category" : "numeric",
            labels: { format: "yyyy-MMM-dd" },
            axisBorder: { show: false }, // Remove axis border
            axisTicks: { show: false }
        },
        yaxis: {
            min: 1,
            max: 5,
            labels: {
                formatter: (value) => value.toFixed(0), // Round to 0 decimal places
            },
            axisBorder: { show: false }, // Remove axis border
            axisTicks: { show: false },
        },
        stroke: { curve: 'straight', width: 1 },
        tooltip: { x: { format: type === 'area' ? "datetime" : "string" } },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "90%", // Removes space between bars
            }
        },
        grid: {
            show: false
        },
    };

    return (
        <div>
            <ReactApexChart
                options={options}
                series={data.series}
                type={type}
                height={300} />
        </div >
    );
}

export default RatingEvolutionGraph;