import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { Button, List, ListItem, ListDivider, Sheet, Snackbar, Typography, Chip } from '@mui/joy';
import { DialogContent, DialogTitle, Modal, ModalClose, ModalDialog, ModalOverflow } from '@mui/joy';
import { Report } from '@mui/icons-material';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import PlanManagementComponent from './PlanManagementComponent';

import '../../styles/reviews.scss';
const networking = require('../../Networking/API');
const helper = require('../../Helpers/helpers');

function ClientPlanManagementComponent({ subscription, onSubscriptionUpdate }: any) {
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showPlanChooseModal, setShowPlanChooseModal] = useState(false);
    const [currentSubscription, setCurrentSubscription] = useState(subscription);

    const labelForPlan = (plan: string) => {
        switch (plan) {
            case 'U':
                return 'Enterprise'

            case 'AA':
                return 'Starter'

            case 'AAA':
                return 'PRO'

            case 'A':
                return 'Free'
        }
    }

    const updateSubscription = (event: any) => {
        event.preventDefault();
        setLoading(true);
        setShowError(false);

        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        formJson.id = subscription.id;
        formJson.plan = subscription.plan;
        formJson.profileimage = currentSubscription.profileimage;

        networking.updateSubscription(formJson).then((sub: any) => {
            getNewData()
        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    const getNewData = () => {
        setLoading(true);

        networking.authenticateSubscription(subscription.id).then((sub: any) => {
            setLoading(false);
            setCurrentSubscription(sub);
            setShowSuccess(true);

            onSubscriptionUpdate(sub)

        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    const openCustomerPortal = (event: any) => {
        setLoading(true);

        networking.customerPortal(subscription, location.pathname).then((sub: any) => {
            setLoading(false);

            if (sub.portal_url) {
                window.open(sub.portal_url, 'noopener,noreferer');
            }
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    return (
        <>
            {/* Plan Modal */}
            <Modal
                open={showPlanChooseModal}
                onClose={() => setShowPlanChooseModal(false)}>
                <ModalOverflow>
                    <ModalDialog
                        color="neutral"
                        layout='fullscreen'
                        size="sm"
                        variant="plain">
                        <ModalClose />
                        <DialogTitle>
                            Plans & Billing
                        </DialogTitle>
                        <DialogContent>
                            Manage your billing and plan settings.
                        </DialogContent>
                        <div className='pt-4'>
                            <PlanManagementComponent
                                subscription={subscription}
                                onSubscriptionUpdate={updateSubscription}
                            />
                        </div>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            {showSuccess &&
                <Snackbar
                    variant="soft"
                    color="success"
                    open={showSuccess}
                    onClose={() => setShowSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowSuccess(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    Profile updated! 🚀
                </Snackbar>
            }
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div className='row gx-2'>
                    <Sheet
                        variant='outlined'
                        sx={{
                            p: 1,
                            borderRadius: 8
                        }}>
                        <div className='p-3'>
                            <div className='row'>
                                <div className='col'>
                                    <Typography
                                        level="title-sm">
                                        {labelForPlan(currentSubscription.plan)}
                                    </Typography>
                                </div>
                                {currentSubscription.plan !== 'U' &&
                                    <div className='col-auto'>
                                        <Button
                                            className=' my-auto'
                                            sx={{ mt: 6 /* margin top */ }}
                                            onClick={() => setShowPlanChooseModal(true)}>
                                            Change plan
                                        </Button>
                                    </div>
                                }
                            </div>
                            <ListDivider
                                sx={{
                                    mt: 1,
                                    mb: 1
                                }} />
                            <div className='mt-3'>
                                <Typography
                                    level="body-xs">
                                    Renews
                                </Typography>
                                <Typography
                                    level="body-xs"
                                    fontWeight="lg">
                                    {helper.formatFriendlyDate(currentSubscription.expirationdate, navigator.language)}
                                </Typography>
                            </div>
                        </div>
                    </Sheet>
                    {currentSubscription.plan === 'U' &&
                        <Sheet
                            variant='outlined'
                            sx={{
                                p: 1,
                                borderRadius: 8,
                                mt: 2
                            }}>
                            <div className='p-3'>
                                <Typography
                                    level="title-sm">
                                    Currently subscribed
                                </Typography>
                                <ListDivider
                                    sx={{
                                        mt: 1,
                                        mb: 1
                                    }} />
                                <List aria-labelledby="decorated-list-demo">
                                    <ListItem>
                                        <Chip color='warning'>Proactive feedback</Chip>
                                        <Chip color='warning'>Dedicated support</Chip>
                                    </ListItem>
                                </List>
                            </div>
                        </Sheet>
                    }
                    {(currentSubscription.plan !== 'A' && currentSubscription.plan !== 'U') &&
                        <Sheet
                            variant='outlined'
                            sx={{
                                p: 1,
                                borderRadius: 8,
                                mt: 2
                            }}>
                            <div className='p-3'>
                                <Typography
                                    level="title-sm">
                                    Manage my subscription
                                </Typography>
                                <Typography
                                    level="body-sm">
                                    You will be redirected to our payments partner page, where you can manage your subscription,
                                    payment details and download invoices.
                                </Typography>
                                <ListDivider
                                    sx={{
                                        mt: 1,
                                        mb: 1
                                    }} />
                                <Button
                                    className=' my-auto'
                                    sx={{ mt: 2 /* margin top */ }}
                                    onClick={(e) => openCustomerPortal(e)}>
                                    Manage
                                </Button>

                            </div>
                        </Sheet>
                    }
                </div >
            }
        </>
    );
}

export default ClientPlanManagementComponent;