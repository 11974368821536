import React, { useState } from 'react';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import { Alert } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import '../../styles/alert.scss';

const networking = require('../../Networking/API');

function NotificationAlertComponent(app: any, style: any) {
    const [loading, setLoading] = useState(true);
    const [reviews, setReviews] = useState(null) as any[];
    const [status, setStatus] = useState('');

    var icon = status === 'severe' ? faExclamationCircle : faCheckCircle;
    var title = status === 'severe' ? 'ATTENTION' : 'LOOKING GOOD';
    var message = status === 'severe' ? 'There are some keywords that represent more than 15% of all of your reviews. This requires your attention!' :
        'Nothing to see here. Just relax.';

    var a: any[] = [];
    var count = [];

    if (!reviews) {
        getData();
    } else {
        getIssues();
    }

    function getData() {
        networking.reviewsFor(app.app.id).then((reviews: any) => {
            setReviews(reviews);
        }).catch((error: Error) => {
            setLoading(false);
        });
    }

    function getIssues() {
        networking.issuesFor(app.app.id).then((issues: any) => {
            prepareAlert(issues);
            setLoading(false);
        }).catch((error: Error) => {
            setLoading(false);
        });
    }

    function prepareAlert(issues: any[]) {
        const revs = reviews;
        issues.map((issue: any) =>
            a.push({ 'r': revs.filter((r: any) => issue.keywords.some((k: string) => r.content.toLowerCase().includes(k.toLocaleLowerCase()))) })
        );

        a.forEach((element: any) => {
            const p = element.r.length / reviews.length;

            if (p > 0.15 && element.r.length > 10) {
                count.push(p);
            }
        });

        if (count.length) {
            setStatus('severe');
        } else {
            setStatus('good');
        }
    }

    return (
        <Alert
            variant='soft'
            color={status === 'severe' ? 'danger' : 'success'}
            sx={{
                height: '100%',
            }}>

            {loading ?
                <LoadingSpinnerComponent />
                :
                <div className='my-auto mx-auto'>
                    <div className='text-center'>
                        <FontAwesomeIcon
                            icon={icon}
                            size='3x' />
                    </div>
                    <div>
                        <div className='text-center mt-3'>
                            {title}
                        </div>
                        <div className='text-center mt-3'>
                            {message}
                        </div>
                    </div>
                </div>
            }
        </Alert>
    );
}

export default NotificationAlertComponent;