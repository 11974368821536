import { useState, useEffect } from "react";
import { Modal, ModalDialog, Typography, Button, Box, Divider } from "@mui/joy";
import { UpdateItem } from "../../Interfaces/UpdateItem";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import whats_new_1 from '../../resources/4_1_1.png'
import whats_new_2 from '../../resources/4_1_2.png'
import whats_new_3 from '../../resources/4_1_3.png'

const WHATS_NEW_VERSION = "4.1.0"; // Update this with each new release

const updates: UpdateItem[] = [
    {
        image: whats_new_1,
        title: "💡 Spot Trends Instantly",
        subtitle: "Appinion now detects trending words in your reviews, helping you uncover emerging topics effortlessly.",
    },
    {
        image: whats_new_2,
        title: "🎯 Find What Matters Faster",
        subtitle: "We've expanded filtering options across all major features, making it easier to drill down into the insights that matter most.",
    },
    {
        image: whats_new_3,
        title: "🔍 Deeper Insights at a Glance",
        subtitle: "Each review now includes richer metadata, giving you more context and clarity in your analysis.",
    }
]

export default function WhatsNewModal() {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const lastVersion = localStorage.getItem("lastVersion");

        if (lastVersion !== WHATS_NEW_VERSION) {
            setOpen(true);
            localStorage.setItem("lastVersion", WHATS_NEW_VERSION);
        }
    }, []);

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}>

            <ModalDialog
                maxWidth={800}>
                <Typography
                    level="body-md"
                    fontWeight={700}>
                    {"Whats New in version " + WHATS_NEW_VERSION}
                </Typography>
                <Divider />
                <Slider
                    dots
                    infinite
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    adaptiveHeight
                    arrows={false}>
                    {updates.map((update, index) => (
                        <Box
                            key={index}>
                            <div>
                                <img
                                    className="mx-auto"
                                    src={update.image}
                                    alt={update.title}
                                    style={{
                                        width: "90%",
                                        borderRadius: "8px",
                                    }} />
                            </div>
                            <div className="text-center">
                                <Typography
                                    level="title-md"
                                    sx={{
                                        mt: 2,
                                        mb: 2,
                                        px: 8
                                    }}>
                                    {update.title}
                                </Typography>
                                <Typography
                                    level="body-md"
                                    sx={{
                                        mb: 2,
                                        px: 8
                                    }}>
                                    {update.subtitle}
                                </Typography>
                            </div>
                        </Box>
                    ))}
                </Slider>
                <Button
                    sx={{
                        marginTop: 4
                    }}
                    onClick={() => setOpen(false)}>
                    Let's go!
                </Button>
            </ModalDialog>
        </Modal >
    );
}