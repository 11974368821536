import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Card, Typography } from '@mui/joy';
import { Android, Apple, ChevronRight } from '@mui/icons-material';

import '../styles/app-card.scss';
const helper = require('../Helpers/helpers');

function AppCard(app: any, subscription: any) {

    const stores = () => {
        if (app.app.androidpackageid && app.app.appleappid) {
            return <span className="mr-3">
                {helper.iconFor('applestore')}
                <span> + </span>
                {helper.iconFor('playstore')}
            </span>
        } else if (app.app.androidpackageId && !app.app.appleappid) {
            return <span className="mr-3">
                {helper.iconFor('playstore')}
            </span>
        } else if (!app.app.androidpackageid && app.app.appleappid) {
            return <span className="mr-3">
                {helper.iconFor('applestore')}
            </span>
        }
    }

    return (
        <div>
            <Link
                to={'/app/' + app.app.id}
                className='no-decoration'
                onClick={() => localStorage.setItem("currentApp", JSON.stringify(app.app))}>
                <Card
                    variant='plain'
                    sx={{
                        borderColor: '#f5f5f5',
                        borderWidth: '1px',
                        borderStyle: 'solid'
                    }}>
                    <div className='row'>
                        <div
                            className='col-auto my-auto '>
                            <Avatar
                                alt={app.app.name + 'App icon'}
                                src={app.app.icon}
                                size='lg'>
                                {helper.getInitials(app.app.name)}
                            </Avatar>
                        </div>
                        <div
                            className='col-auto my-auto'>
                            <Typography
                                level='title-sm'>
                                {app.app.name}
                            </Typography>
                            <Typography
                                level='body-xs'>
                                {app.app.country} | {stores()}
                            </Typography>
                        </div>
                        <div className='col my-auto text-end'>
                            <ChevronRight />
                        </div>
                    </div>
                </Card>
            </Link>
        </div >

    );
}

export default AppCard;