import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

function RatingDistributionGraph(data: any) {

    const options: ApexOptions = {
        colors: ["#FEF3C7", "#FDE68A", "#FACC15", "#E3A008", "#C27803"],
        states: {
            hover: {
                filter: {
                    type: 'none', // Options: darken, lighten, none
                }
            }
        },
        chart: {
            type: "pie",
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 300,
            },
        },
        fill: {
            type: 'solid',
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#ffffff'],
        },
        labels: ["1 Star", "2 Stars", "3 Stars", "4 Stars", "5 Stars"], // Rating categories
        tooltip: {
            y: {
                formatter: (val: number) => `${val} reviews`,
            },
        },
        legend: {
            position: 'bottom',
            labels: {
                colors: '#333',
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: "60%", // Make it a donut chart (if desired)
                }
            },
        },
        dataLabels: {
            enabled: true, // Show labels inside the pie chart
        }
    };

    return (
        <div>
            <ReactApexChart
                options={options}
                series={data.data}
                type="pie"
                height={350} />
        </div >
    );
}

export default RatingDistributionGraph;