import { host, authorize } from '../API';

export const getReviewsInsights = async function (app: string, count: number) {
    const endpoint = host + "wingman/reviews/" + app + "?lang=" + navigator.language + '&limit=' + count;

    const response = await fetch(
        endpoint,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Basic' + authorize()
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const results = await response.json();

    return results;
}

export const getRatingInsights = async function (app: string, data: any) {
    const endpoint = host + "wingman/rating/" + app + "?lang=" + navigator.language;

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'ratingOverTime': data[0],
                'ratingOverVersion': data[1],
                'ratingDistribution': data[2]
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const results = await response.json();

    return results;
}

export const getSentimentInsights = async function (app: string, data: any) {
    const endpoint = host + "wingman/sentiment/" + app + "?lang=" + navigator.language;

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'sentimentOverTime': data[0],
                'sentimentOverVersion': data[1]
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const results = await response.json();

    return results;
}

export const getKeywordsInsights = async function (app: string, count: number) {
    const endpoint = host + "wingman/keywords/" + app + "?lang=" + navigator.language + '&limit=' + count;

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const results = await response.json();

    return results;
}

export const getResponseForReview = async function (app: any, review: string) {
    const endpoint = host + "wingman/response/" + app.id + "?lang=" + app.countrycode;

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                'review': review
            })
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const results = await response.json();

    return results;
}

export const generatePersona = async function (app: string) {
    const endpoint = host + "wingman/persona/" + app + "?lang=" + navigator.language;

    const response = await fetch(
        endpoint,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Basic' + authorize()
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const results = await response.json();

    return results;
}

export const generateRoadmap = async function (app: string) {
    const endpoint = host + "wingman/roadmap/" + app + "?lang=" + navigator.language;

    const response = await fetch(
        endpoint,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Basic' + authorize()
            }
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    const results = await response.json();

    return results;
}

export const shareOnSlack = async function (app: string, text: string) {
    const endpoint = host + "connections/slack/" + app;

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify(
                {
                    message: text
                }
            )
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    return response.json;
}

export const shareOnTeams = async function (app: string, text: string) {
    const endpoint = host + "connections/teams/" + app;

    const response = await fetch(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Basic' + authorize(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify(
                {
                    message: text
                }
            )
        });

    if (!response.ok) {
        const message = 'Something went wrong here. Code: ' + response.status;
        throw new Error(message);
    }

    return response.json;
}