import React, { useState, useEffect, useRef } from 'react';
import csvDownload from 'json-to-csv-export'

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import ReviewComponent from './ReviewComponent';
import ReplyPopUpComponent from './ReplyPopUpComponent';
import EmptyMessageComponent from '../EmptyMessageComponent';

import { Select, Option, Stack, Typography, Input, Button, Modal, ModalOverflow, ModalDialog, ModalClose, DialogTitle, DialogContent, Snackbar, Card, Alert, FormLabel, Box, Avatar, Divider } from '@mui/joy';
import { Search, Star, Report } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStore, faMessage } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons'

import '../../styles/dropdowns.scss';

import DateFilter, { DateFilterValue } from '../Filters/DateFilter';
import { getDateRange } from '../../Helpers/DateHelpers';

import PaginationComponent from '../PaginationComponent';
import WingmanSummary from '../Wingman/WingmanSummary';
import Rating from "@mui/material/Rating"

const networking = require('../../Networking/API');
const helper = require('../../Helpers/helpers');

function ReviewsComponent({ subscription, app }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];
    const [reviewToReply, setReviewToReply] = useState<Review>();
    const [openReply, setOpenReply] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [textFilter, setTextFilter] = useState('');
    const [expanded, setExpanded] = React.useState(false);

    //Filter states
    const [currentPage, setCurrentPage] = useState(1);
    const [currentStore, setCurrentStore] = useState('');
    const [currentSentiment, setCurrentSentiment] = useState('All');
    const [currentRating, setCurrentRating] = useState<number>(5);
    const [currentVersion, setCurrentVersion] = useState('All');
    const [selectedFilter, setSelectedFilter] = useState<DateFilterValue>("last7");

    const itemsPerPage = 20;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFisrtItem = indexOfLastItem - itemsPerPage;
    const { start, end } = getDateRange(selectedFilter);
    var versions: any[] = [];

    const hasFetched = useRef(false);

    useEffect(() => {
        if (!hasFetched.current) {
            getData();
            hasFetched.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setExpanded(false);
    }, [currentStore, currentSentiment, currentRating, currentVersion, textFilter, selectedFilter]);

    function getData() {
        networking.reviewsFor(app.id).then((reviews: any) => {
            setReviews(reviews);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function paginate(page: number) {
        setCurrentPage(page)
    }

    function filterByStore(e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        setCurrentStore(value)
    }

    function filterBySentiment(e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        setCurrentSentiment(value)
    }

    function filterByVersion(e: any) {
        e.preventDefault();

        const value = e.target.innerText;

        setCurrentVersion(value)
    }

    function exportToCSV() {
        const name = "feedback_" + new Date().toLocaleDateString();

        const data = {
            data: currentItems,
            filename: name + ".csv"
        }

        csvDownload(data)
    }

    const reviewTapped = (review: Review) => {
        setReviewToReply(review);
        setOpenReply(true);
    }

    const replySent = (event: any) => {
        setOpenReply(false);
        setShowSuccess(true);
        getData();
    };

    let list = null;

    if (reviews) {
        reviews.sort(function (a: any, b: any) {
            return new Date(b.updated).getTime() - new Date(a.updated).getTime();
        });

        versions = [...new Set(reviews.map((r: any) => r.version))];
        versions.sort(function (a: any, b: any) {
            if (a !== null) {
                return a.localeCompare(b, undefined, { numeric: true });
            }

            return [];

        });
        versions.unshift("All");

        var currentItems = reviews;

        if (currentStore === 'Appstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'applestore');

        } else if (currentStore === 'Playstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'playstore');

        } else if (currentStore === 'Proactive feedback') {
            currentItems = currentItems.filter((r: any) => r.store === 'profeed');
        }

        currentItems = currentItems.filter((r: Review) => Number(r.rating) <= currentRating);

        if (currentSentiment !== 'All') {
            currentItems = currentItems.filter((r: Review) => r.sentiment.toLowerCase() === currentSentiment.toLowerCase());
        }

        if (currentVersion !== 'All') {
            currentItems = currentItems.filter((r: any) => r.version === currentVersion);
        }

        if (textFilter.length !== 0) {
            currentItems = currentItems.filter((r: any) => r.content.toLowerCase().includes(textFilter.toLocaleLowerCase()))
        }

        //Apply Date filters
        currentItems = currentItems.filter((review: Review) => {
            const reviewDate = new Date(review.updated);
            return (!start || reviewDate >= start) && (!end || reviewDate <= end);
        });

        var parsedReviews = currentItems.slice(indexOfFisrtItem, indexOfLastItem);

        list = parsedReviews.map((review: Review, index: number) =>
            <div
                className='mb-3'
                key={index}>
                <ReviewComponent
                    subscription={subscription}
                    app={app}
                    review={review}
                    tokens={[]}
                    canReply={review.store === 'applestore' ? app.appstore_connect : review.store === 'playstore' ? app.playstore_connect : false}
                    handler={reviewTapped} />
            </div>
        )
    }

    return (
        <div>
            {showSuccess &&
                <Snackbar
                    variant="soft"
                    color="success"
                    open={showSuccess}
                    onClose={() => setShowSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowSuccess(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    Reply sent. 🚀
                </Snackbar>
            }
            {/* Review reply Modal */}
            <Modal
                open={openReply}
                onClose={() => setOpenReply(false)}>
                <ModalOverflow>
                    <ModalDialog
                        color="neutral"
                        layout="center"
                        size="md"
                        variant="plain"
                        sx={{
                            maxWidth: 800
                        }}>
                        <ModalClose />
                        <DialogTitle>
                            Reply to this review
                        </DialogTitle>
                        <DialogContent>
                            Choose the tone according to the review you are replying.
                        </DialogContent>
                        <Divider />
                        <>
                            <ReplyPopUpComponent
                                subscription={subscription}
                                app={app}
                                review={reviewToReply}
                                handler={replySent} />
                        </>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
            <div>
                {error && <div> <AlertComponent alert={error} /> </div>}
                {loading ?
                    <LoadingSpinnerComponent />
                    :
                    <div className='col-lg'>
                        {/* Filters and Actions */}
                        <Box
                            component='section'
                            color='primary'
                            sx={{
                                marginBottom: 2,
                                backgroundColor: '#f4f4f4',
                                borderRadius: '0.5rem',
                                padding: '1rem',
                            }}>
                            <div className='row'>
                                <div className='col-auto mt-auto'>
                                    <div className='row'>
                                        <div
                                            className='col-auto my-auto '>
                                            <Avatar
                                                alt={app.name + 'App icon'}
                                                src={app.icon}
                                                size='lg'>
                                                {helper.getInitials(app.name)}
                                            </Avatar>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <FormLabel
                                        sx={{
                                            fontSize: '0.75rem',
                                            marginBottom: 0.25
                                        }}>
                                        Content
                                    </FormLabel>
                                    <div className='col'>
                                        <Input
                                            type="text"
                                            size='sm'
                                            placeholder="Type in here…"
                                            startDecorator={<Search />}
                                            onChange={(event) => setTextFilter(event.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-auto my-auto'>
                                    <FormLabel
                                        sx={{
                                            fontSize: '0.75rem',
                                            marginBottom: 0.25
                                        }}>
                                        Source
                                    </FormLabel>
                                    <Select
                                        color="neutral"
                                        defaultValue={'all'}
                                        size="sm"
                                        variant="outlined"
                                        onChange={filterByStore}>
                                        <Option value="all">
                                            <span className="me-2"><FontAwesomeIcon icon={faStore} /></span>
                                            All
                                        </Option>
                                        <Option value="apple">
                                            <span className="me-2"><FontAwesomeIcon icon={faAppStore} />
                                            </span> Appstore
                                        </Option>
                                        <Option value="google"><span className="me-2">
                                            <FontAwesomeIcon icon={faGooglePlay} /></span> Playstore
                                        </Option>
                                        <Option value="proactive">
                                            <span className="me-2"><FontAwesomeIcon icon={faMessage} /></span>
                                            Proactive feedback
                                        </Option>
                                    </Select>
                                </div>
                                <div className='col-auto my-auto'>
                                    <FormLabel
                                        sx={{
                                            fontSize: '0.75rem',
                                            marginBottom: 0.25
                                        }}>
                                        Sentiment
                                    </FormLabel>
                                    <Select
                                        color="neutral"
                                        defaultValue={'all'}
                                        size="sm"
                                        variant="outlined"
                                        onChange={filterBySentiment}>
                                        <Option value="all">
                                            All
                                        </Option>
                                        <Option value="good">
                                            Good
                                        </Option>
                                        <Option value="neutral">
                                            Neutral
                                        </Option>
                                        <Option value="bad">
                                            Bad
                                        </Option>
                                    </Select>
                                </div>
                                <div className='col-auto my-auto'>
                                    <FormLabel
                                        sx={{
                                            fontSize: '0.75rem',
                                            marginBottom: 0.25
                                        }}>
                                        Version
                                    </FormLabel>
                                    <Select
                                        color="neutral"
                                        placeholder="All versions"
                                        size="sm"
                                        variant="outlined"
                                        onChange={filterByVersion}>
                                        {
                                            versions.reverse().map((version: string) => {
                                                return (
                                                    <Option value={version}>
                                                        {version}
                                                    </Option>
                                                );
                                            })
                                        }
                                    </Select>
                                </div>
                                <div className='col-auto my-auto'>
                                    <FormLabel
                                        sx={{
                                            fontSize: '0.75rem',
                                            marginBottom: 0.25
                                        }}>
                                        Rating
                                    </FormLabel>
                                    <Select
                                        value={currentRating || 5}
                                        color="neutral"
                                        size="sm"
                                        variant="outlined"
                                        placeholder="Filter by rating"
                                        onChange={(_, newValue) => setCurrentRating(newValue ?? 5)}
                                        renderValue={(selected) =>
                                            selected ? (
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Rating
                                                        value={Number(selected.value)}
                                                        readOnly size="small"
                                                        emptyIcon={<Star
                                                            style={{ opacity: 0.55 }}
                                                            fontSize="inherit" />} />
                                                    <Typography level='body-xs'>
                                                        {selected.value} Stars
                                                    </Typography>
                                                </Stack>
                                            ) : (
                                                "Filter by rating"
                                            )
                                        }
                                    >
                                        {[5, 4, 3, 2, 1].map((rating) => (
                                            <Option
                                                key={rating}
                                                value={rating}>
                                                <Stack direction="row"
                                                    spacing={1}
                                                    alignItems="center">
                                                    <Rating
                                                        value={rating}
                                                        readOnly size="small"
                                                        emptyIcon={<Star
                                                            style={{ opacity: 0.55 }}
                                                            fontSize="inherit" />} />
                                                    <Typography level='body-xs'>
                                                        {rating} Stars
                                                    </Typography>
                                                </Stack>
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className='col-auto my-auto'>
                                    <FormLabel
                                        sx={{
                                            fontSize: '0.75rem',
                                            marginBottom: 0.25
                                        }}>
                                        Date range
                                    </FormLabel>
                                    <DateFilter
                                        selectedFilter={selectedFilter}
                                        onChange={setSelectedFilter} />
                                </div>
                            </div>
                        </Box>
                        {app && subscription && (subscription.plan === 'AAA' || subscription.plan === 'U') &&
                            <WingmanSummary
                                expanded={expanded}
                                setExpanded={setExpanded}
                                app={app}
                                count={currentItems && currentItems.length}
                            />
                        }
                        {app && subscription && (subscription.plan === 'A' || subscription.plan === 'AA') &&
                            <Alert
                                className="mb-3"
                                size="lg"
                                variant="soft"
                                color="primary">
                                <div>
                                    <div>The wingman</div>
                                    <Typography
                                        level="body-sm"
                                    >
                                        Your plan does not include the wingman feature. To enable it, please upgrade your plan.
                                    </Typography>
                                </div>
                            </Alert>
                        }
                        {/* Reviews */}
                        <Card
                            sx={{
                                backgroundColor: 'white'
                            }}>
                            {parsedReviews && parsedReviews.length === 0 &&
                                <EmptyMessageComponent
                                    title={'No reviews found.'}
                                    description={'We\'ve not found any reviews. Please try another search criteria.'} />
                            }
                            {parsedReviews && parsedReviews.length !== 0 &&
                                <div className='row'>
                                    <div className='col-auto my-auto'>
                                        <Typography
                                            level='body-sm'
                                            fontWeight={600}>
                                            {currentItems.length} reviews found
                                        </Typography>
                                    </div>
                                    <div className='col'>
                                        <div className='text-end'>
                                            <Button
                                                variant="soft"
                                                size='sm'
                                                color="neutral"
                                                onClick={() => exportToCSV()}>
                                                Export to csv
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            }
                            <Divider />
                            {list}
                            <PaginationComponent
                                itemsPerPage={itemsPerPage}
                                totalItems={currentItems.length}
                                paginate={paginate}
                                currentPage={currentPage} />
                        </Card>
                    </div>
                }
            </div>
        </div >
    )
}

export default ReviewsComponent;