//Data treatment to ApexCharts
export function getRatingOverTime(data: Review[]) {
    const ratingsByDate: Record<string, { sum: number; count: number }> = {};

    data
        .slice(0, 50)
        .filter(({ rating }) => rating > 0) // Exclude reviews with rating 0
        .forEach(({ updated, rating }) => {
            const date = updated.split("T")[0]; // Extract YYYY-MM-DD
            if (!ratingsByDate[date]) {
                ratingsByDate[date] = {
                    sum: 0,
                    count: 0
                };
            }

            ratingsByDate[date].sum += Number(rating);
            ratingsByDate[date].count += 1;
        });

    const seriesData = Object.entries(ratingsByDate)
        .map(([date, { sum, count }]) => ({
            x: date,
            y: sum / count, // Calculate average rating
        }))
        .sort((a, b) => b.x.localeCompare(a.x))

    return {
        series: [{ name: "", data: seriesData }],
    };
}

export function getRatingOverVersion(data: Review[]) {
    const ratingsByVersion: Record<string, { sum: number; count: number }> = {};

    data
        .slice(0, 50)
        .filter(({ rating }) => rating > 0) // Exclude reviews with rating 0
        .forEach(({ version, rating }) => {
            if (!ratingsByVersion[version]) {
                ratingsByVersion[version] = {
                    sum: 0,
                    count: 0
                };
            }

            ratingsByVersion[version].sum += Number(rating);
            ratingsByVersion[version].count += 1;
        });

    const seriesData = Object.entries(ratingsByVersion)
        .map(([version, { sum, count }]) => ({
            x: version,
            y: sum / count, // Calculate average rating
        }))
        .sort((a, b) => b.x.localeCompare(a.x))

    return {
        series: [{ name: "", data: seriesData }],
    };
}

export function getRatingDistribution(data: Review[]) {
    const distribution = [0, 0, 0, 0, 0]; // For ratings 1, 2, 3, 4, 5

    data.forEach((review) => {
        if (review.rating >= 1 && review.rating <= 5) {
            distribution[review.rating - 1] += 1; // Increment the count for each rating
        }
    });

    return distribution;
}

export function getSentimentEvolutionOverTime(reviews: Review[]) {
    const sentimentByDate: Record<string, Record<string, number>> = {};

    reviews
        .slice(0, 50)
        .filter(({ content }) => content.length !== 0)
        .forEach(({ updated, sentiment }) => {
            const date = updated.split("T")[0]; // Extract YYYY-MM-DD
            if (!sentimentByDate[date]) {
                sentimentByDate[date] = { good: 0, bad: 0, neutral: 0 };
            }
            sentimentByDate[date][sentiment] = (sentimentByDate[date][sentiment] || 0) + 1;
        });

    const seriesData = Object.entries(sentimentByDate).map(([date, sentimentCounts]) => ({
        x: date,
        y: [
            sentimentCounts["good"],
            sentimentCounts["bad"],
            sentimentCounts["neutral"],
        ],
    }))
        .sort((a, b) => b.x.localeCompare(a.x));

    return { seriesData };
}

export function getSentimentEvolutionOverVersion(reviews: Review[]) {
    const sentimentByVersion: Record<string, Record<string, number>> = {};

    reviews
        .slice(0, 50)
        .filter(({ content }) => content.length !== 0)
        .forEach(({ version, sentiment }) => {
            if (!sentimentByVersion[version]) {
                sentimentByVersion[version] = { good: 0, bad: 0, neutral: 0 };
            }
            sentimentByVersion[version][sentiment] = (sentimentByVersion[version][sentiment] || 0) + 1;
        });

    const seriesData = Object.entries(sentimentByVersion).map(([version, sentimentCounts]) => ({
        x: version,
        y: [
            sentimentCounts["good"],
            sentimentCounts["bad"],
            sentimentCounts["neutral"],
        ],
    }))
        .sort((a, b) => b.x.localeCompare(a.x));

    return { seriesData };
}

export function aggregateReviewsByDate(reviews: Review[]) {
    const reviewCounts: Record<string, number> = {};

    reviews
        .slice(0, 50)
        .filter(({ content }) => content.length !== 0)
        .forEach((review) => {
            const date = new Date(review.updated).toISOString().split("T")[0]; // Extract YYYY-MM-DD
            reviewCounts[date] = (reviewCounts[date] || 0) + 1;
        });

    const seriesData = Object.entries(reviewCounts).map(([date, count]) => ({
        x: date,
        y: count,
    }))
        .sort((a, b) => b.x.localeCompare(a.x));

    return { seriesData };
};

export function getVersionAnnotations(reviews: Review[]) {
    // Step 1: Find the earliest review date for each version
    const versionMap = new Map<string, string>();

    reviews.forEach((review) => {
        const { version, updated } = review;
        if (!versionMap.has(version) || new Date(updated) < new Date(versionMap.get(version)!)) {
            versionMap.set(version, updated);
        }
    });

    // Step 2: Convert the map into a sorted array of annotations
    return Array.from(versionMap.entries())
        .map(([version, releaseDate]) => ({
            x: new Date(releaseDate).toISOString().split("T")[0], // Convert date to timestamp
            borderColor: "#c9c9c9",
            strokeDashArray: 4,
            label: {
                text: `${version}`,
                style: {
                    background: "#f9f9f9",
                    color: "#c1c1c1",
                    fontSize: "12px",
                },
            },
        }))
        .sort((a: any, b: any) => a.x - b.x); // Sort by time
}

//Data treatment to Wingman
export function getRatingOverTimeWingman(data: Review[]) {
    const ratingsByDate: Record<string, { sum: number; count: number, version: string }> = {};

    data
        .slice(0, 50)
        .filter(({ rating }) => rating > 0) // Exclude reviews with rating 0
        .forEach(({ updated, rating, version }) => {
            const date = updated.split("T")[0]; // Extract YYYY-MM-DD
            if (!ratingsByDate[date]) {
                ratingsByDate[date] = {
                    sum: 0,
                    count: 0,
                    version: version
                };
            }

            ratingsByDate[date].sum += Number(rating);
            ratingsByDate[date].count += 1;
        });

    const seriesData = Object.entries(ratingsByDate)
        .map(([date, { sum, count, version }]) => ({
            date: date,
            average: sum / count, // Calculate average rating
            version: version,
            count: count
        }))
        .sort((a, b) => b.date.localeCompare(a.date))

    return {
        series: { data: seriesData },
    };
}

export function getRatingOverVersionWingman(data: Review[]) {
    const ratingsByVersion: Record<string, { sum: number; count: number }> = {};

    data
        .slice(0, 50)
        .filter(({ rating }) => rating > 0) // Exclude reviews with rating 0
        .forEach(({ version, rating }) => {
            if (!ratingsByVersion[version]) {
                ratingsByVersion[version] = {
                    sum: 0,
                    count: 0
                };
            }

            ratingsByVersion[version].sum += Number(rating);
            ratingsByVersion[version].count += 1;
        });

    const seriesData = Object.entries(ratingsByVersion)
        .map(([version, { sum, count }]) => ({
            date: version,
            average: sum / count,
            count: count
        }))
        .sort((a, b) => b.date.localeCompare(a.date))

    return {
        series: { data: seriesData },
    };
}

export function getRatingDistributionWingman(reviews: Review[]) {
    const ratingDistribution: Record<
        number,
        { rating: number, count: number; percentage: string; mostCommonVersion: string | null; versions: string[] }
    > = {};

    // Count occurrences and collect versions per rating
    for (const review of reviews) {
        if (review.rating >= 1 && review.rating <= 5) {
            if (!ratingDistribution[review.rating]) {
                ratingDistribution[review.rating] = { rating: review.rating, count: 0, percentage: "0%", mostCommonVersion: null, versions: [] };
            }

            ratingDistribution[review.rating].count += 1;
            ratingDistribution[review.rating].versions.push(review.version);
        }
    }

    // Compute percentages and find most common version
    const totalReviews = reviews.length;
    const result: Record<number, { rating: number, count: number; percentage: string; mostCommonVersion: string | null }> = {};

    for (const rating in ratingDistribution) {
        const entry = ratingDistribution[Number(rating)];
        result[Number(rating)] = {
            rating: entry.rating,
            count: entry.count,
            percentage: ((entry.count / totalReviews) * 100).toFixed(2) + "%",
            mostCommonVersion: mostCommonVersion(entry.versions),
        };
    }

    return result;
}

export function getSentimentEvolutionOverVersionWingman(reviews: Review[]) {
    const sentimentByVersion: Record<string, Record<string, number>> = {};

    reviews
        .slice(0, 50)
        .filter(({ content }) => content.length !== 0)
        .forEach(({ version, sentiment }) => {
            if (!sentimentByVersion[version]) {
                sentimentByVersion[version] = { good: 0, bad: 0, neutral: 0 };
            }
            sentimentByVersion[version][sentiment] = (sentimentByVersion[version][sentiment] || 0) + 1;
        });

    const seriesData = Object.entries(sentimentByVersion).map(([version, sentimentCounts]) => ({
        version: version,
        distribution: [
            { "positive": sentimentCounts["good"] },
            { "negative": sentimentCounts["bad"] },
            { "neutral": sentimentCounts["neutral"] },
        ],
    }));

    return { seriesData };
}

export function getSentimentEvolutionOverTimeWingman(reviews: Review[]) {
    const sentimentByDate: Record<string, Record<string, number>> = {};

    reviews
        .slice(0, 50)
        .filter(({ content }) => content.length !== 0)
        .forEach(({ updated, sentiment }) => {
            const date = updated.split("T")[0]; // Extract YYYY-MM-DD
            if (!sentimentByDate[date]) {
                sentimentByDate[date] = { good: 0, bad: 0, neutral: 0 };
            }
            sentimentByDate[date][sentiment] = (sentimentByDate[date][sentiment] || 0) + 1;
        });

    const seriesData = Object.entries(sentimentByDate).map(([date, sentimentCounts]) => ({
        date: date,
        distribution: [
            { "positive": sentimentCounts["good"] },
            { "negative": sentimentCounts["bad"] },
            { "neutral": sentimentCounts["neutral"] },
            4],
    }));

    return { seriesData };
}

function mostCommonVersion(versions: string[]): string | null {
    if (versions.length === 0) return null;

    const frequencyMap = new Map<string, number>();

    // Count occurrences
    for (const version of versions) {
        frequencyMap.set(version, (frequencyMap.get(version) || 0) + 1);
    }

    // Find the most common version
    let mostCommon = null;
    let maxCount = 0;

    for (const [version, count] of frequencyMap.entries()) {
        if (count > maxCount) {
            mostCommon = version;
            maxCount = count;
        }
    }

    return mostCommon;
}

//Keyword counting
export const countKeywords = (reviews: Review[]) => {
    const keywordCount: Record<string, number> = {};

    reviews.forEach((review) => {
        review.tokens.forEach((text: string) => {
            keywordCount[text] = (keywordCount[text] || 0) + 1;
        });
    });

    // Convert object to array and sort by count (descending)
    return Object.entries(keywordCount)
        .filter(([_, count]) => count > 1)
        .sort((a, b) => b[1] - a[1])
        .map(([text, value]) => ({ text, value }));
};