import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import '../../styles/sentiment-gauge.scss';

import { Typography } from '@mui/joy';

const networking = require('../../Networking/API');

function SentimentGaugeComponent(app: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];

    var rotation = 0;

    if (!reviews) {
        getData();
    }

    function getData() {
        networking.reviewsFor(app.app.id).then((reviews: any) => {
            setReviews(reviews);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    if (reviews) {
        const badReviews = reviews.filter((d: any) => d.sentiment === 'bad').length;
        const goodReviews = reviews.filter((d: any) => d.sentiment === 'good').length;

        const total = reviews.length;

        const badPercentage = badReviews / total;
        const goodPercentage = goodReviews / total;

        const sentimentValue = (goodPercentage * 100 - badPercentage * 100) / 100;

        // If you want to represent it as a percentage score:
        const sentimentPercentage = (sentimentValue + 1) * 50;

        rotation = sentimentPercentage;
    }

    var options: ApexOptions = {
        chart: {
            height: 200,
            type: 'radialBar',
            offsetY: -10
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 135,
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        fontSize: '52px',
                        formatter: function (val) {
                            return val.toFixed(0);
                        }
                    }
                },
            },
        },
        fill: {
            type: 'solid',
            colors: [rotation! > 135 ? '#008000' : rotation! > 45 ? '#FFA500' : '#FF0000'],
        },
        stroke: {
            dashArray: 4
        },
        labels: ['Sentiment'],
    };

    return (
        <div>
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div className='text-center'>
                    <Typography
                        color='neutral'
                        fontWeight={600}
                        level='body-xs'>
                        {"Sentiment meter".toUpperCase()}
                    </Typography>
                    {rotation &&
                        <ReactApexChart
                            options={options}
                            series={[rotation]}
                            type="radialBar"
                            height={350} />
                    }
                </div>
            }
        </div >
    )
}

export default SentimentGaugeComponent;